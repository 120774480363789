import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --responsivePadding: 10px;
    --responsive: 10px;
  }

  @media only screen and (min-width: 420px) {
    :root {
      --responsivePadding: 20px;
      --responsive: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    :root {
      --responsivePadding: 40px;
      --responsive: 40px;
    }
  }

  * {
    font-family: Dexper, sans-serif;
  }

  h3,
  h4 {
    padding: 0 0 24px 0;
    margin: 0;
    font-weight: 500;
  }
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
  h4 {
    font-size: 24px;
    line-height: 32px;
  }
  .allvideos-heading {
    margin: 0 !important;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Dexper;
  }

  .Heading{
    font-size: 36px;
    color: #ffffffff;
    line-height: 50px;
    margin: 10px 0px;
    max-width: 648px;
  }

  .Text{
    font-size: 16px;
    color: #ffffffff;
    line-height: 22px;
  }

  .Link{
    font-size: 15px;
    color: #1BC492;
    line-height: 25px;
  }

  .Button.primary{
    border-radius: 4px;
    height: 48px;
    border: none;
    color: #ffffffff;
    line-height: 22px;
    letter-spacing: 0.25px;
    font-family: Dexper;
    font-weight:700;
    font-size: 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    background: #1bc492;
    padding: 13px 24px;
    cursor: pointer;
  }

  .Badge{
    border-radius: 21px;
    height: 20px;
    color: #ffffffff;
    font-family: Dexper;
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 16.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5px 8px;
  }

  .Badge.primary{
    background: #ff8330;
  }

  .Badge.secondary{
    background: #3d4859;
  }
    
  .Badge.tertiary {
    border-radius: 21px;
    height: 20px;
    color: #fff;
    font-family: Dexper;
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 16.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    --alt-color: #ff5c5cff;
    background: #1bc492ff;
    padding: 1px 8px;
  }

  .Dex365Header {
    div {
      padding: 0 !important;
    }
    .Heading {
      font-size: 36px !important;
      line-height: 50px !important;
    }
  }

  .CardSeries {
    color: #ffffffff;
    border-radius: 8px;
    height: 567px;
    border-style: none;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    > div:first-child {
      top: 10px;
      left: 20px;
      width: 200px;
      white-space: nowrap;
    }
    .section-categories {
      margin-bottom: 10px;
      display: flex;
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;
      .Category {
        line-height: 16.8px;
        letter-spacing: 0.25px;
        font-family: Dexper;
        font-size: 12px;
        display: flex;
        gap: 1px;
        flex-direction: row;
        align-items: center;
        text-transform: none;
      }
    }
    .Button.primary {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border-style: none;
      color: #ffffffff;
      letter-spacing: 0.25px;
      font-size: 16px;
      font-weight: 400;
      font-family: Dexper;
      line-height: 22.4px;
      display: flex;
      justify-content: center;
      background: #00000088;
      padding: 0;
      cursor: pointer;
      margin-top: 20px;
      svg{
        width:1.5em;
        height:1.5em;
      }
    }
  }

  .Grid {
    gap: 32px 16px;
  }

  .FilterList-btnSave {
    font-weight: 700 ;
    font-size: 14px;
  }
  color: #00234b;
  
  .modalContent {
    > div > div {
      height: initial;
    }
  }

  .VideoThumb {
    > :first-child {
      margin-bottom: 16px;
    }
    cursor: pointer;
    .section-thumbnail {
      &:hover {
        opacity: 0.8;
      }
      div {
        border-radius: 8px;
        &:after {
          content: '';
          position: absolute;
          left: calc(50% - 35px);
          top: calc(50% - 35px);
          width: 70px;
          height: 70px;
          background: url(/assets/icons/playbutton.svg) center center no-repeat;
        }
      }
    }
    .section-series {
      color: #ffffff;
      padding: 0;
      margin: 0;
      p {
        margin: 0 !important;
      }
      .Text {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16.8px;
        color: #ffffff;
      }
    }

    .section-description {
      color: #ffffff;
      p {
        margin-top: 0;
      }
    }

    .section-title {
      p {
        color: #ffffff;
      }
      h5 {
        font-size: 18px;
        color: #ffffff;
        margin: 0 0 8px 0;
      }
    }

    .section-categories {
      color: #ffffff;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      margin-bottom: 0 !important;
      cursor: initial;
      .Category {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-right: 6px;
        }
      }
    }
  }

  .VideoThumb.primary .section-description .primary {
    -webkit-line-clamp: 3;
    p {
      margin-bottom: 0;
    }
  }

  .back-button {
    margin: 0;
  }

  .SidePanel {
    > button {
      width: 24px;
      height: 24px;
      background: url(/assets/icons/filter-close.svg) 0 0 no-repeat transparent;
    }
    .SidePanel-container{
      background-color: #3d4859;
    }
    h3 {
      padding: 0;
    }
  }

  .channels-heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    padding-bottom: 0;
  }
  .carousel__slider{
    height: 585px; 
  }
  .CardSeries {
    transition: .8s ease;
    height: 548px;
    top: 0px;
    :hover {
      height: 567px;
      top: -5px;
    }
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
    }
    .Category {
      font-weight: 300;
      font-size: 12px;
      line-height: 140%;
    }
    .Button{
      background-color: #00000088; 
    }
  }
  
  .Button.primary {
  padding: 13px 24px 13px 24px; 
  color: #fff;
  background: #1BC492;
  font-family: Dexper;
  line-height: 140% ;
  display: flex ;
  justify-content: center ;
  cursor: pointer ;
  border-radius: 4px ;
  border-width: 0 ;
  border-style: none ;
}
  .Button.primary:hover {
  opacity: 0.8 ;
  }
  .Button.primary:disabled {
  color:#C8D0D9;
  }

  .Button.secondary {
  padding: 13px 24px 13px 24px; 
  color: #fff;
  background: transparent;
  font-family: Dexper;
  line-height: 140% ;
  display: flex ;
  justify-content: center ;
  cursor: pointer ;
  border-radius: 4px ;
  border-width: 0 ;
  border-style: none ;
}
  .Button.secondary:hover {
  opacity: 0.8 ;
  }
  .Button.secondary:disabled {
  color:#C8D0D9;
  }

  .VideoThumb {
    :hover{
      .section-thumbnail{
        opacity: .8;
      }
    }
    .section-title h5 {
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 12px;
      margin-top: 8px;
    }
    .section-series {
      margin-bottom: 8px;
    }
    .section-categories {
      p {
        color: white !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        margin-left: 10px;
      }
    }
    .section-description {
      p {
        color: white;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }
    }
  }
  .section-content-pagination {
    .Pagination {
      .Button {
        background-color: transparent;
        svg {
          color: #eaf2fb !important;
        }
        &:disabled {
          opacity: 0.8;
        }
      }
      p {
        color: #eaf2fb !important;
      }
    }
  }
  
  .Footer365 {
    padding: 0 var(--responsivePadding);
    background: #141c28;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .section-links {
      padding: 0 0 30px 0;
      display: flex;
      gap: 39px;
      justify-content: flex-start;
      @media (min-width: 420px) {
        padding: 0;
      }
    }
    .section-logo {
      .Link {
        line-height: 0;
      }
    }
    .Link.primary {
      color: #fff;
      font-size: 14px;
      &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    }
    .Icon.primary {
      color: #ffffffff;
      background: #ffffff00;
      height: 95px;
    }
  }

  .CardEvent.primary {
    border-radius: 8px;
    width: 100%;
    height: 450px;
    border-style: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .section-top {
      margin-bottom: auto;
    }
    .section-middle {
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
    .section-categories {
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 10px;
    }

    .Heading.primary {
      font-size: 36px;
    }
    .Text.primary {
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
    }
    .Text.secondary {
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: #fff;
      text-transform: uppercase;
    }
    .Category.primary {
      color: #fff;
      font-size: 12px;
      letter-spacing: 0.25px;
      line-height: 16.8px;
      display: flex;
      gap: 1px;
      flex-direction: row;
      align-items: center;
      text-transform: none;
      margin-top: 10px;
    }
  }

  .back-button {
    background-color: transparent;
    border-style: none;
    color: #ffffff;
    font-family: Dexper, sans-serif;
    letter-spacing: 0.25px;
    --link-decoration: none;
    padding: 14px 0 36px;
    cursor: pointer;
    padding: 40px var(--responsive);
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    svg {
      width: 24px;
      height: 24px;
      filter: brightness(0) saturate(100%) invert(65%) sepia(23%) saturate(7068%) hue-rotate(121deg) brightness(102%) contrast(79%);
    }
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
`;

export default GlobalStyle;
