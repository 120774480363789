import styled from 'styled-components';
import { Slide } from 'pure-react-carousel';
import { ResponsiveRow } from '../../styles';

export const VodContainer = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 50px;
  }
  padding: 40px var(--responsivePadding);
  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }
`;

export const NextUpVodWrapper = styled.section`
  margin-bottom: 20px;
  .section-title h5 {
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  }
  .section-description p {
    color: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }
`;

export const ActiveVodInfo = styled.section`
  display: flex;
  flex-direction: column;
  .series-title {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16.8px;
    color: #ffffff;
    margin: 10px 0;
  }
  .Text,
  .vod-categories {
    margin: 20px 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
  .read-more {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
    color: #1bc492;
    margin-left: 5px;
    font-size: 15px;
  }
`;

export const BackButtonWrapper = styled.div``;

export const StyledOnDemandContainer = styled.div`
  .Dex365EventPage {
    background-color: #3d4859 !important;
  }
  .section-player {
    .Text {
      font-size: 15px;
    }
    padding: 0 var(--responsive) 40px;
  }
  color: #00234b;

  .modalContent {
    > div > div {
      height: initial;
    }
  }
  .video-thumb-title > h5 {
    color: #00234b;
  }

  .section-content-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .Text {
      font-size: 16px;
      color: #00234b;
    }
    .Button {
      svg {
        color: #0061ff !important;
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
  .sessions {
  }
`;

export const ResponsiveRowAllVideos = styled(ResponsiveRow)`
  padding: 40px var(--responsive);
`;

export const BottomPageContainer = styled.div`
  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );
`;

export const StyledSlide = styled(Slide)``;
