import styled from 'styled-components';

export const Container = styled.span`
  background: #131414;
  color: #f25054;
  /* font-family:; */
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  padding: 2px 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
