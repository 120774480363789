/* eslint-disable react/react-in-jsx-scope */

import { settings } from '../../Molecules/Card/Card';
import { StyledTextArea } from './TextAreaAtom.css';

export const PrintVariations = (args: any) => {
  const { className, customRef, type, placeholder, disabled } = args;
  return (
    <StyledTextArea
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      className={className}
      ref={customRef}
      {...args}
    />
  );
};

/* 

 <TextArea
      className={classNames('TextAreaAtom', className)}
      ref={ref}
      name={name}
      disabled={disabled}
      type={type}
      defaultValue={defaultValue}
      error={error}
      placeholder={placeholder}
      onBlur={onBlur}
      {...props}
    />
*/
