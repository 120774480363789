import { Category } from '@dexper.events/dexper-datamodel/lib/src/AllModels';

export function getCategoriesByKey(
  categoryKeys: string[],
  globalCategories: Category[]
) {
  return categoryKeys.map((categoryKey: string) => {
    return globalCategories.find((globalCategory: Category) => {
      return globalCategory.key === categoryKey;
    });
  });
}
