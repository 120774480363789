/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as SC from './styles';
import { ModalSeries } from '@dexper.events/old-atomic-ui';
import { Dex365HeaderContainer } from '../../containers/Dex365Header';
import { DetailBodyContainer } from './containers/DetailBody';
import { dexperFirestore } from '../../../utils/firebase';
import GlobalContext from '../../../context/GlobalContext';
import { currentLanguage } from '../../../config/language.config';
import { Dex365ContainerAppSettings } from '../../containers/Dex365Header/types';
import Head from '../../../components/Head';
import { useAnalytics } from '../../../utils/hooks/analytics/useAnalytics';
import { useIntl } from 'react-intl';
import { Heading, VideoThumb } from '@dexper.events/product-atomic-ui';
import Carousel from '../../../components/Carousel';
import useGetCollectionOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { limit, orderBy, where } from 'firebase/firestore';
import { CardSeriesContainer } from '../../OnDemand/Detail/containers/CardSeries';
import { getGradientColors } from '../Overview/helpers';
import { adaptCategories } from '../../../utils/helpers/categories/adaptCategories';
import { ResponsiveRow } from '../../styles';
import { getSeriesTitle } from '../../../utils/helpers/series/global';

function EventDetailPage() {
  useAnalytics();
  const history = useHistory();
  const intl = useIntl();
  const { pathname } = useLocation();
  const { slug } = useParams<{
    slug: string;
  }>();

  const [relatedSeries, setRelatedSeries] = useState<any>(null);
  const [relatedVods, setRelatedVods] = useState<any>(null);
  const [showModalSeries, setShowModalSeries] = useState<boolean>(false);
  const [seriesModalData, setSeriesModalData] = useState<any>();
  const [detailData, setDetailData] = useState<any>();

  // Move to general helpers
  const adaptSeriesModalVodData = (vods: any, series: any) => {
    const adaptedVods = vods.map((value: any) => {
      value.orderInSeries = value?.series?.orderInSeries;
      const {
        id,
        thumbnail,
        videoID,
        language,
        categories,
        orderInSeries,
        slug,
      } = value as any;

      const seriesTitle = series
        ? getSeriesTitle(series.language[currentLanguage]?.title, orderInSeries)
        : '';
      const translations = language[currentLanguage];
      return {
        id,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        title: translations?.title,
        description: translations?.description,
        seriesTitle,
        categories: adaptCategories(globalData?.categories, categories),
        orderInSeries,
        slug,
      };
    });
    return adaptedVods.sort((a: any, b: any) => {
      return a.orderInSeries - b.orderInSeries;
    });
  };

  // Move to general helpers
  const adaptModalData = (modalData: any) => {
    const { modalImage, accentColor, language, vods, categories, slug } =
      modalData;
    const translations = language[currentLanguage];
    const adaptedModalData = {
      modalImage: `${process.env.REACT_APP_STORAGE_URL}${modalImage}`,
      accentColor: getGradientColors(accentColor, [0.7, 0.7, 0.7]),
      descriptionLong: translations?.descriptionLong,
      title: translations?.title,
      translation: {
        description: 'Description',
      },
      vodTitle: 'Episodes',
      vods: adaptSeriesModalVodData(vods, modalData),
      categories: adaptCategories(globalData?.categories, categories),
      slug,
    };
    return adaptedModalData;
  };

  const openSeriesModal = (seriesId?: string) => {
    if (seriesId) {
      const currentModalData = relatedSeries.find(
        (serie: any) => serie.id === seriesId
      );
      // console.log(vodData, seriesId);
      const vods = vodData.filter((vod: any) => {
        return vod.series && vod.series.seriesID === seriesId;
      });
      if (vods) {
        currentModalData.vods = vods;
      }
      setSeriesModalData(adaptModalData(currentModalData));
    }
  };

  useEffect(() => {
    document.getElementById('root')?.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (seriesModalData) {
      setShowModalSeries(true);
    }
  }, [seriesModalData]);

  const [allData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Events(),
    true,
    [where('slug', '==', slug)]
  );
  useEffect(() => {
    allData && allData.length > 0 && setDetailData(allData[0]);
  }, [allData]);

  const globalData = useContext(GlobalContext);

  const pageContent =
    globalData?.content?.eventsDetails?.language[currentLanguage];

  const appSettings: { dex365HeaderContainer: Dex365ContainerAppSettings } = {
    dex365HeaderContainer: {
      showButtonLabel: false,
    },
  };

  const [vodData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365VODs(),
    true,
    [orderBy('order', 'asc'), limit(200)]
  );

  useEffect(() => {
    if (!vodData || !vodData.length || !detailData || !detailData.vods) {
      return;
    }

    const changedVods = [] as any;

    Object.entries(detailData?.vods).map(([id, vod]) => {
      const video = vod as any;
      changedVods.push({
        id,
        ...video,
      });
    });

    const adaptedVods = Object.entries(detailData?.vods).map(
      ([id, vod], index) => {
        const foundVod = vodData.find((v) => v.id === id);

        if (foundVod) {
          let seriesTitle = '';
          let orderInSeries;
          const foundVideo = foundVod as any;
          if (foundVideo?.series) {
            const translations = foundVideo.series?.language[currentLanguage];
            orderInSeries = foundVideo?.series.orderInSeries;
            seriesTitle = `${translations.title} - EP. ${orderInSeries + 1}`;
          }
          const {
            categories,
            language,
            thumbnail,
            videoID,
            orderInEvent,
            slug,
          } = vod as any;
          const translations = language[currentLanguage];
          return {
            id,
            index,
            categories: adaptCategories(globalData?.categories, categories),
            description: translations?.description,
            title: translations?.title,
            thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
            orderInEvent,
            orderInSeries,
            videoID,
            seriesTitle,
            slug,
          };
        }
      }
    );

    setRelatedVods(adaptedVods);
    if (detailData.series) {
      const adaptedSeriesData = Object.entries(detailData?.series)
        .map(([id, series]) => {
          const seriesData = series as any;
          return {
            ...seriesData,
            id,
          };
        })
        .sort((a: any, b: any) => {
          return a.orderInEvent - b.orderInEvent;
        });
      setRelatedSeries(adaptedSeriesData);
    }
  }, [detailData, vodData]);

  let pageTitle;
  if (detailData) {
    pageTitle = `${detailData.language[currentLanguage].title} | Dexper`;
  }
  return detailData ? (
    <>
      <Head
        title={
          pageTitle ?? intl.formatMessage({ id: 'analytics.event.detail' })
        }
      />
      <SC.StyledContainer className="Dex365EventDetailPage">
        <section className="section-row1">
          <Dex365HeaderContainer
            className="primary"
            data={detailData}
            appSettings={appSettings.dex365HeaderContainer}
          />
        </section>
        <section className="section-row2">
          <DetailBodyContainer
            pageContent={pageContent}
            detailData={detailData}
          />
        </section>
        {relatedSeries && relatedSeries.length && (
          <ResponsiveRow>
            <Heading tag="h3" className="primary channels-heading">
              {pageContent?.relatedSeriesLabel}
            </Heading>
            <Carousel
              slides={
                <>
                  {relatedSeries?.map((series: any) => (
                    <SC.StyledSlide
                      key={series.order}
                      index={series.order}
                      className="section-top"
                    >
                      <CardSeriesContainer
                        className="primary"
                        series={series}
                        callbacks={{
                          onClick: () => {
                            history.push(`/on-demand/${series.slug}`);
                          },
                          onPlay: (e: any) => {
                            e.stopPropagation();
                            history.push(`/on-demand/${series.slug}`);
                          },
                          onInfo: (e: any) => {
                            e.stopPropagation();
                            openSeriesModal(series.id);
                          },
                        }}
                      />
                    </SC.StyledSlide>
                  ))}
                </>
              }
            />
          </ResponsiveRow>
        )}
        {relatedVods && relatedVods.length && (
          <ResponsiveRow>
            <Heading tag="h3" className="primary channels-heading">
              {pageContent?.relatedVideosLabel}
            </Heading>
            <Carousel
              config={{
                buttonOffset: -80,
              }}
              slides={
                <>
                  {relatedVods?.map((vod: any, index: number) => (
                    <SC.StyledSlide
                      key={vod.id}
                      index={index}
                      className="section-top"
                    >
                      <VideoThumb
                        className="primary"
                        thumbnail={vod.thumbnail}
                        categories={vod.categories || []}
                        description={vod.description}
                        title={vod.title}
                        videoId={vod.videoID}
                        seriesInfo={vod.seriesTitle}
                        onClick={() => {
                          history.push({
                            pathname: `/on-demand/${vod.slug}`,
                            state: { referrer: 'detail' },
                          });
                        }}
                      />
                    </SC.StyledSlide>
                  ))}
                </>
              }
            />
          </ResponsiveRow>
        )}
        {showModalSeries && seriesModalData && (
          <ModalSeries
            {...seriesModalData}
            showModal={showModalSeries}
            onClose={() => {
              setShowModalSeries(false);
            }}
            onVideoThumb={(data: any) => {
              history.push(`/on-demand/${data.slug}`);
            }}
          />
        )}
      </SC.StyledContainer>
    </>
  ) : (
    <></>
  );
}

export default EventDetailPage;
