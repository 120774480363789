import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 30px;
  }
  padding: 40px var(--responsive);

  .section-right {
    .RichText {
      color: #fff;
      a {
        color: #1bc492;
        text-decoration: none;
      }
    }
  }
  padding-top: 15px;
  h5,
  h2,
  h3,
  h1,
  p,
  span {
    color: white !important;
    line-height: 140% !important;
  }
  p {
    font-weight: 400 !important;
    font-size: 16px !important;
    margin-bottom: 5px !important;
  }
  h5 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 120% !important;
  }
`;

export const RichTextWrapper = styled.div`
  margin-bottom: 30px;
  p,
  li,
  a {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: white !important;
  }
  p {
    margin: 0 0 35px 0;
  }
  a {
    color: #1bc492 !important;
    text-decoration: none;
    :hover {
      opacity: 0.7;
    }
  }
`;

export const Wrapper = styled.div``;

export const InfoWrapper = styled.div`
  max-width: 270px;
  margin-bottom: 35px;
`;
