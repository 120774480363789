/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Heading,
  VideoThumb,
  Text,
  Category,
  Button,
  Pagination,
} from '@dexper.events/product-atomic-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Head from '../../../components/Head';
import GlobalContext from '../../../context/GlobalContext';
import { dexperFirestore } from '../../../utils/firebase';
import { Dex365HeaderContainer } from '../../containers/Dex365Header';
import * as SC from './styles';
import {
  ResponsiveRowAllVideos,
  StyledOnDemandContainer,
  StyledSlide,
  BottomPageContainer,
} from './styles';
import { ModalSeries, Player } from '@dexper.events/old-atomic-ui';
import useGetCollectionOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { limit, orderBy } from 'firebase/firestore';
import { CardSeriesContainer } from './containers/CardSeries';
import Carousel from '../../../components/Carousel';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getGradientColors } from '../../Events/Overview/helpers';
import { currentLanguage } from '../../../config/language.config';
import { ICON_ARROW_LEFT } from '../../../constants/storage.constants';
import { isMobile } from '../../../utils/helpers/isMobile';
import { adaptCategories } from '../../../utils/helpers/categories/adaptCategories';
import { useAnalytics } from '../../../utils/hooks/analytics/useAnalytics';
import { ResponsiveRow, StyledGrid } from '../../styles';
import { getSeriesTitle } from '../../../utils/helpers/series/global';

function OnDemandDetailPage() {
  useAnalytics();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const pageSize = isMobile() ? 20 : 40;

  const { vodOrSerieSlug }: any = useParams();

  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState<
    string[] | null
  >([]);

  const [filteredVodList, setfilteredVodList] = useState<any>(null);
  const [showModalSeries, setShowModalSeries] = useState<boolean>(false);
  const [seriesModalData, setSeriesModalData] = useState<any>();
  const [adaptedVodData, setAdaptedVodData] = useState<any>(null);
  const [upNextVodData, setUpNextVodData] = useState<any>(null);
  const [pageType, setPageType] = useState('');
  const [activeSeries, setActiveSeries] = useState<any>();
  const [activeVod, setActiveVod] = useState<any>(null);
  const [headerData, setHeaderData] = useState<any>(null);
  const [showModalVodInfo, setShowModalVodInfo] = useState<boolean>(false);
  const [modalVodInfoData, setmodalVodInfoData] = useState<any>(null);
  const [visibleVodData, setVisibleVodData] = useState<any>(null);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const globalData = useContext(GlobalContext);
  const pageContent = globalData?.content?.vodDetail?.language[currentLanguage];

  const [seriesData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Series(),
    true,
    [orderBy('order', 'asc'), limit(100)]
  );

  const adaptVodData = (vods: any) => {
    if (!vods) {
      return [];
    }
    const adaptedVods = vods.map((value: any) => {
      const {
        id,
        language,
        order,
        thumbnail,
        videoID,
        categories,
        series,
        slug,
      } = value as any;
      const translations = language[currentLanguage];
      const seriesTitle = value?.series
        ? getSeriesTitle(
            value?.series.language[currentLanguage]?.title,
            value?.series?.orderInSeries
          )
        : '';
      return {
        id,
        description: translations?.description,
        title: translations?.title,
        order,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        categories: adaptCategories(globalData?.categories, categories),
        series,
        seriesTitle,
        slug,
      };
    });
    return adaptedVods;
  };

  const [vodData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365VODs(),
    true,
    [orderBy('order', 'asc'), limit(200)]
  );

  // Move to general helpers
  const adaptModalVodData = (vods: any, series?: any) => {
    const seriesTranslations = series?.language[currentLanguage];
    const seriesTitle = seriesTranslations.title;

    const adaptedVods = Object.entries(vods).map(([key, value]) => {
      const { thumbnail, videoID, language, categories, orderInSeries, slug } =
        value as any;
      const translations = language[currentLanguage];
      return {
        id: key,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        title: translations?.title,
        description: translations?.description,
        categories: adaptCategories(globalData?.categories, categories),
        orderInSeries,
        seriesTitle: getSeriesTitle(seriesTitle, orderInSeries),
        slug,
      };
    });
    return adaptedVods.sort((a: any, b: any) => {
      return a.orderInSeries - b.orderInSeries;
    });
  };

  // Move to general helpers
  const adaptSeriesModalVodData = (vods: any, series: any) => {
    const adaptedVods = Object.entries(vods).map(([key, value]) => {
      const { thumbnail, videoID, language, categories, orderInSeries, slug } =
        value as any;

      const seriesTitle = series
        ? getSeriesTitle(series.language[currentLanguage]?.title, orderInSeries)
        : '';
      const translations = language[currentLanguage];
      return {
        id: key,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        title: translations?.title,
        description: translations?.description,
        seriesTitle,
        categories: adaptCategories(globalData?.categories, categories),
        orderInSeries,
        slug,
      };
    });
    return adaptedVods.sort((a: any, b: any) => {
      return a.orderInSeries - b.orderInSeries;
    });
  };

  // Move to general helpers
  const adaptModalData = (modalData: any) => {
    const { modalImage, accentColor, language, vods, categories } = modalData;
    const translations = language[currentLanguage];
    const adaptedModalData = {
      modalImage: `${process.env.REACT_APP_STORAGE_URL}${modalImage}`,
      accentColor: getGradientColors(accentColor, [0.7, 0.7, 0.7]),
      descriptionLong: translations?.descriptionLong,
      title: translations?.title,
      translation: {
        description: 'Description',
      },
      vodTitle: pageContent?.episodesLabel,
      vods: adaptSeriesModalVodData(vods, modalData),
      categories: adaptCategories(globalData?.categories, categories),
    };
    return adaptedModalData;
  };

  const adaptModalVodInfoData = (vod: any) => {
    const { thumbnail, title, description, categories, accentColor } = vod;

    const adaptedModalVodInfoData = {
      modalImage: thumbnail,
      descriptionLong: description,
      title: title,
      translation: {
        description: 'Description',
      },
      categories,
      accentColor,
    };

    return adaptedModalVodInfoData;
  };

  useEffect(() => {
    if (modalVodInfoData) {
      setShowModalVodInfo(true);
    }
  }, [modalVodInfoData]);

  const openSeriesModal = (seriesId?: string) => {
    if (seriesId) {
      const currentModalData = seriesData.find(
        (serie: any) => serie.slug === seriesId
      );
      setSeriesModalData(adaptModalData(currentModalData));
    }
  };

  const scrollToVideoSection = () => {
    const scrollContainer = document.getElementById('root');
    setTimeout(() => {
      const scrollToElement = document.querySelector(
        '.section-player'
      ) as HTMLElement;
      if (scrollContainer && scrollToElement) {
        scrollContainer.scrollTo({
          top: scrollToElement.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (location?.state?.referrer !== 'detail') {
      return;
    }
    scrollToVideoSection();
  }, [location]);

  useEffect(() => {
    if (seriesModalData) {
      setShowModalSeries(true);
    }
  }, [seriesModalData]);

  useEffect(() => {
    if (vodData) {
      setAdaptedVodData(adaptVodData(vodData));
    }
  }, [vodData]);

  useEffect(() => {
    if (!location || !adaptedVodData || !seriesData || !vodOrSerieSlug) {
      return;
    }
    setPageType('');
    setActiveSeries(null);
    setActiveVod(null);
    setHeaderData(null);
    setUpNextVodData(null);

    const getVodByOrder = (order: number) => {
      return adaptedVodData.find((vod: any) => vod.order === order);
    };
    const getVodByOrderInSeries = (order: number) => {
      return filteredVodList.find((vod: any) => vod.orderInSeries === order);
    };
    const getNextUpVods = (order: number) => {
      const getFunction =
        pageType === 'series' ? getVodByOrderInSeries : getVodByOrder;
      const nextUpVods = [] as any;
      const nextVod = getFunction(order + 1);
      if (nextVod) {
        nextUpVods.push(nextVod);
      }
      const secondNextVod = getFunction(order + 2);
      if (secondNextVod) {
        nextUpVods.push(secondNextVod);
      }
      return nextUpVods;
    };

    const checkSeries = (seriesSlug: string, vodSlug?: string) => {
      const foundSeries = seriesData.find(
        (serie: any) =>
          serie.seriesID === seriesSlug || serie.slug === seriesSlug
      ) as any;
      if (foundSeries) {
        setPageType('series');
        setActiveSeries(foundSeries);
        setHeaderData(foundSeries);
        const adaptedVodData = adaptModalVodData(
          foundSeries?.vods,
          foundSeries
        );
        setfilteredVodList(adaptedVodData);
        let activeVod;
        if (vodSlug) {
          activeVod = adaptedVodData.find((vod: any) => vod.slug === vodSlug);
        } else {
          activeVod = adaptedVodData.slice(0, 1)[0];
        }
        if (activeVod) {
          setActiveVod(activeVod);
        }

        let nextUpVods = getNextUpVods(activeVod?.orderInSeries);
        if (!nextUpVods.length) {
          nextUpVods = getNextUpVods(-1);
        }
        setUpNextVodData(nextUpVods);
      }
    };

    const checkVod = (seriesID: string) => {
      const foundVod = adaptedVodData.find(
        (vod: any) => vod.slug === vodOrSerieSlug
      );
      if (foundVod) {
        if (foundVod.series) {
          checkSeries(foundVod.series.slug, foundVod.slug);
        } else {
          let nextUpVods = getNextUpVods(foundVod.order);
          if (!nextUpVods.length) {
            nextUpVods = getNextUpVods(-1);
          }
          setUpNextVodData(nextUpVods);
          setPageType('vod');
          setActiveVod(foundVod);
        }
      }
    };

    checkSeries(vodOrSerieSlug);
    checkVod(vodOrSerieSlug);
  }, [location, adaptedVodData, seriesData, vodOrSerieSlug, pageType]);

  useEffect(() => {
    if (!adaptedVodData) {
      return;
    }
    setPageCount(0);
    let vodData = filteredVodList ? filteredVodList : adaptedVodData;

    if (vodData.length > pageSize) {
      setPageCount(vodData.length);
      vodData = vodData.slice(pageOffset, pageOffset + pageSize);
    }

    setVisibleVodData(vodData);
  }, [adaptedVodData, pageOffset, pageType]);

  let pageTitle;
  if (activeVod) {
    pageTitle = `${activeVod.title} | Dexper`;
  }
  return (
    <StyledOnDemandContainer>
      <main className="Dex365EventPage">
        {pageType === 'series' && (
          <section className="section-row1">
            <Dex365HeaderContainer
              className="primary"
              data={headerData}
              appSettings={{
                showButtonLabel: false,
              }}
            />
          </section>
        )}
        {activeVod && (
          <>
            <Head
              title={
                pageTitle ??
                intl.formatMessage({ id: 'analytics.onDemand.detail' })
              }
            />
            <SC.BackButtonWrapper>
              <Button
                onClick={() => {
                  history.push('/on-demand');
                }}
                image={ICON_ARROW_LEFT}
                className="back-button"
              >
                {pageContent?.backButtonLabel}
              </Button>
            </SC.BackButtonWrapper>
            <SC.VodContainer className="section-player">
              <div>
                <Heading tag="h3" className="primary">
                  {activeVod.title}
                </Heading>
                <Player
                  className="VodPlayer primary"
                  accountID={process.env.REACT_APP_BRIGHTCOVE_ACCOUNTID}
                  playerID={process.env.REACT_APP_BRIGHTCOVE_PLAYERID}
                  videoId={activeVod.videoID}
                  descriptionShort={activeVod.description}
                  descriptionLong={activeVod.description}
                  speakers={[]}
                  title={activeVod.title}
                  translation={{
                    upNext: 'Next',
                  }}
                  categories={activeVod.categories}
                  timeStartExpected={0}
                  timeEndExpected={0}
                  locale="en-EN"
                  onReadMore={() => {
                    // console.log('onReadMore');
                  }}
                  getVodMetadata={() => {
                    // console.log('getVodMetadata');
                  }}
                />
                <SC.ActiveVodInfo>
                  {activeVod.seriesTitle && (
                    <div className="series-title">{activeVod.seriesTitle}</div>
                  )}
                  {activeVod.categories && activeVod.categories.length > 0 && (
                    <div className="VideoThumb">
                      <div className="section-categories vod-categories">
                        {activeVod.categories?.map(
                          (category: any, index: number) => {
                            return (
                              <Category
                                className={category.className}
                                shape={category.shape}
                                color={category.color}
                                key={index}
                              >
                                {category.children}
                              </Category>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  <Text>
                    {activeVod.description}
                    <span
                      className="read-more"
                      onClick={() => {
                        setmodalVodInfoData(adaptModalVodInfoData(activeVod));
                      }}
                    >
                      {/* TODO: Add this field to the datamodel */}
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {pageContent?.readMoreLabel}
                    </span>
                  </Text>
                </SC.ActiveVodInfo>
              </div>
              <div>
                <Heading tag="h4" className="primary">
                  {pageContent?.upNextLabel}
                </Heading>
                {upNextVodData?.map((item: any) => {
                  return (
                    <SC.NextUpVodWrapper key={item.id}>
                      <VideoThumb
                        className="primary"
                        thumbnail={item.thumbnail}
                        categories={item.categories || []}
                        description={item.description}
                        title={item.title}
                        videoId={item.videoId}
                        seriesInfo={item.seriesTitle}
                        onClick={() => {
                          history.push({
                            pathname: `/on-demand/${item.slug}`,
                            state: { referrer: 'detail' },
                          });
                        }}
                      />
                    </SC.NextUpVodWrapper>
                  );
                })}
              </div>
            </SC.VodContainer>
          </>
        )}
        <BottomPageContainer>
          {pageType === 'vod' && seriesData && seriesData.length && (
            <ResponsiveRow className={'sessions'}>
              <Heading tag="h3" className="primary channels-heading">
                {pageContent?.allSeriesLabel}
              </Heading>
              <Carousel
                slides={
                  <>
                    {seriesData?.map((series: any) => (
                      <StyledSlide
                        key={series?.order}
                        index={series?.order}
                        className="section-top"
                      >
                        <CardSeriesContainer
                          className="primary"
                          series={series}
                          callbacks={{
                            onClick: () => {
                              history.push(`/on-demand/${series.slug}`);
                            },
                            onPlay: (e: any) => {
                              e.stopPropagation();
                              history.push(`/on-demand/${series.slug}`);
                            },
                            onInfo: (e: any) => {
                              e.stopPropagation();
                              openSeriesModal(series.slug);
                            },
                          }}
                        />
                      </StyledSlide>
                    ))}
                  </>
                }
              />
            </ResponsiveRow>
          )}
          <ResponsiveRowAllVideos className="all-videos">
            <Heading tag="h3" className="primary allvideos-heading">
              {pageType === 'series'
                ? pageContent?.episodesLabel
                : pageContent?.allVideosLabel}
            </Heading>
            <StyledGrid columns={3}>
              {visibleVodData?.map((item: any) => {
                return (
                  <VideoThumb
                    key={item.id}
                    className="primary"
                    thumbnail={item.thumbnail}
                    categories={item.categories || []}
                    description={item.description}
                    title={item.title}
                    videoId={item.videoId}
                    seriesInfo={item.seriesTitle}
                    onClick={() => {
                      history.push({
                        pathname: `/on-demand/${item.slug}`,
                        state: { referrer: 'detail' },
                      });
                    }}
                  />
                );
              })}
            </StyledGrid>
            {pageCount > pageSize && (
              <div className="section-content-pagination">
                <Pagination
                  className="primary"
                  count={pageCount}
                  pageSize={pageSize}
                  onPage={() => {
                    document.querySelector('.all-videos')?.scrollIntoView();
                  }}
                  onChange={(offset) => {
                    setPageOffset(offset);
                  }}
                  baseUrl="https://events.remote.com/"
                />
              </div>
            )}
          </ResponsiveRowAllVideos>
        </BottomPageContainer>
        {showModalSeries && seriesModalData && (
          <ModalSeries
            {...seriesModalData}
            showModal={showModalSeries}
            onClose={() => {
              setShowModalSeries(false);
            }}
            onVideoThumb={(data: any) => {
              history.push(`/on-demand/${data.slug}`);
              scrollToVideoSection();
              setShowModalSeries(false);
            }}
          />
        )}
        {showModalVodInfo && modalVodInfoData && (
          <ModalSeries
            {...modalVodInfoData}
            showModal={showModalVodInfo}
            onClose={() => {
              setShowModalVodInfo(false);
            }}
          />
        )}
      </main>
    </StyledOnDemandContainer>
  );
}

export default OnDemandDetailPage;
