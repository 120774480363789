/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { Box } from '../../Layout/Box/Box';
import { Icon } from '../../Atoms/Icon/Icon';
import { Text } from '../../Atoms/Text/Text';
import { Heading } from '../../Atoms/Heading/Heading';
import { Button } from '../../Atoms/Button/Button';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PrintVariations = (args: any) => {
  /**
   * function to handle button click, which also hides the button and shows the check in icon
   */
  function handleClick() {
    settings.callbacks.onSubmit();
  }

  /**
   * Function to render icons based on the implementation's state
   * @param isSubmited bool
   * @param isLoading  bool
   * @param isFinished bool
   * @returns
   */
  function printIcons(
    isSubmited: boolean,
    isLoading: boolean,
    isFinished: boolean,
  ) {
    if (!isLoading && !isFinished) {
      return (
        <Button className='primary' onClick={handleClick}>
          {settings.text.buttonText}
        </Button>
      );
    }
    if (isSubmited && isLoading && !isFinished) {
      return (
        <Icon
          className='primary'
          location='/icons/three-dots.svg'
          content={{ label: settings.text.signedIn }}
        />
      );
    }
    if (isSubmited && isFinished && !isLoading) {
      return (
        <Icon
          className='custom'
          location='/icons/check.svg'
          content={{ label: settings.text.signedIn }}
        />
      );
    }
  }

  const { className: variation } = args;
  const { settings } = args;

  switch (variation) {
    case 'primary':
      return (
        <div>
          <div className='section-header'>
            <Text className='large'>{settings.text.header} </Text>
          </div>
          <div className='section-middle'>
            <Text className='medium'>
              {settings.text.title} {settings.text.subtitle}
            </Text>
            <Icon
              className='primary'
              location='/assets/icons/instagram-white.svg'
              content={{ label: settings.text.signedIn }}
            />
            <Icon
              className='secondary'
              location='/assets/icons/linkedin-white.svg'
              content={{ label: settings.text.signedIn }}
            />
            <Button className='tertiary' onClick={settings.callbacks.onShare}>
              Other Button
            </Button>
          </div>
          <div className='section-bottom'>
            <Button className='secondary' onClick={settings.callbacks.onShare}>
              Other Button
            </Button>

            {printIcons(
              settings.isSubmited,
              settings.isLoading,
              settings.isFinished,
            )}
          </div>
        </div>
      );
      break;
    // add more Card variations here
    default:
      return <p>no card variation selected</p>;
  }
};
