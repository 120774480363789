//@ts-nocheck
import styled from 'styled-components';
import { Icon } from '../../Atoms/Icon/Icon';

interface T {
  selected?: string;
  variant?: string;
}
export const Wrapper = styled.div<T>`
  height: auto;
  margin: 0 0 15px 0px;
  padding-top: 1px;
  color: ${({ selected, theme }) => (selected ? '#FFF' : '#76767D')};
  font-size: ${({ theme }) => '15px'};
  &.hide {
    display: none !important;
  }
  li {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

interface A {
  variant?: any;
  isOpen?: boolean;
  error?: boolean;
}

export const Header = styled.div<A>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-bottom: 4px;
  padding-right: 1.5em;
  padding-left: 1em;
  box-sizing: border-box;
  border: ${({ variant, isOpen, error }) =>
    variant.header.borderColor && !error
      ? `1px solid ${
          isOpen && !error
            ? variant.header.borderColor.focus
            : variant.header.borderColor.default || 'transparent'
        }`
      : `1px solid ${
          error
            ? variant.header.borderColor.error
            : variant.header.borderColor.default || 'transparent'
        }`};
  border-radius: 0;
  background: ${({ disabled, variant }) =>
    disabled
      ? variant.header.backgroundColor.disabled
      : variant.header.backgroundColor.default};
  &:focus {
    outline: none;
  }
  line-height: 45px;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface SelectedElm {
  icon?: string;
}

export const SelectedElm = styled(Icon)<SelectedElm>`
  position: absolute;
  display: flex;
  background-repeat: no-repeat;
  width: 14px;
  height: 9px;
  top: 13px;
  right: 15px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

interface ChevronType {
  isOpen?: boolean;
  variant?: any;
  location?: string;
}
export const Chevron = styled(Icon)<ChevronType>`
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  top: ${({ isOpen }) => (isOpen ? '14px' : '14px')};
  right: 15px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const ListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
`;

interface ListType {
  variant?: any;
}
export const List = styled.ul<ListType>`
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 80vh;
  background: #131414;
  border: 1px solid #c8d0d9;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  line-height: 25px;
  overflow: hidden;
`;
interface ItemType {
  variant?: any;
}
export const Item = styled.li<ItemType>`
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  height: 36px;
  padding-right: 1.5em;
  padding-left: 1em;
  line-height: 36px;
  &:hover {
    background: #212324;
    cursor: pointer;
  }
  &.itemFocused {
    background: #212324;
    cursor: pointer;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InputField = styled.input``;
