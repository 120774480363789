import React from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { Text } from '../../Atoms/Text/Text';
import { Button } from '../../Atoms/Button/Button';
import { StyledSection } from './CardSeries.css';
import { Category } from '../../Atoms/Category/Category';

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;

  switch (variation) {
    case 'primary':
      return (
        <>
          <StyledSection className='section-heading'>
            <Heading tag={settings.titleType} className='primary'>
              {settings.text.title}
            </Heading>
          </StyledSection>

          <StyledSection className='section-categories'>
            {settings.categories.map((category: any, index: number) => {
              return (
                <Category
                  className={category.className}
                  shape={category.shape}
                  color={category.color}
                  key={index}
                >
                  {category.label}
                </Category>
              );
            })}
          </StyledSection>
          <StyledSection className='section-hover'>
            <Text className='primary'>{settings.text.description} </Text>
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}
            >
              {settings.callbacks.onPlay && (
                <Button
                  className='primary'
                  image={`${settings.baseUrl ?? ''}/assets/icons/play.svg`}
                  alt='Play'
                  onClick={(e) => settings.callbacks.onPlay(e)}
                ></Button>
              )}
              <Button
                className='primary'
                image={`${settings.baseUrl || ''}/assets/icons/info.svg`}
                alt='Info'
                onClick={settings.callbacks.onInfo}
              ></Button>
            </div>
          </StyledSection>
        </>
      );
      break;
    default:
      return <p>No CardSeries variation selected</p>;
  }
};
