/*
  CardSeries from product-atomic-ui should just be a div instead of a button.
  If we want to link it, we can just add a React Router Link around it in the implementation.
*/

import React from 'react';
import { CardSeries } from '@dexper.events/product-atomic-ui';
import { settings } from './settings';

export const CardSeriesContainer = ({ className, series, callbacks }: any) => {
  return (
    <CardSeries className={className} settings={settings(series, callbacks)} />
  );
};
