/*eslint-disable */
export const getOrdinalSuffix = (day: string | number) => {
  if (day > 3 && day < 21) return 'th';
  switch (Number(day) % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getSuffixedDay = (
  locale: string | string[] | undefined,
  timeMs: number | Date | undefined,
) =>
  `${getOrdinalSuffix(
    new Intl.DateTimeFormat(locale, {
      day: 'numeric',
    }).format(timeMs),
  )}`;
