import {
  CategoryConfig,
  ClientContentGeneral,
  Config365,
} from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import React from 'react';
import { GenericWithDocumentID } from '../types';

export type ContextProps = {
  config: GenericWithDocumentID<Config365>;
  content: GenericWithDocumentID<ClientContentGeneral>;
  categories: GenericWithDocumentID<CategoryConfig>;
};

const GlobalContext = React.createContext<Partial<ContextProps>>({});

export const GlobalContextProvider = GlobalContext.Provider;
export const GlobalContextConsumer = GlobalContext.Consumer;

export default GlobalContext;
