import React, { useEffect, useState, useContext, ReactNode } from 'react';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { Item } from './SelectBoxOptions.css';
import { State } from '../../../definitions/States';
import classNames from 'classnames';

export const SelectBoxOptionsStory = {
  onClick: ({ label, value }: any) => console.log('this is a console log '),
  onMouseOut: () => console.log('this is a console log '),
  className: 'primary',
  openIcon: '/assets/icons/arrow-down.svg',
  children: 'hello',
  selectedKeyboard: true,
  selectedFromKeyboard: '',
  selected: '',
  options: [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Diverse', value: 'diverse' },
  ],
};

interface optionsType {
  label: string;
  value: string;
}

export interface SelectBoxOptionsTypes {
  className?: string;
  options: Array<optionsType>;
  onClick: ({ label, value }: any) => void;
  onMouseOut: () => void;
  selectedKeyboard?: boolean;
  selectedFromKeyboard?: string;
  selected?: string;
  children?: React.ReactNode;
}

export const SelectBoxOptions: React.FC<SelectBoxOptionsTypes> = ({
  className,
  options,
  onClick,
  onMouseOut,
  selectedKeyboard,
  selectedFromKeyboard,
  selected,
  children,
}) => {
  return (
    <>
      {options.length > 0 &&
        options.map(({ label, value }: any) => (
          <Item
            className={classNames('SelectBoxOptions', className)}
            onClick={onClick({
              label,
              value,
            })}
            key={label}
            onMouseOut={onMouseOut}
            id={
              value === selectedFromKeyboard && selectedKeyboard
                ? 'itemFocused'
                : ''
            }
          >
            <div>{label}</div>
            {selected === value && children}
          </Item>
        ))}
    </>
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.text,
];

export const SelectBoxOptionsSettings: ISettings = {
  states: [State.default, State.active, State.hover, State.disabled],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'SelectBoxOptions Primary',
    },
    {
      className: '&secondary',
      description: 'SelectBoxOptions Secondary',
    },
  ],
};

SelectBoxOptions.defaultProps = {
  options: [],
};
