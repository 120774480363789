import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { TextContainer, TextContainerLabel } from './Text.css';
import classNames from 'classnames';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import sanitizeHtml from 'sanitize-html';
import * as H from 'history';

const TextPropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

export const TextStory = {
  children: 'I am a Text',
  className: 'primary',
  htmlFor: '',
};

export type TextType = InferProps<typeof TextPropTypes>;

export const Text: React.FC<TextType> = ({ className, children, htmlFor }) => {
  const history = useHistory();

  return htmlFor ? (
    <TextContainerLabel
      htmlFor={htmlFor}
      className={classNames('Text', className)}
      onClick={(event) => {
        handleClick(history, event as unknown as Event);
      }}
    >
      {applyMarkdown(children)}
    </TextContainerLabel>
  ) : (
    <TextContainer
      className={classNames('Text', className)}
      onClick={(event) => {
        handleClick(history, event as unknown as Event);
      }}
    >
      {applyMarkdown(children)}
    </TextContainer>
  );
};

const allCategories = [
  CmsConfig.brandText,
  CmsConfig.color,
  CmsConfig.linkStyle,
  CmsConfig.vmargin,
  CmsConfig.hmargin,
  CmsConfig.flexbox,
  CmsConfig.transform,
];

export const TextSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'Text small',
    },
    {
      className: '&secondary',
      description: 'Text medium',
    },
    {
      className: '&tertiary',
      description: 'Text large',
    },
  ],
};

Text.propTypes = TextPropTypes;

function handleClick(history: H.History, event: Event) {
  const link = (event.target as HTMLElement).getAttribute('href') as string;
  if (link && !isLinkExternal(link)) {
    history.push(link);
    event.preventDefault();
  }
}

function isLinkExternal(url: string) {
  return /^https?:\/\//.test(url);
}

function applyMarkdown(nodes: any): any {
  if (typeof nodes == 'string') {
    return <span dangerouslySetInnerHTML={{ __html: replaceLinks(nodes) }} />;
  } else if (Array.isArray(nodes)) {
    return Array.from(nodes).map((node) => applyMarkdown(node));
  }

  return nodes;
}

function replaceLinks(text: string) {
  const updatedText = text.replace(
    /\[([^\]]+)]\(([^)]+)\)/g,
    `<a href='$2' target='_blank' rel='noopener noreferrer'>$1</a>`,
  );

  return sanitizeHtml(updatedText, {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['href', 'target', 'class', 'rel'],
    },
  });
}
