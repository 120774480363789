export function getStatusBadgeStatus(
  startTimeMs: number,
  endTimeMs: number
): { variant: string; text: string; status: string } {
  const currentTimeMs = Date.now();
  let variant = '';
  let text = '';
  if (currentTimeMs < startTimeMs) {
    variant = 'primary';
    text = 'UPCOMING';
  } else if (currentTimeMs >= startTimeMs && currentTimeMs <= endTimeMs) {
    variant = 'tertiary';
    text = 'LIVE';
  } else if (currentTimeMs > endTimeMs) {
    variant = 'secondary';
    text = 'CONCLUDED';
  }
  return {
    variant,
    text,
    status: text.toLowerCase(),
  };
}
