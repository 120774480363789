import styled from 'styled-components';
import {
  Circle,
  Oval,
  Parallelogram,
  Rectangle,
  Square,
  Trapezoid,
  TriangleDown,
  TriangleLeft,
  TriangleRight,
  TriangleUp,
} from './shapes';

export interface CategoryShapeProps {
  shape: string;
  color: string;
}

export const CategoryShape = styled.span<CategoryShapeProps>`
  ${({ shape }) => {
    switch (shape) {
      case 'circle':
        return Circle;
      case 'oval':
        return Oval;
      case 'parallelogram':
        return Parallelogram;
      case 'rectangle':
        return Rectangle;
      case 'square':
        return Square;
      case 'trapezoid':
        return Trapezoid;
      case 'triangleDown':
        return TriangleDown;
      case 'triangleLeft':
        return TriangleLeft;
      case 'triangleRight':
        return TriangleRight;
      case 'triangleUp':
        return TriangleUp;
    }
  }}
`;

export const CategoryContainer = styled.div``;
