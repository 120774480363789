import {
  Config365,
  Event365,
} from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import { useContext } from 'react';
import { currentLanguage } from '../../../../../config/language.config';
import GlobalContext from '../../../../../context/GlobalContext';
import { getCategoriesByKey } from '../../../../../utils/helpers/categories/getCategoriesByKey';
import { getGradientColors, getStatusBadgeStatus } from '../../helpers';

export const settings = (
  dex365Event: Event365,
  callbacks: {
    onClick: () => void;
  },
  eventTypes: Config365['eventTypes']
) => {
  const {
    eventType: eventTypeKey,
    startTimeMs,
    endTimeMs,
    accentColor,
    cardImage,
    categories: eventCategoryKeys,
    language: {
      EN: { title = '', descriptionShort: description = '', when = '' },
    },
  } = dex365Event;
  const eventType = eventTypes[eventTypeKey]?.language[currentLanguage]?.label;
  let categories: any;
  const globalData = useContext(GlobalContext);
  if (eventCategoryKeys && globalData?.categories) {
    categories = getCategoriesByKey(
      eventCategoryKeys,
      globalData?.categories?.categories
    )?.map((category: any) => {
      const {
        shape,
        color,
        language: {
          EN: { label: children },
        },
      } = category;
      return {
        className: 'primary',
        shape,
        children,
        color,
      };
    });
  }
  const statusBadgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
  const gradientColors = getGradientColors(accentColor, [0.2, 0.75]);
  const cardEventSettings = {
    text: {
      eventType,
      badgeLabel: statusBadgeStatus?.text,
      dateTime: when,
      titleType: 'h1',
      title,
      description,
    },
    callbacks,
    categories,
    badgeVariant: statusBadgeStatus?.variant,
    backgroundImage: `${process.env.REACT_APP_STORAGE_URL}${cardImage}`,
    firstGradientColor: gradientColors[0],
    secondGradientColor: gradientColors[1],
  };
  return cardEventSettings;
};
