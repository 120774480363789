import { backgroundTaskInterval } from '../../../config/analytics.config';
import useOnBackgroundPing from './useOnBackgroundPing';

let timer: ReturnType<typeof setInterval>;

function clearTimer() {
  if (timer) {
    clearTimeout(timer);
  }
}

const useAnalyticsBackgroundTask = async ({ data, exitPage = false }: any) => {
  if (!data) throw new Error('No data provided');
  try {
    const GetUseOnBackgroundPing = (obj: any) =>
      useOnBackgroundPing(obj) as any;

    const sendBackgroundPing = () => {
      const visitID = sessionStorage.getItem('visitID');
      if (visitID && data?.ping?.page === window?.location?.pathname) {
        GetUseOnBackgroundPing({ ...data, visitID });
      }
    };

    const sendExitPing = () => {
      const visitID = sessionStorage.getItem('visitID');
      if (visitID) {
        GetUseOnBackgroundPing({ ...data, visitID });
      }
    };

    if (exitPage) {
      sendExitPing();
      return;
    }

    clearTimer();
    timer = setInterval(() => {
      sendBackgroundPing();
    }, backgroundTaskInterval);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('useAnalyticsBackgroundTask error occurred:', error);
  }
};

export default useAnalyticsBackgroundTask;
