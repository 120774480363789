import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { HeadingContainer } from './Heading.css';
import classNames from 'classnames';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';

const HeadingPropTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export const HeadingStory = {
  tag: 'h1',
  children: 'I am a Heading',
  className: 'primary',
};

export type HeadingType = InferProps<typeof HeadingPropTypes>;

export const Heading: React.FC<HeadingType> = ({
  tag,
  className,
  children,
}) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <HeadingContainer as={tag} className={classNames('Heading', className)}>
    {children}
  </HeadingContainer>
);

export const allHeadingCategories = [
  CmsConfig.brandHeading,
  CmsConfig.color,
  CmsConfig.vmargin,
  CmsConfig.hmargin,
];

export const HeadingSettings: ISettings = {
  states: [State.default],
  include: allHeadingCategories,
  variants: [
    {
      className: '&primary',
      description: 'Heading Primary',
    },
    {
      className: '&secondary',
      description: 'Heading Secondary',
    },
    {
      className: '&tertiary',
      description: 'Heading Tertiary',
    },
    {
      className: '!h1',
      description: 'H1 heading',
    },
    {
      className: '!h2',
      description: 'H2 heading',
    },
    {
      className: '!h3',
      description: 'H3 heading',
    },
  ],
};

Heading.propTypes = HeadingPropTypes;
