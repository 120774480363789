import { IStates, State } from '../definitions/States';

export const states: IStates = {
  [State.default]: {
    label: 'Default',
    style: '',
  },
  [State.hover]: {
    label: 'Hover',
    style: ':hover',
  },
  [State.active]: {
    label: 'Active',
    style: ':active',
  },
  [State.disabled]: {
    label: 'Disabled',
    style: ':disabled',
  },
  [State.focus]: {
    label: 'Focus',
    style: ':focus',
  },
};
