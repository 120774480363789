import React, { useLayoutEffect, useState } from 'react';
import { Navigation365 } from '@dexper.events/product-atomic-ui';
import { INavigation365Container } from './types';
import { settings } from './settings';
import { StyledContainer } from './styles';
import { useLocation } from 'react-router-dom';

export const Navigation365Container = ({
  className,
  data,
}: INavigation365Container) => {
  const [navigation365Settings, setNavigation365Settings] = useState<any>();
  const location = useLocation();
  useLayoutEffect(() => {
    if (!data) {
      return;
    }
    setNavigation365Settings(settings());
  }, [data, location]);
  // Navigation has not been added in the config data yet
  // Se we'll define it manually until it is added
  // const globalData = useContext(GlobalContext);
  // console.log(globalData.config, globalData.content);
  return (
    <>
      {navigation365Settings && (
        <StyledContainer>
          <Navigation365
            className={className}
            settings={navigation365Settings}
          />
        </StyledContainer>
      )}
    </>
  );
};
