import styled from 'styled-components';

interface IProfileCard {
  open: boolean;
}

export const ProfileCardContainer = styled.div<IProfileCard>`
  border: var(--border);
  padding: var(--padding);
  color: var(--dark);
  background: var(--light);

  .footer {
    justify-content: space-between;
  }
`;
