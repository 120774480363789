import styled from 'styled-components';
import { RichText } from '../../Atoms/RichText/RichText';
import ImagePlayButton from './assets/playbutton.svg';

export const StyledVideoThumb = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  border: none;
  text-align: left;
  background-color: transparent;
  outline: none;

  .video-thumb {
    transition: opacity 150ms ease;
    opacity: 0.6;
  }

  :hover .video-thumb,
  :focus-visible .video-thumb {
    opacity: 0.4;
  }

  .video-thumb-title {
    transition: opacity 150ms ease;
    opacity: 1;
  }

  :hover .video-thumb-title,
  :focus-visible .video-thumb-title {
    opacity: 0.8;
  }

  :focus-visible {
    outline: 2px solid #00a1f1;
  }
`;

export const PlayButton = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 14px;

  ::after {
    content: '';
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
    background: no-repeat center url(${ImagePlayButton});
  }
`;

interface StyledVideoType {
  src?: string;
}

export const StyledVideo = styled.div<StyledVideoType>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ src }) => `no-repeat center/cover #212324 url(${src})`};
`;

interface StyledTitleType {
  isLight?: boolean;
}

export const StyledTitle = styled.div<StyledTitleType>`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* color: ${({ isLight }) => (isLight ? '#0f0' : '#00f')}; */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  margin-top: 0;
  margin-top: 9px;
`;

export const RichTextWrapped = styled(RichText)`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ConstrainHeight = styled.div``;
