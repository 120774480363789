import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SponsorCardContainer } from './SponsorCard.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';

import { breakpoints, useWindowSize } from '../../../config/Breakpoints';

/* 
Implementation example: ( with responsivness beyond flexbox or clamp )
=======================

import {SponsorCardStory} from '@dexper.events/product-atomic-ui';

const {settings : sponsorcardSettings} = SponsorCardStory;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <SponsorCard settings={sponsorcardSettings} className="primary"></$> 
     </div>
 </div> 

*/

export const sponsorcardSettings = {
  text: {
    title: 'Remote Technology Services',
    subtitle: 'Check out our detail page!',
    sponsorName: 'Remote',
  },
  isInternalLink: true,
  // sponsorImage: 'center/cover url(/assets/images/remote-sponsor.png)',
  sponsorImage: '/assets/images/remote-sponsor.png',
  iconInternalLink: {
    label: 'internal link',
    location: '/home',
    icon: '/assets/icons/arrow-right.svg',
  },
  iconExternalLink: {
    label: 'external link',
    location: 'https://dexper.io',
    icon: '/assets/icons/external-link.svg',
  },
  callbacks: {
    onClick: (...args: any[]) => {
      console.log('onClick fired', args);
    },
  },
  // more props here:
};

export const SponsorCardStory = {
  settings: sponsorcardSettings,
  className: 'primary',
};

const SponsorCardPropTypes = {
  className: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }).isRequired,

    isInternalLink: PropTypes.bool,
    sponsorImage: PropTypes.string.isRequired,

    iconInternalLink: PropTypes.shape({
      label: PropTypes.string,
      location: PropTypes.string,
      icon: PropTypes.string,
    }),

    iconExternalLink: PropTypes.shape({
      label: PropTypes.string,
      location: PropTypes.string,
      icon: PropTypes.string,
    }),

    callbacks: PropTypes.shape({
      onClick: PropTypes.func,
    }),
  }).isRequired,
};

export type SponsorCardTypes = InferProps<typeof SponsorCardPropTypes>;

export const SponsorCard: React.FC<SponsorCardTypes> = ({
  className,
  settings,
}) => {
  const windowSize = useWindowSize();

  if (windowSize > breakpoints.tabletLandscapeUp) {
    className = 'primary'; // desktop
  } else {
    className = 'secondary'; // mobile
  }

  return (
    <SponsorCardContainer
      className={classNames('SponsorCard', className)}
      onClick={() => {
        settings.callbacks &&
          settings.callbacks.onClick &&
          settings?.callbacks?.onClick();
      }}
    >
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </SponsorCardContainer>
  );
};

const atoms = [
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button internal link',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon external link',
    settings: IconSettings,
    atomVariant: '&primary',
  },

  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading title',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text subtitle',
    settings: TextSettings,
    atomVariant: '&primary',
  },
];
//more atoms here:

export const SponsorCardSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.flexbox,
    CmsConfig.cursor,
    CmsConfig.effects,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-image',
      description: 'Section sponsor image',
    },
    {
      className: 'section-hr',
      description: 'Section horizontal rule',
    },
    {
      className: 'section-details',
      description: 'Section details',
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'SponsorCard primary',
    },
    {
      className: '&secondary',
      description: 'SponsorCard secondary',
    },
  ],
};

SponsorCard.propTypes = SponsorCardPropTypes;
