import React from 'react';
import * as SC from './SubMenu.css';
import { Link, LinkSettings } from '../../Atoms/Link/Link';

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;
  switch (variation) {
    case 'primary':
      return (
        <SC.NavLinksWrapper>
          {settings?.navigation?.map((link: any, key: number) => (
            <SC.Li key={link.label}>
              <SC.FlexWrapper active={link.active}>
                {link.active == true ? (
                  <Link
                    className='secondary'
                    anchorType={link.anchorType}
                    callback={() => link.callback()}
                    label={link.label}
                    location={link.location ? link.location : ''}
                  />
                ) : (
                  <Link
                    className='primary'
                    anchorType={link.anchorType}
                    callback={() => link.callback()}
                    label={link.label}
                    location={link.location ? link.location : ''}
                  />
                )}
                <SC.DivLine className='section-underline' />
              </SC.FlexWrapper>
            </SC.Li>
          ))}
        </SC.NavLinksWrapper>
      );
      break;
    default:
      return <p>no SubMenu variation selected</p>;
  }
};
