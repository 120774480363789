import styled, { css } from 'styled-components';

export const StyledCarousel = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 calc(-1 * var(--page-padding));
  width: calc(100% + 2 * var(--page-padding));

  @media screen and (pointer: fine) {
    margin: 0;
    width: 100%;
  }
`;

export const StyledScrollButtonContainer = styled.div`
  position: absolute;
  height: 100%;
  width: var(--page-padding);
  display: none;
  align-items: center;
  justify-content: center;

  &.left {
    left: calc(var(--page-padding) * -1);
  }

  &.right {
    right: calc(var(--page-padding) * -1);
  }

  @media screen and (pointer: fine) {
    display: flex;
  }
`;

type StyledCardContainerProps = {
  columns: number;
  childrenCount: number;
  childrenColumns: number;
};

export const StyledCardContainer = styled.div<StyledCardContainerProps>`
  --gap: 20px;

  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  overscroll-behavior: contain auto;
  margin-bottom: 20px;
  padding: 0 var(--page-padding) 40px;

  ::-webkit-scrollbar {
    display: none;
  }

  display: grid;

  /* Mobile */
  ${({ childrenCount }) =>
    css`
      grid-template-columns: repeat(${childrenCount}, 85vw);
    `};
  gap: var(--gap);

  /* Tablet */
  @media screen and (min-width: 481px) and (pointer: coarse) {
    ${({ childrenCount }) =>
      css`
        grid-template-columns: repeat(${childrenCount}, 50vw);
      `};
  }

  /* Desktop */
  @media screen and (min-width: 992px) and (pointer: coarse) {
    ${({ childrenCount }) =>
      css`
        grid-template-columns: repeat(${childrenCount}, 39vw);
      `};
  }

  @media screen and (pointer: fine) {
    --gap: 32px;

    padding-left: 0;

    ${({ columns, childrenColumns }) =>
      css`
        grid-template-columns: repeat(
          ${childrenColumns},
          calc(
            (100vw - 2 * var(--page-padding) - var(--gap) * ${columns - 1}) /
              ${columns}
          )
        );
      `};
    scroll-snap-type: none;
    padding-right: 0;
  }

  & > * {
    scroll-snap-align: center;

    @media screen and (min-width: 568px) and (pointer: coarse) {
      scroll-snap-align: none;
    }

    @media screen and (pointer: fine) {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
