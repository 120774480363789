import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCounter,
  StyledPagination,
  ImgButtonWrapperLeft,
  ImgButtonWrapperRight,
} from './Pagination.css';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { State } from '../../../definitions/States';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import classNames from 'classnames';

export interface PaginationTypes {
  count: number;
  pageSize: number;
  onPage: (offset: number) => any;
  onChange: (offset: any, size: any) => any;
  prevCallback?: () => any;
  nextCallback?: () => any;
  className?: string;
  baseUrl?: string;
}
export const PaginationStory = {
  className: 'primary',
  count: 300,
  pageSize: 50,
  baseUrl: '',
  onPage: (offset: number) => {
    console.log('onPage', { offset });
  },
  onChange: (offset: any, size: any) => {
    console.log('onChange', { offset, size });
  },
  prevCallback: () => {
    console.log('story assigned event');
  },
  nextCallback: () => {
    console.log('story assigned event');
  },
};

export const Pagination: React.FC<PaginationTypes> = ({
  count,
  pageSize,
  onPage,
  onChange,
  prevCallback,
  nextCallback,
  className,
  baseUrl = '',
}) => {
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const memoizedOnPage = useCallback(onPage, []);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const memoizedOnChange = useCallback(onChange, []);

  const [offset, setOffset] = useState(0);

  const onClick = (delta: number) => {
    const newOffset = offset + pageSize * delta;
    if (newOffset < 0 || newOffset > count) {
      return;
    }
    setOffset(newOffset);
    memoizedOnPage(newOffset);
  };

  useEffect(() => {
    setOffset(0);
  }, [count]);

  useEffect(() => {
    memoizedOnChange(offset, Math.min(pageSize, count - offset));
  }, [offset, count, pageSize, memoizedOnChange]);

  return (
    <>
      <StyledPagination className={classNames('Pagination', className)}>
        <div style={{ position: 'relative' }}>
          <ImgButtonWrapperLeft
            className='primary'
            image={`${baseUrl}/assets/icons/arrow-down.svg`}
            disabled={offset <= 0}
            alt='pagination-left-icon'
            onClick={() => {
              prevCallback && prevCallback();
              onClick(-1);
            }}
          />
        </div>
        <StyledCounter>
          <Text className='primary'>
            {Math.floor(offset / pageSize) + 1} /{' '}
            {Math.floor(count / pageSize) + 1}
          </Text>
        </StyledCounter>
        <div style={{ position: 'relative' }}>
          <ImgButtonWrapperRight
            className='primary'
            disabled={offset + pageSize >= count}
            image={`${baseUrl}/assets/icons/arrow-down.svg`}
            alt='pagination-right-icon'
            onClick={() => {
              nextCallback && nextCallback();
              onClick(1);
            }}
          />
        </div>
      </StyledPagination>
    </>
  );
};

const allCategories = [
  CmsConfig.vmargin,
  CmsConfig.hmargin,
  CmsConfig.padding,
  CmsConfig.border,
  CmsConfig.fill,
  CmsConfig.frame,
];

const atoms = [
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button for Icon',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text Primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
];

export const PaginationSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  variants: [
    {
      className: '&primary',
      description: 'Pagination primary',
    },
  ],
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  prevCallback: PropTypes.func,
  nextCallback: PropTypes.func,
};

const defaultProps: PaginationTypes = {
  count: 0,
  pageSize: 1,
  onPage: () => '',
  onChange: () => '',
  prevCallback: () => '',
  nextCallback: () => '',
};

Pagination.defaultProps = defaultProps;
