/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  Heading,
  Pagination,
  VideoThumb,
} from '@dexper.events/product-atomic-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Head from '../../../components/Head';
import GlobalContext from '../../../context/GlobalContext';
import { dexperFirestore } from '../../../utils/firebase';
import useGetDocumentOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetDocumentOnSnapshot';
import { Dex365HeaderContainer } from '../../containers/Dex365Header';
import { FilterOption } from '../../Events/Overview/containers/FilterList/types';
import Styled, { ResponsiveRow, StyledGrid } from '../../styles';
import {
  FilterBarWrapper,
  ResponsiveRowAllVideos,
  StyledOnDemandContainer,
  StyledSlide,
  StyledFilterBar,
} from './styles';
import {
  SidePanel,
  ModalSeries,
  FilterManager,
} from '@dexper.events/old-atomic-ui';
import { FilterListContainer } from './containers/FilterList';
import useGetCollectionOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { limit, orderBy } from 'firebase/firestore';
import { CardSeriesContainer } from '../Detail/containers/CardSeries';
import Carousel from '../../../components/Carousel';
import { useHistory } from 'react-router-dom';
import { getGradientColors } from '../../Events/Overview/helpers';
import { currentLanguage } from '../../../config/language.config';
import { isMobile } from '../../../utils/helpers/isMobile';
import { adaptCategories } from '../../../utils/helpers/categories/adaptCategories';
import { useAnalytics } from '../../../utils/hooks/analytics/useAnalytics';
import { getSeriesTitle } from '../../../utils/helpers/series/global';
import { isEmpty } from '../../../utils/helpers/javascript';

function OnDemandOverviewPage() {
  useAnalytics();
  const intl = useIntl();
  const history = useHistory();
  const pageSize = isMobile() ? 20 : 40;

  const [showSidePanel, setShowSidePanel] = useState(false);
  const [eventTypeFilter, setEventTypeFilter] = useState<FilterOption[]>([]);
  const [categoryParentFilter, setCategoryParentFilter] = useState<
    FilterOption[]
  >([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState<
    string[] | null
  >([]);

  const [showModalSeries, setShowModalSeries] = useState<boolean>(false);
  const [seriesModalData, setSeriesModalData] = useState<any>();

  const [listFilters, setListFilters] = useState([]);
  const [hiddenCategories, setHiddenCategories] = useState();
  const [hiddenCategoriesParent, setHiddenCategoriesParent] = useState();

  const [adaptedVodData, setAdaptedVodData] = useState<any>(null);

  const [visibleVodData, setVisibleVodData] = useState<any>(null);

  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const globalData = useContext(GlobalContext);
  const pageContent =
    // TODO: Fix in datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalData?.content?.vodOverview?.language[currentLanguage];

  // Snapshot is not updated ATM
  const [headerData] = useGetDocumentOnSnapshot(
    dexperFirestore.dex365Series(
      globalData?.content?.pageHeaders?.series?.seriesID ?? ''
    ),
    true
  );
  const [seriesData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Series(),
    true,
    [orderBy('order', 'asc'), limit(100)]
  );

  const getFilterOptions = () => {
    const categories = globalData?.categories?.categories;
    const categoriesParent = globalData?.categories?.categoriesParent;

    let groupedCategories: any;
    let optionGroup = {};

    categoriesParent?.map((parent) => {
      groupedCategories = {
        ...groupedCategories,
        [parent.key]: categories?.filter((child) => {
          return child.parent === parent.key;
        }),
      };

      return groupedCategories;
    });

    categoriesParent?.map((parent: any) => {
      let elm: any;
      categories?.map((child: any) => {
        elm = {
          ...elm,
          [child.key]: child.language.EN.label,
        };
        return elm;
      });

      optionGroup = {
        ...optionGroup,
        [parent.key]: {
          _parent: parent.language.EN.label,
          ...elm,
        },
      };

      return optionGroup;
    });

    return { groupedCategories, optionGroup };
  };

  const filterOptions = getFilterOptions();
  const { groupedCategories, optionGroup } = filterOptions;

  const adaptVodData = (vods: any) => {
    if (!vods) {
      return [];
    }
    const adaptedVods = vods.map((value: any) => {
      const { id, language, order, thumbnail, videoID, categories, slug } =
        value as any;
      const translations = language[currentLanguage];
      const seriesTitle = value?.series
        ? getSeriesTitle(
            value?.series.language[currentLanguage]?.title,
            value?.series?.orderInSeries
          )
        : '';
      return {
        id,
        descriptionShort: translations?.description,
        title: translations?.title,
        order,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        categories: adaptCategories(globalData?.categories, categories),
        seriesTitle,
        slug,
      };
    });
    return adaptedVods;
  };

  const [vodData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365VODs(),
    true,
    [orderBy('order', 'asc'), limit(200)]
  );

  const adaptModalVodData = (vods: any, series: any) => {
    if (isEmpty(vods)) {
      return [];
    }
    const adaptedVods = Object.entries(vods).map(([key, value]) => {
      const { thumbnail, videoID, language, categories, orderInSeries, slug } =
        value as any;

      const seriesTitle = series
        ? getSeriesTitle(series.language[currentLanguage]?.title, orderInSeries)
        : '';
      const translations = language[currentLanguage];
      return {
        id: key,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        title: translations?.title,
        description: translations?.description,
        seriesTitle,
        categories: adaptCategories(globalData?.categories, categories),
        orderInSeries,
        slug,
      };
    });
    return adaptedVods.sort((a: any, b: any) => {
      return a.orderInSeries - b.orderInSeries;
    });
  };

  const adaptModalData = (modalData: any) => {
    const { modalImage, accentColor, language, vods, categories, slug } =
      modalData;
    const translations = language[currentLanguage];
    const adaptedModalData = {
      modalImage: `${process.env.REACT_APP_STORAGE_URL}${modalImage}`,
      accentColor: getGradientColors(accentColor, [0.7, 0.7, 0.7]),
      descriptionLong: translations?.descriptionLong,
      title: translations?.title,
      translation: {
        description: 'Description',
      },
      vodTitle: 'Episodes',
      vods: adaptModalVodData(vods, modalData),
      categories: adaptCategories(globalData?.categories, categories),
      slug,
    };
    return adaptedModalData;
  };

  const openSeriesModal = (seriesId?: string) => {
    if (seriesId) {
      const currentModalData = seriesData.find(
        (serie: any) => serie.id === seriesId
      );
      setSeriesModalData(adaptModalData(currentModalData));
    }
  };
  useEffect(() => {
    if (seriesModalData) {
      setShowModalSeries(true);
    }
  }, [seriesModalData]);

  const lang = globalData?.content?.pageHeaders?.series?.language;
  const translations = lang && lang[currentLanguage];
  const appSettings = {
    showButtonLabel: true,
    buttonLabel: translations?.label,
    callbacks: {
      onSubmit: (seriesId: any) => {
        history.push(`/on-demand/${seriesId}`);
      },
    },
  };

  useEffect(() => {
    if (groupedCategories && vodData) {
      const usedCategories: string[] = [];
      const videoData = vodData as any;
      videoData.forEach(({ categories }: { categories: any }) => {
        categories?.forEach((key: string) => {
          if (!usedCategories.includes(key)) {
            usedCategories.push(key);
          }
        });
      });
      const excludedCategories: any = [];
      const excludedCategoryParents: any = [];
      Object.entries(groupedCategories).forEach(([categoryParent]) => {
        let deleteCount = 0;
        groupedCategories[categoryParent].forEach((cat: any) => {
          const isUsed = usedCategories.find((uc) => uc === cat.key);
          if (!isUsed) {
            excludedCategories.push(cat.key);
            deleteCount += 1;
          }
        });
        // If no categories are left under a category parent, we remove the category parent
        const categoryParentLength = groupedCategories[categoryParent].length;
        if (
          categoryParentLength === 0 ||
          deleteCount === categoryParentLength
        ) {
          excludedCategoryParents.push(categoryParent);
        }
      });
      setHiddenCategories(excludedCategories);
      setHiddenCategoriesParent(excludedCategoryParents);
    }
    if (vodData) {
      setAdaptedVodData(adaptVodData(vodData));
    }
  }, [vodData]);

  const filterVodbyCategory = (selection: any) => (item: any) => {
    const itemCategories = item?.categories?.map((c: any) => c?.key);
    return selection.every((category: any) =>
      itemCategories?.includes(category)
    );
  };

  useEffect(() => {
    if (!adaptedVodData) {
      return;
    }
    setPageCount(0);
    let vodData = adaptedVodData;

    if (listFilters) {
      vodData = vodData.filter(filterVodbyCategory(listFilters));
    }

    if (vodData.length > pageSize) {
      setPageCount(vodData.length);
      vodData = vodData.slice(pageOffset, pageOffset + pageSize);
    }

    setVisibleVodData(vodData);
  }, [adaptedVodData, listFilters, pageOffset]);

  return (
    <StyledOnDemandContainer>
      <Head title={intl.formatMessage({ id: 'analytics.onDemand.overview' })} />
      <Styled.main className="Dex365EventPage">
        <section className="section-row1">
          <Dex365HeaderContainer
            className="primary"
            data={headerData}
            appSettings={appSettings}
          />
        </section>

        <StyledFilterBar>
          <FilterManager
            className="primary"
            translation={{
              title: 'Filter On Demand',
              openerLabel: 'Filter',
              saveBtn: 'SAVE',
              resetBtn: 'Reset Filter',
              noResultsTitle: 'No results',
              noResultsDescription: 'No results found',
              noResultsLabel: 'No Results',
              options: optionGroup,
            }}
            groupedCategories={groupedCategories}
            onSelect={(inline: any, list: any) => {
              setListFilters(list);
            }}
            excludeCategory={hiddenCategories}
            excludeCategoryParent={hiddenCategoriesParent}
          />
        </StyledFilterBar>
        {seriesData && seriesData.length && (
          <ResponsiveRow>
            <Heading tag="h3" className="primary channels-heading">
              {pageContent?.allSeriesLabel}
            </Heading>
            <Carousel
              slides={
                <>
                  {seriesData?.map((series: any) => (
                    <StyledSlide
                      key={series?.order}
                      index={series?.order}
                      className="section-top"
                    >
                      <CardSeriesContainer
                        className="primary"
                        series={series}
                        callbacks={{
                          onClick: () => {
                            history.push(`/on-demand/${series.slug}`);
                          },
                          onPlay: (e: any) => {
                            e.stopPropagation();
                            history.push(`/on-demand/${series.slug}`);
                          },
                          onInfo: (e: any) => {
                            e.stopPropagation();
                            openSeriesModal(series.id);
                          },
                        }}
                      />
                    </StyledSlide>
                  ))}
                </>
              }
            />
          </ResponsiveRow>
        )}
        <ResponsiveRowAllVideos className="all-videos">
          <FilterBarWrapper>
            <Heading
              tag="h3"
              className="primary allvideos-heading channels-heading"
            >
              {pageContent?.allVideosLabel}
            </Heading>
          </FilterBarWrapper>
          <StyledGrid columns={3}>
            {visibleVodData?.map((item: any) => {
              return (
                <VideoThumb
                  key={item.id}
                  className="primary"
                  thumbnail={item.thumbnail}
                  categories={item.categories || []}
                  description={item.descriptionShort}
                  title={item.title}
                  videoId={item.videoId}
                  seriesInfo={item.seriesTitle}
                  onClick={() => {
                    history.push({
                      pathname: `/on-demand/${item.slug}`,
                      state: { id: item.id, hideHeader: true },
                    });
                  }}
                />
              );
            })}
          </StyledGrid>
          {pageCount > pageSize && (
            <div className="section-content-pagination">
              <Pagination
                className="primary"
                count={pageCount}
                pageSize={pageSize}
                onPage={() => {
                  document.querySelector('.all-videos')?.scrollIntoView();
                }}
                onChange={(offset) => {
                  setPageOffset(offset);
                }}
                baseUrl={process.env.PUBLIC_URL}
              />
            </div>
          )}
        </ResponsiveRowAllVideos>
        <SidePanel
          isVisible={showSidePanel}
          onClose={() => {
            setShowSidePanel(false);
          }}
        >
          <FilterListContainer
            className="primary"
            setEventTypeFilter={setEventTypeFilter}
            setCategoryParentFilter={setCategoryParentFilter}
            setSelectedFilterValues={setSelectedFilterValues}
            selectedFilterValues={selectedFilterValues}
            setShowSidePanel={setShowSidePanel}
          />
        </SidePanel>
        {showModalSeries && seriesModalData && (
          <ModalSeries
            {...seriesModalData}
            showModal={showModalSeries}
            onClose={() => {
              setShowModalSeries(false);
            }}
            onVideoThumb={(data: any) => {
              history.push(`/on-demand/${data.slug}`);
            }}
          />
        )}
      </Styled.main>
    </StyledOnDemandContainer>
  );
}

export default OnDemandOverviewPage;
