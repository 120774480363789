/*
  CardEvent from product-atomic-ui should just be a div instead of a button.
  If we want to link it, we can just add a React Router Link around it in the implementation.
*/

import React, { useContext } from 'react';
import { CardEvent } from '@dexper.events/product-atomic-ui';
import { settings } from './settings';
import GlobalContext from '../../../../../context/GlobalContext';
import { ICardEventContainer } from './types';
import { StyledLink } from './styles';

export const CardEventContainer = ({
  className,
  dex365Event,
}: ICardEventContainer) => {
  const globalData = useContext(GlobalContext);
  return (
    <StyledLink to={`/events/detail/${dex365Event.slug}`}>
      <CardEvent
        className={className}
        settings={settings(
          dex365Event,
          {
            onClick: () => {
              // Hook for onClick action
            },
          },
          globalData?.config?.eventTypes || {}
        )}
      />
    </StyledLink>
  );
};
