import { useState, useEffect } from 'react';
import { getDomain, isEmpty } from '../../helpers/javascript';
const prefix = 'dex365';

function reset() {
  Object.entries(localStorage)
    .map((entry: any) => entry[0])
    .filter((entry: string) => entry.startsWith(`${prefix}-`))
    .forEach((entry) => localStorage.removeItem(entry));
}

function getStorageValue(key: string, defaultValue?: string) {
  const saved = localStorage.getItem(key);
  if (saved) {
    try {
      const savedValue = JSON.parse(saved);
      if (process.env.REACT_APP_DEBUG === 'true') {
        // eslint-disable-next-line no-console
        console.info('Getting from local storage:', savedValue);
      }
      return savedValue || defaultValue;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'An error occurred while trying to parse local storage data.'
      );
      reset();
      window.location.reload();
    }
  }
  return null;
}

function allowedToGetFromStorage(storageKey: string) {
  if (!process.env.REACT_APP_PERSIST) {
    return false;
  }
  const currentVersion = process.env.REACT_APP_VERSION;
  const savedValue = getStorageValue(storageKey);
  return (
    !isEmpty(savedValue) &&
    savedValue.version === currentVersion &&
    savedValue.hostname === getDomain().hostname
  );
}

export const usePersist = (key: string, defaultValue?: string) => {
  const storageKey = `${prefix}-dex365-${key}`;
  const [value, setValue] = useState(() => {
    return allowedToGetFromStorage(storageKey)
      ? getStorageValue(storageKey, defaultValue)?.value || null
      : null;
  });

  useEffect(() => {
    const versionedValue = {
      hostname: getDomain().hostname,
      version: process.env.REACT_APP_VERSION,
      value,
    };
    localStorage.setItem(storageKey, JSON.stringify(versionedValue));
  }, [storageKey, value]);

  return [value, setValue];
};
