import styled from 'styled-components';
export const LinksWrapper = styled.section`
  background-color: ${({ theme }) => theme.components.footer?.linksBackground};
  padding: 50px 0 23px 0;
`;

export const Container = styled.div`
  max-width: 1215px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 0 32px;
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 64px 0 0 0;
  border-bottom: 1px solid #222;

  ${({ theme }) => theme.media.tinyMobileUp} {
    padding: 30px 0 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.components.footer?.background};
`;

export const LinksListUl = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;

  ${({ theme }) => theme.media.tinyMobileUp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  ${({ theme }) => theme.media.largeMobileUp} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: nowrap;
    padding: 0 10% 0;
    li:last-child {
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        margin: 30px 5% 0;
      }
    }
  }

  ${({ theme }) => theme.media.desktopUp} {
    padding: 0 135px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0 0 30px;

    li:last-child {
      flex: none;
      display: contents;
      a {
        margin: 0;
      }
    }
  }
`;

export const Link = styled.a`
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.components.footer?.textColor};
  &:hover {
    text-decoration: none;
    opacity: 0.5;
  }
`;

export const HashicorpImage = styled.img`
  padding-right: 50px;
  padding-bottom: 32px;
`;

export const Image = styled.img`
  height: 40px;

  ${({ theme }) => theme.media.smallMobileUp} {
    height: 48px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    height: 64px;
  }
`;

export const SocialLinks = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0 0 32px;
  align-items: center;
  margin: 0;
`;

export const SocialLinksListItem = styled.li`
  padding: 0 10px;
  &:last-child {
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

export const SocialLinksImg = styled.img`
  &:hover {
    filter: brightness(260%);
    transition: all 0.3s ease-in-out;
  }
`;

export const FooterWrapper = styled.div`
  padding: 52px 0;
`;

export const Coli = styled.div`
  flex: 6;
  margin-top: 0;
`;

export const Col = styled.div`
  padding-right: 15px;
  flex: 6;

  div div:last-child {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
  }
  ${({ theme }) => theme.media.mobileUp} {
    div div:last-child {
      flex-basis: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;

  flex-wrap: wrap;
  flex: 1 0 33%;
`;

export const Sample = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowFlex = styled.div`
  display: flex;
`;

export const ColUl = styled.ul`
  margin-top: 0;
  flex: 6;
  padding: 0 22px 0 0;
  min-width: 135px;
`;

export const FooterWrapperRow = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.largeMobileUp} {
    flex-direction: row;
  }
`;

export const StyledItem = styled.li`
  ${({ theme }) => theme.media.tinyMobileUp} {
    width: 48%;
    padding-bottom: 15px;
    flex: 1 0 16%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    :last-child {
      a:last-child {
        padding-top: 15px;
      }
    }
  }
  ${({ theme }) => theme.media.largeMobileUp} {
    :last-child {
      a:last-child {
        padding-top: 0;
      }
    }
  }

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    display: contents;
  }
`;

export const LeftBlockFooterListItem = styled.li`
  padding: 0 0 65px 0;
  list-style-type: none;
  margin-bottom: 0;
`;

export const LeftBlockFooterLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.components.footer?.listItems};
  &:hover {
    text-decoration: none;
  }
`;

export const FooterSpan = styled.span`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.components.footer?.listTitle};
  padding-bottom: 11px;
  display: flex;
`;

export const FooterPara = styled.p`
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.components.footer?.listItems};
  &:hover {
    opacity: 0.5;
  }

  ${({ theme }) => theme.media.tinyMobileUp} {
    height: 50px;
  }

  ${({ theme }) => theme.media.smallMobileUp} {
    height: 30px;
  }
  ${({ theme }) => theme.media.tabletPortraitUp} {
    height: 30px;
  }
  ${({ theme }) => theme.media.desktopUp} {
    height: 22px;
  }
`;

export const ListFooterUnorderedList = styled.ul`
  padding: 0;
  list-style-type: none;
`;

export const FooterListSpan = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.components.footer?.listTitle};

  display: flex;
`;

export const FooterAnchorLinks = styled.a`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.components.footer?.listItems};
  padding: 5px 0;
  &:hover {
    text-decoration: none;
    opacity: 0.5;
  }
`;

export const FooterBottom = styled.div`
  white-space: nowrap;
  border-top: 1px solid #222;
  padding: 20px 0 44px;
  ${({ theme }) => theme.media.smallMobileUp} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SystemCopyright = styled.span`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  width: 100%;
  color: ${({ theme }) => theme.components.footer?.listItems};

  ${({ theme }) => theme.media.tinyMobileUp} {
    & :last-child {
      padding-left: 0;
    }
  }

  ${({ theme }) => theme.media.mobileUp} {
    & :first-child {
      margin-right: auto;
    }
    & :last-child {
      padding-left: 0;
    }
  }
`;

export const CopyrightLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: 0;
  ${({ theme }) => theme.media.tinyMobileUp} {
    margin-top: 15px;
    padding-top: 15px;
  }
  ${({ theme }) => theme.media.mobileUp} {
    margin-top: 0;
    padding-top: 0;
  }
`;

export const CopyrightLinksListItem = styled.li`
  display: flex;
  padding: 0 15px;
  &:last-child {
    padding-right: 0 !important;
  }

  ${({ theme }) => theme.media.tinyMobileUp} {
    &:first-child {
      padding-left: 0;
    }
  }

  ${({ theme }) => theme.media.smallMobileUp} {
    padding: 0 15px;
  }

  ${({ theme }) => theme.media.mobileUp} {
    padding: 0 5px;
  }

  ${({ theme }) => theme.media.mobileLandscapeUp} {
    padding: 0 15px;
  }
`;
