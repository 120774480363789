import React from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { Badge } from '../../Atoms/Badge/Badge';
import { Text } from '../../Atoms/Text/Text';
import { Button } from '../../Atoms/Button/Button';
import { StyledSection, TopImage } from './Dex365Header.css';

export const PrintVariations = (args: any) => {
  /**
   * function to handle button click, which also hides the button and shows the check in icon
   */
  function handleClick() {
    settings.callbacks.onSubmit();
  }

  const { className: variation } = args;
  const { settings } = args;

  switch (variation) {
    case 'primary':
      return (
        <>
          <div className='section-top'>
            {settings.text.badgeLabel && settings.text.badgeLabel !== '' ? (
              <Badge className={settings.badgeVariant}>
                {settings.text.badgeLabel}{' '}
              </Badge>
            ) : null}
            <Text className='primary'>{settings.text.dateTime} </Text>
          </div>
          <TopImage
            topImage={settings?.topTitleImage}
            className='section-image'
          />
          <StyledSection className='section-middle'>
            <Heading tag={settings.titleType} className='primary'>
              {settings.text.title}
            </Heading>
            <Text className='primary'>{settings.text.description} </Text>
          </StyledSection>
          <div className='section-bottom'>
            {settings.text.buttonLabel && settings.text.buttonLabel !== '' ? (
              <Button
                className='primary'
                onClick={handleClick}
                disabled={settings.text.buttonDisabled}
              >
                {settings.text.buttonLabel}{' '}
              </Button>
            ) : null}
          </div>
        </>
      );
      break;
    default:
      return <p>No Dex365Header variation selected</p>;
  }
};
