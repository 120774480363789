import { GenericWithDocumentID } from '../../../types';
import { Event365 } from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import {
  getGradientColors,
  getStatusBadgeStatus,
} from '../../Events/Overview/helpers';
import { Dex365ContainerAppSettings, Dex365HeaderSettings } from './types';

export const settings = (
  headerData: GenericWithDocumentID<Event365>,
  appSettings: Dex365ContainerAppSettings
) => {
  const { showButtonLabel, customButtonLabel, buttonLabel, callbacks } =
    appSettings;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id,
    accentColor,
    startTimeMs,
    endTimeMs,
    // TODO: Add this field to our datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modalImage,
    headerImage,
    language: {
      EN: {
        title,
        buttonLabel: buttonLabelInLanguage,
        descriptionShort: description = '',
        when = '',
      },
    },
  } = headerData;
  const backgroundImage = headerImage ?? modalImage;
  const statusBadgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
  const gradientColors = getGradientColors(accentColor, [0.9, 0.5, 0.9]);
  const settings: Dex365HeaderSettings = {
    text: {
      badgeLabel: statusBadgeStatus?.text,
      dateTime: when,
      titleType: 'h1',
      title,
      description,
      buttonLabel: showButtonLabel
        ? customButtonLabel ?? buttonLabelInLanguage ?? buttonLabel ?? ''
        : '',
    },
    callbacks: {
      onSubmit: () => {
        callbacks?.onSubmit(headerData.slug);
      },
    },
    badgeVariant: statusBadgeStatus?.variant,
    backgroundImage: `${process.env.REACT_APP_STORAGE_URL}${backgroundImage}`,
    // topTitleImage: '/logo512.png',
    topTitleImage: '',
    firstGradientColor: gradientColors[0],
    secondGradientColor: gradientColors[1],
    thirdGradientColor: gradientColors[2],
  };
  return settings;
};
