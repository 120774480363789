import React from 'react';
import { useAnalytics } from '../../utils/hooks/analytics/useAnalytics';
import * as SC from './styles';

type Props = {
  src: string;
  title: string;
};

function FormPage({ src, title }: Props) {
  useAnalytics();

  return (
    <SC.Wrapper>
      <SC.Container>
        <iframe
          src={src}
          width="100%"
          height="100%"
          frameBorder="0"
          title={title}
        ></iframe>
      </SC.Container>
    </SC.Wrapper>
  );
}

export default FormPage;
