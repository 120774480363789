import * as Models from "./AllModels";

export const DexperBigQuery = {
    analyticsDataset: "analytics",
    analyticsTables: {
        chat: "chat",
        clickPings: "click",
        pings: "ping",
        meetups: "meetup",
        mySchedule: "mySchedule",
        qAndA: "question",
        qAndAVotes: "questionVote",
        sessions: "session",
        users: "user",
        VOD: "VOD",
        series: "series",
        tracks: "tracks",
        sponsors: "sponsors",
        hubspotContacts: "hubspotContact",
        dex365VOD: "dex365VODs",
        dex365Series: "dex365Series",
        dex365Event: "dex365Events",
        dex365Resources: "dex365Resources",
        fairtecContacts: "fairtecContact",
        speakers: "speaker",
        nps: "nps",
    },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type TypedPath<T> = {
    path: string;
};

export class TypedCollection<T> {
    path: string;
    //This is here to ensure type checking happens, typescript structural typing ignores nominal type issues otherwise.
    // @ts-ignore
    private no: T;

    collectionName: string;
    pathElements: string[];

    constructor(collectionName: string, parentElements?: string[]) {
        this.pathElements = (parentElements ?? []).concat([collectionName]);
        this.path = this.pathElements.join("/");
        this.collectionName = collectionName;
    }

    doc<DocT = T>(id: string): TypedDoc<DocT> {
        return new TypedDoc<DocT>(id, this.pathElements);
    }
}

export class TypedDoc<T> {
    path: string;
    //This is here to ensure type checking happens, typescript structural typing ignores nominal type issues otherwise.
    // @ts-ignore
    private no: T;

    collectionName: string | undefined;
    id: string;
    pathElements: string[];

    constructor(id: string, parentElements?: string[]) {
        this.pathElements = (parentElements ?? []).concat([id]);
        this.path = this.pathElements.join("/");
        this.id = id;
        this.collectionName = parentElements?.[parentElements.length - 1];
    }

    collection<SubT>(id: string): TypedCollection<SubT> {
        return new TypedCollection<SubT>(id, this.pathElements);
    }
}

/**
 * Use with a helper method to retrieve typed document and collection references.
 * 
    const dexFirestore = new DexperFirestore();
    const typedCollectionRef = collectionHelper( dexFirestore.meetup("somevent"));
    const typedDocRef = docHelper(dexFirestore.meetup("someevent", "somemeetupID"));

    With helpers as:
    Webv9:
    function collectionHelper<T>(path: TypedCollection<T>): CollectionReference<T> {
        return collection(getFirestore(), path.path) as CollectionReference<T>
    }
    function docHelper<T>(path: TypedDoc<T>): DocumentReference<T> {
        return doc(getFirestore(), path.path) as DocumentReference<T>
    }
    Admin / webv8
    function collectionHelper<T>(path: TypedCollection<T>): CollectionReference<T> {
        return firestore.collection(path) as CollectionReference<T>
    }
    function docHelper<T>(path: TypedDoc<T>): DocumentReference<T> {
        return firestore.doc(path.path) as DocumentReference<T>
    }
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class DexperFirestore {
    private internal(): TypedCollection<unknown> {
        return new TypedCollection("internal");
    }
    internalAuthConfig(): TypedDoc<Models.InternalConfigAuthentication> {
        return this.internal().doc<Models.InternalConfigAuthentication>("configAuthentication");
    }
    internalAnalyticsConfig(): TypedDoc<Models.InternalConfigAnalytics> {
        return this.internal().doc<Models.InternalConfigAnalytics>("configAnalytics");
    }
    internalSchedulerConfig(): TypedDoc<Models.InternalConfigScheduler> {
        return this.internal().doc<Models.InternalConfigScheduler>("configScheduler");
    }
    private internalHubspot(): TypedDoc<unknown> {
        return this.internal().doc("hubspot");
    }
    private internalFairtec(): TypedDoc<unknown> {
        return this.internal().doc("fairtec");
    }
    hubspotContacts(): TypedCollection<Models.HubspotContact>;
    hubspotContacts(docID: string): TypedDoc<Models.HubspotContact>;
    hubspotContacts(docID?: string) {
        const collection = this.internalHubspot().collection<Models.HubspotContact>("contacts");
        return docID ? collection.doc(docID) : collection;
    }
    hubspotQueue(): TypedCollection<Models.HubspotQueue>;
    hubspotQueue(docID: string): TypedDoc<Models.HubspotQueue>;
    hubspotQueue(docID?: string) {
        const collection = this.internalHubspot().collection<Models.HubspotQueue>("exportqueue");
        return docID ? collection.doc(docID) : collection;
    }
    fairtecContacts(): TypedCollection<Models.FairtecImport>;
    fairtecContacts(docID: string): TypedDoc<Models.FairtecImport>;
    fairtecContacts(docID?: string) {
        const collection = this.internalFairtec().collection<Models.FairtecImport>("contacts");
        return docID ? collection.doc(docID) : collection;
    }
    fairtecIndex(): TypedCollection<Models.FairtecExport>;
    fairtecIndex(docID: string): TypedDoc<Models.FairtecExport>;
    fairtecIndex(docID?: string) {
        const collection = this.internalFairtec().collection<Models.FairtecExport>("exportIndex");
        return docID ? collection.doc(docID) : collection;
    }

    userProfilePrivate(): TypedCollection<Models.UserProfilePrivate>;
    userProfilePrivate(docID: string): TypedDoc<Models.UserProfilePrivate>;
    userProfilePrivate(docID?: string) {
        const collection = new TypedCollection<Models.UserProfilePrivate>("userProfilePrivate");
        return docID ? collection.doc(docID) : collection;
    }
    userProfilePublic(): TypedCollection<Models.UserProfilePublic>;
    userProfilePublic(docID: string): TypedDoc<Models.UserProfilePublic>;
    userProfilePublic(docID?: string) {
        const collection = new TypedCollection<Models.UserProfilePublic>("userProfilePublic");
        return docID ? collection.doc(docID) : collection;
    }

    dex365Series(): TypedCollection<Models.Series>;
    dex365Series(docID: string): TypedDoc<Models.Series>;
    dex365Series(docID?: string) {
        const collection = new TypedCollection<Models.Series>("dex365Series");
        return docID ? collection.doc(docID) : collection;
    }
    dex365Resources(): TypedCollection<Models.Resource>;
    dex365Resources(docID: string): TypedDoc<Models.Resource>;
    dex365Resources(docID?: string) {
        const collection = new TypedCollection<Models.Resource>("dex365Resources");
        return docID ? collection.doc(docID) : collection;
    }
    dex365Events(): TypedCollection<Models.Event365>;
    dex365Events(docID: string): TypedDoc<Models.Event365>;
    dex365Events(docID?: string) {
        const collection = new TypedCollection<Models.Event365>("dex365Events");
        return docID ? collection.doc(docID) : collection;
    }
    dex365VODs(): TypedCollection<Models.VOD>;
    dex365VODs(docID: string): TypedDoc<Models.VOD>;
    dex365VODs(docID?: string) {
        const collection = new TypedCollection<Models.VOD>("dex365VODs");
        return docID ? collection.doc(docID) : collection;
    }

    private clientConfig(): TypedCollection<unknown> {
        return new TypedCollection("clientConfig");
    }
    private clientContent(): TypedCollection<unknown> {
        return new TypedCollection("clientContent");
    }

    config365(): TypedDoc<Models.Config365> {
        return this.clientConfig().doc<Models.Config365>("config365");
    }
    configDashboard(): TypedDoc<Models.ConfigDashboard> {
        return this.clientConfig().doc<Models.ConfigDashboard>("configDashboard");
    }
    clientCategoryConfig(): TypedDoc<Models.CategoryConfig> {
        return this.clientConfig().doc<Models.CategoryConfig>("configCategories");
    }
    clientLanguageConfig(): TypedDoc<Models.LanguageConfig> {
        return this.clientConfig().doc<Models.LanguageConfig>("configLanguages");
    }
    content365(): TypedDoc<Models.ClientContentGeneral> {
        return this.clientContent().doc<Models.ClientContentGeneral>("content365");
    }

    event(): TypedCollection<Models.Event>;
    event(docID: string): TypedDoc<Models.Event>;
    event(docID?: string) {
        const collection = new TypedCollection<Models.Event>("events");
        return docID ? collection.doc(docID) : collection;
    }

    private eventContent(event: string): TypedCollection<unknown> {
        return this.event(event).collection("content");
    }
    eventContentGeneral(event: string): TypedDoc<Models.GeneralContent> {
        return this.eventContent(event).doc<Models.GeneralContent>("contentGeneral");
    }
    eventContentLandingPage(event: string): TypedDoc<Models.LandingPageContent> {
        return this.eventContent(event).doc<Models.LandingPageContent>("contentLandingPage");
    }
    eventContentLogin(event: string): TypedDoc<Models.LoginContent> {
        return this.eventContent(event).doc<Models.LoginContent>("contentLogin");
    }
    eventContentFAQ(event: string): TypedDoc<Models.FAQContent> {
        return this.eventContent(event).doc<Models.FAQContent>("contentFAQ");
    }

    eventFAQ(event: string): TypedCollection<Models.Event>;
    eventFAQ(event: string, docID: string): TypedDoc<Models.Event>;
    eventFAQ(event: string, docID?: string) {
        const collection = this.eventContentFAQ(event).collection("faqs");
        return docID ? collection.doc(docID) : collection;
    }
    eventNpsRatings(event: string): TypedCollection<Models.NpsRating>;
    eventNpsRatings(event: string, docID: string): TypedDoc<Models.NpsRating>;
    eventNpsRatings(event: string, docID?: string) {
        const collection = this.event(event).collection("npsRatings");
        return docID ? collection.doc(docID) : collection;
    }
    private eventConfig(event: string): TypedCollection<unknown> {
        return this.event(event).collection("configGeneral");
    }

    eventCustomPage(event: string): TypedCollection<Models.CustomPage>;
    eventCustomPage(event: string, docID: string): TypedDoc<Models.CustomPage>;
    eventCustomPage(event: string, docID?: string) {
        const collection = this.event(event).collection("customPages");
        return docID ? collection.doc(docID) : collection;
    }
    eventPublicCustomPage(event: string): TypedCollection<Models.CustomPage>;
    eventPublicCustomPage(event: string, docID: string): TypedDoc<Models.CustomPage>;
    eventPublicCustomPage(event: string, docID?: string) {
        const collection = this.event(event).collection("publicCustomPages");
        return docID ? collection.doc(docID) : collection;
    }

    configDays(event: string): TypedDoc<Models.DayConfig> {
        return this.eventConfig(event).doc<Models.DayConfig>("configDays");
    }
    configNotifications(event: string): TypedDoc<Models.NotificationConfig> {
        return this.eventConfig(event).doc<Models.NotificationConfig>("configNotifications");
    }
    configMySchedule(event: string): TypedDoc<Models.MyScheduleConfig> {
        return this.eventConfig(event).doc<Models.MyScheduleConfig>("configMySchedule");
    }
    configMyProfile(event: string): TypedDoc<Models.MyProfileConfig> {
        return this.eventConfig(event).doc<Models.MyProfileConfig>("configMyProfile");
    }
    configSessions(event: string): TypedDoc<Models.SessionConfig> {
        return this.eventConfig(event).doc<Models.SessionConfig>("configSessions");
    }
    configMeetups(event: string): TypedDoc<Models.MeetupConfig> {
        return this.eventConfig(event).doc<Models.MeetupConfig>("configMeetups");
    }
    configMeetupUrls(event: string): TypedDoc<Models.MeetupUrlConfig> {
        return this.eventConfig(event).doc<Models.MeetupUrlConfig>("configMeetupUrl");
    }
    configLanguages(event: string): TypedDoc<Models.LanguageConfig> {
        return this.eventConfig(event).doc<Models.LanguageConfig>("configLanguages");
    }
    configEvent(event: string): TypedDoc<Models.EventConfig> {
        return this.eventConfig(event).doc<Models.EventConfig>("configEvent");
    }
    configCategories(event: string): TypedDoc<Models.CategoryConfig> {
        return this.eventConfig(event).doc<Models.CategoryConfig>("configCategories");
    }
    configSponsors(event: string): TypedDoc<Models.SponsorConfig> {
        return this.eventConfig(event).doc<Models.SponsorConfig>("configSponsors");
    }
    expertMeeting(event: string): TypedCollection<Models.ExpertMeeting>;
    expertMeeting(event: string, docID: string): TypedDoc<Models.ExpertMeeting>;
    expertMeeting(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.ExpertMeeting>("expertMeetings");
        return docID ? collection.doc(docID) : collection;
    }

    meetup(event: string): TypedCollection<Models.Meetup>;
    meetup(event: string, docID: string): TypedDoc<Models.Meetup>;
    meetup(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Meetup>("meetups");
        return docID ? collection.doc(docID) : collection;
    }
    meetupSignups(event: string): TypedCollection<Models.MeetupSignup>;
    meetupSignups(event: string, meetupID: string, uid: string): TypedDoc<Models.MeetupSignup>;
    meetupSignups(event: string, meetupID?: string, uid?: string) {
        const collection = this.event(event).collection<Models.MeetupSignup>("meetupsSignups");
        return meetupID && uid ? collection.doc(`${meetupID}-${uid}`) : collection;
    }

    vod(event: string): TypedCollection<Models.VOD>;
    vod(event: string, docID: string): TypedDoc<Models.VOD>;
    vod(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.VOD>("VOD");
        return docID ? collection.doc(docID) : collection;
    }
    series(event: string): TypedCollection<Models.Series>;
    series(event: string, docID: string): TypedDoc<Models.Series>;
    series(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Series>("series");
        return docID ? collection.doc(docID) : collection;
    }
    chat(event: string): TypedCollection<Models.Chat>;
    chat(event: string, docID: string): TypedDoc<Models.Chat>;
    chat(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Chat>("chat");
        return docID ? collection.doc(docID) : collection;
    }
    messages(event: string, chatID: string): TypedCollection<Models.Message>;
    messages(event: string, chatID: string, docID: string): TypedDoc<Models.Message>;
    messages(event: string, chatID: string, docID?: string) {
        const collection = this.chat(event, chatID).collection<Models.Message>("message");
        return docID ? collection.doc(docID) : collection;
    }
    mySchedule(event: string): TypedCollection<Models.MySchedule>;
    mySchedule(event: string, docID: string): TypedDoc<Models.MySchedule>;
    mySchedule(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.MySchedule>("mySchedule");
        return docID ? collection.doc(docID) : collection;
    }
    notifications(event: string): TypedCollection<Models.Notification>;
    notifications(event: string, docID: string): TypedDoc<Models.Notification>;
    notifications(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Notification>("notifications");
        return docID ? collection.doc(docID) : collection;
    }

    notificationLog(event: string): TypedCollection<Models.NotificationTracker>;
    notificationLog(event: string, docID: string): TypedDoc<Models.NotificationTracker>;
    notificationLog(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.NotificationTracker>("notificationLog");
        return docID ? collection.doc(docID) : collection;
    }

    scheduleOverview(event: string): TypedDoc<Models.ScheduleOverview> {
        return this.event(event).collection("schedule").doc<Models.ScheduleOverview>("overview");
    }

    sessions(event: string): TypedCollection<Models.Session>;
    sessions(event: string, docID: string): TypedDoc<Models.Session>;
    sessions(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Session>("sessions");
        return docID ? collection.doc(docID) : collection;
    }
    qAndA(event: string, sessionID: string): TypedCollection<Models.QAndA>;
    qAndA(event: string, sessionID: string, docID: string): TypedDoc<Models.QAndA>;
    qAndA(event: string, sessionID: string, docID?: string) {
        const collection = this.sessions(event, sessionID).collection<Models.QAndA>("questions");
        return docID ? collection.doc(docID) : collection;
    }

    speakers(event: string): TypedCollection<Models.Speaker>;
    speakers(event: string, docID: string): TypedDoc<Models.Speaker>;
    speakers(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Speaker>("speakers");
        return docID ? collection.doc(docID) : collection;
    }
    sponsors(event: string): TypedCollection<Models.Sponsor>;
    sponsors(event: string, docID: string): TypedDoc<Models.Sponsor>;
    sponsors(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Sponsor>("sponsors");
        return docID ? collection.doc(docID) : collection;
    }
    tracks(event: string): TypedCollection<Models.Track>;
    tracks(event: string, docID: string): TypedDoc<Models.Track>;
    tracks(event: string, docID?: string) {
        const collection = this.event(event).collection<Models.Track>("tracks");
        return docID ? collection.doc(docID) : collection;
    }

    private stylesLibraries(event: string): TypedCollection<unknown> {
        return this.event(event).collection("stylesLibraries");
    }
    configuratorFontCss(event: string): TypedDoc<Models.ConfiguratorCSS> {
        return this.stylesLibraries(event).doc<Models.ConfiguratorCSS>("fontCssOutput");
    }
    configuratorCurrentStyle(event: string): TypedDoc<Models.ConfiguratorCSS> {
        return this.stylesLibraries(event).doc<Models.ConfiguratorCSS>("configCurrentStyle");
    }

    private analyticsValidation(): TypedCollection<unknown> {
        return new TypedCollection("analyticsValidation");
    }
    pingTracker(event: string): TypedCollection<Models.PingTracker>;
    pingTracker(event: string, docID: string): TypedDoc<Models.PingTracker>;
    pingTracker(event: string, docID?: string) {
        const collection = this.analyticsValidation().doc(event).collection<Models.PingTracker>("pings");
        return docID ? collection.doc(docID) : collection;
    }
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
