import styled, { css } from 'styled-components';

interface CardSeriesContainerProps {
  backgroundImage: string;
  firstGradientColor: string;
  secondGradientColor: string;
}

export const CardSeriesContainer = styled.button<CardSeriesContainerProps>`
  position: relative;
  background: linear-gradient(
      360deg,
      ${({ firstGradientColor }) => firstGradientColor} 8%,
      ${({ secondGradientColor }) => secondGradientColor} 100%
    ),
    url(${({ backgroundImage }) => backgroundImage}) no-repeat center center;
  background-size: cover;

  /* hover properties on the hover section */
  .section-hover {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.5s ease;
  }

  :hover .section-hover {
    max-height: 200px;
  }

  /* transition of scale (i dont like it) - uncomment to implement */
  /* 
  transition: transform 1s ease;
  scale(1); 

  :hover{
    transform: scale(1.03);
  }  
  */
`;

export const StyledSection = styled.div`
  width: 100%;
`;

interface CardSeriesTopProps {
  topImage?: string;
}
export const TopSection = styled.div<CardSeriesTopProps>`
  position: absolute;
  width: 100px;
  height: 100px;
  ${({ topImage }) =>
    topImage &&
    css`
      background: center/cover url(${topImage});
      background-size: contain;
      background-repeat: no-repeat;
    `}
  top: 50px;
  left: 10%;
`;
