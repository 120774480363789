import styled, { css } from 'styled-components';

interface ButtonProps {
  background: string;
}

export const ButtonStyle = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  outline-color: transparent;

  /*csslint important: false*/
  ${({ background }) =>
    background &&
    css`
      background: center/cover url(${background});
      background-size: contain;
      background-repeat: no-repeat;
    `}
  /*csslint important: true*/
  
  :focus-visible {
    outline: 2px solid #00a1f1;
    outline-offset: 2px;
  }
`;

export const ImgStyle = styled.svg`
  width: 1em;
  height: 1em;
`;
