import React, { useState } from 'react';
import {
  StyledBackButton,
  StyledHasVoted,
  StyledProfile,
  StyledQnACard,
  StyledScrollContainer,
  StyledSeeAnswerButton,
} from './QandACard.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { Text } from '../../Atoms/Text/Text';
import { Icon } from '../../Atoms/Icon/Icon';
import { Button } from '../../Atoms/Button/Button';
import {
  QandACardDefaultProps,
  QandACardPropTypes,
  QandACardTypes,
} from './types';
import { State } from '../../../definitions/States';
import { atoms } from './atoms';
import ProfilePicture1 from './assets/profile-1.jpg';

export const QandACardStory = {
  className: 'primary',
  content: {
    translation: {
      qa_cards: 'Q&A cards',
      vote: 'Vote',
      voted: 'Voted',
      nvotes: '@ votes',
      ask_question: 'Ask a question',
      see_answer: 'See answer',
      back_to_question: 'Back to question',
      qa_will_start: 'Q&A will start in next session!',
      qa_will_start_sub:
        'When the next session starts you can enter your questions here.',
      ask_a_question: 'Ask a question',
      ask_a_question_desc:
        'Suspendisse vitae arcu nec felis molestie scelerisque sed mollis ipsum. Maecenas volutpat eleifend diam, vel ornare dolor imperdiet at. Donec ac tellus in lectus euis tellus in lectus euis',
      submit: 'Submit',
      question_sent: 'Question sent',
      question_sent_desc:
        'Suspendisse vitae arcu nec felis molestie scelerisque sed mollis ipsum. Maecenas volutpat eleifend diam, vel ornare dolor imperdiet at. Donec ac tellus in lectus euis tellus in lectus euis',
      go_to_live: 'Go to livestream',
    },
    question:
      'What is ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat? Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum?',
    answer:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    answered: true,
    firstName: 'Dan',
    lastName: 'Paciello',
    questioner_pict: ProfilePicture1,
    votesCount: 12,
    hasVoted: false,
    useProcessingAnimation: true,
    id: 'id',
    activeElm: {},
    userPublic: '',
    onVote: () => {
      console.log('Vote');
    },
    onOpenMiniProfile: () => {
      console.log('Open mini-profile');
    },
  },
};

export const QandACard: React.FC<QandACardTypes> = ({
  className,
  content: {
    translation,
    question,
    answer,
    answered,
    firstName,
    lastName,
    questioner_pict,
    votesCount,
    hasVoted,
    onVote,
    onOpenMiniProfile,
    userPublic,
    useProcessingAnimation,
    id,
    activeElm,
  },
  useCheckIcon,
}) => {
  const [showQuestion, setShowQuestion] = useState(true);
  const votes = translation.nvotes.replace(/@/, votesCount.toString());
  const IconBack = () => (
    <Icon
      className='primary'
      location='/assets/icons/arrow-left.svg'
      content={{ label: '' }}
    />
  );
  const IconCheck = () => (
    <Icon
      className='secondary'
      location='/assets/icons/check.svg'
      content={{ label: '' }}
    />
  );
  const IconProcessing = () => (
    <Icon
      className='tertiary'
      location='/assets/icons/three-dots.svg'
      content={{ label: '' }}
    />
  );
  return showQuestion ? (
    <StyledQnACard className={classNames('QandACard', className)}>
      <StyledProfile>
        <Button className='quinary' image={questioner_pict} />
        <Button
          className='quaternary'
          onClick={() => onOpenMiniProfile && onOpenMiniProfile(userPublic)}
        >
          {firstName} {lastName}
        </Button>
      </StyledProfile>
      <StyledScrollContainer>
        <Text className='tertiary'>{question}</Text>
        {answered && (
          <StyledSeeAnswerButton
            className='secondary'
            onClick={() => setShowQuestion(false)}
          >
            {translation.see_answer}
          </StyledSeeAnswerButton>
        )}
      </StyledScrollContainer>
      <div>
        {hasVoted ? (
          <>
            {useCheckIcon ? (
              IconCheck()
            ) : (
              <StyledHasVoted>
                <Text className='secondary'>{translation.voted}</Text>
                {IconCheck()}
              </StyledHasVoted>
            )}
          </>
        ) : activeElm?.voteIsLoading && activeElm?.votedElm?.id === id ? (
          IconBack()
        ) : (
          <Button
            className='primary'
            onClick={() => {
              onVote(id);
            }}
          >
            {translation.vote}
          </Button>
        )}
        {useProcessingAnimation ? (
          IconProcessing()
        ) : (
          <Text className='quaternary'>{votes}</Text>
        )}
      </div>
    </StyledQnACard>
  ) : (
    <StyledQnACard className={classNames('QandACard', className)}>
      <StyledBackButton
        className='tertiary'
        onClick={() => setShowQuestion(true)}
      >
        {IconBack()}
        {translation.back_to_question}
      </StyledBackButton>
      <StyledScrollContainer>
        <Text className='primary'>{answer}</Text>
      </StyledScrollContainer>
      <div>
        <span />
        <Text className='quaternary'>{votes}</Text>
      </div>
    </StyledQnACard>
  );
};

QandACard.propTypes = QandACardPropTypes;
QandACard.defaultProps = QandACardDefaultProps;

const allCategories = [
  CmsConfig.frame,
  CmsConfig.fill,
  CmsConfig.color,
  CmsConfig.flexbox,
  // CmsConfig.effects,
  CmsConfig.border,
  CmsConfig.padding,
];

export const QandACardSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  variants: [
    {
      className: '&primary',
      description: 'QandACard primary',
    },
  ],
};
