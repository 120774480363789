import styled from 'styled-components';

export const StyledContainer = styled.div`
  .Navigation365.primary {
    display: flex;
    background: #141a28;
    min-height: 72px;
    padding: 0 var(--responsivePadding);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .Icon {
    display: none;
    height: 0;
    @media (min-width: 1024px) {
      display: initial;
    }
  }
  .section-logo {
    padding: 16px 0 0;
    margin-right: 100px;
    @media (min-width: 720px) {
      padding: 0;
    }
    img {
      width: 100px;
      height: 26px;
    }
  }
  .section-links {
    display: flex;
    gap: 30px;
    margin-right: 131px;
    padding-bottom: 16px;
    padding-top: 16px;
    font-size: 14px;
    @media (min-width: 720px) {
      padding: 0;
      gap: 40px;
    }
    a {
      color: #fff;
    }
    a.active {
      color: #1bc492;
      font-size: 14px;
      font-family: Dexper;
      letter-spacing: 0.25px;
      line-height: 19.96px;
    }
  }
  .Link.primary {
    font-family: Dexper;
    letter-spacing: 0.25px;
    line-height: 19.6px;
    font-size: 14px;
    --link-decoration: none;
  }
  .Link.primary.primary:hover {
    opacity: 0.8;
  }
`;
