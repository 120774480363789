import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FilterBar365Container } from './FilterBar365.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';

import { Link, LinkSettings } from '../../Atoms/Link/Link';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';

/* 
Implementation example:
=======================

import {FilterBar365Story} from '@dexper.events/product-atomic-ui';

const {settings : filterBar365Settings} = FilterBar365Story;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <FilterBar365 settings={filterBar365Settings} className="primary"></$> 
     </div>
 </div> 

*/

export const filterBar365Settings = {
  text: {
    filter: 'Filter',
  },
  links: [
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'All',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Live',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Upcoming',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Concluded',
      location: '#',
      children: null,
    },
  ],
  filterIcon: '/assets/icons/filter-white.svg',
  callbacks: {
    onFilter: (...args: any[]) => {
      console.log('onFilter fired', args);
    },
  },
  // more props here:
};

export const FilterBar365Story = {
  settings: filterBar365Settings,
  className: 'primary',
};

const FilterBar365PropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({}).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string.isRequired,
        anchorType: PropTypes.string,
        targetBlank: PropTypes.bool,
        callback: PropTypes.func,
        label: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        children: PropTypes.string,
      }),
    ).isRequired,
    filterIcon: PropTypes.string,
    callbacks: PropTypes.shape({
      onFilter: PropTypes.func.isRequired,
    }).isRequired,
  }),
};

export type FilterBar365Types = InferProps<typeof FilterBar365PropTypes>;

export const FilterBar365: React.FC<FilterBar365Types> = ({
  className,
  settings,
}) => {
  return (
    <FilterBar365Container className={classNames('FilterBar365', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </FilterBar365Container>
  );
};

const atoms = [
  {
    atom: Link,
    name: 'Link~primary',
    description: 'Link navbar',
    settings: LinkSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button navbar',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
];
//more atoms here:

export const FilterBar365Settings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
    CmsConfig.text,
    CmsConfig.altColor,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-links',
      description: 'Section links',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.altColor,
      ],
    },
    {
      className: 'section-button',
      description: 'Section button',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.altColor,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'FilterBar365 primary',
    },
  ],
};

FilterBar365.propTypes = FilterBar365PropTypes;
