import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { TextAreaContainer } from './TextArea.css';
import {
  ErrorMessage,
  ErrorMessageSettings,
} from '../../Atoms/ErrorMessage/ErrorMessage';
import {
  TextAreaAtom,
  TextAreaAtomSettings,
} from '../../Atoms/TextAreaAtom/TextAreaAtom';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
/* 
interface ErrorMessagesType {
  mandatory: string;
  limit500: string;
  notice?: string;
  placeholder?: string;
}
interface TextType {
  notice?: string;
  placeholder?: string;
}
interface TranslationType {
  errorMessages: ErrorMessagesType;
  texts: TextType;
} 
*/

const englishSettings = {
  errorMessages: {
    mandatory: 'This field is mandatory',
    limit500: 'Max 500 characters allowed',
    notice: '* Max 500 characters',
    placeholder: 'Message',
  },
  texts: {
    notice: 'Max 500 characters',
    placeholder: 'Enter your message',
  },
};

export const textAreaSettings = {
  errorMsg: 'This is an error message',
  translation: englishSettings,
  required: true,
  disabled: false,
  error: false,
  value: '',
  type: 'text',
  name: '',
  customRef: null,
  label: 'This is a label',
  autocomplete: '',
  remainingCharacters: 0,
  maxLength: 500,
  callbacks: {
    onBlur: (...args: any[]) => {
      console.log('onBlur fired', args);
    },
    onChange: (...args: any[]) => {
      console.log('onChange fired', args);
    },
  },
};

export const TextAreaStory = {
  className: 'primary',
  settings: textAreaSettings,
};

const TextAreaPropTypes = {
  className: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    translation: PropTypes.shape({
      errorMessages: PropTypes.shape({
        mandatory: PropTypes.string,
        limit500: PropTypes.string,
        notice: PropTypes.string,
        placeholder: PropTypes.string,
      }),
      texts: PropTypes.shape({
        notice: PropTypes.string,
        placeholder: PropTypes.string,
      }),
    }),
    errorMsg: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    value: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    customRef: PropTypes.any,
    label: PropTypes.string,
    autocomplete: PropTypes.string,
    remainingCharacters: PropTypes.number,
    maxLength: PropTypes.number,

    callbacks: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
    }),
  }).isRequired,
};

export type TextAreaTypes = InferProps<typeof TextAreaPropTypes>;

export const TextArea: React.FC<TextAreaTypes> = ({ className, settings }) => {
  return (
    <TextAreaContainer className={classNames('TextArea', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </TextAreaContainer>
  );
};

const atoms = [
  {
    atom: ErrorMessage,
    name: 'ErrorMessage~primary',
    description: 'ErrorMessage primary',
    settings: ErrorMessageSettings,
    atomVariant: '&primary',
  },
  {
    atom: TextAreaAtom,
    name: 'TextAreaAtom~primary',
    description: 'TextAreaAtom primary',
    settings: TextAreaAtomSettings,
    atomVariant: '&primary',
  },
  {
    atom: TextAreaAtom,
    name: 'TextAreaAtom~error',
    description: 'TextAreaAtom error',
    settings: TextAreaAtomSettings,
    atomVariant: '&error',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text remain chars',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
];

export const TextAreaSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.fill,
    CmsConfig.border,
    CmsConfig.padding,
    CmsConfig.frame,
    CmsConfig.flexbox,
    CmsConfig.placeholder,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-top-text',
      description: 'Section top text',
    },
    {
      className: 'section-text-area',
      description: 'Section text area and error',
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'TextArea primary',
    },
    {
      className: '&secondary',
      description: 'TextArea secondary',
    },
    {
      className: '&error',
      description: 'TextArea error',
    },
  ],
};

/* TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  className: PropTypes.string,
};

TextArea.defaultProps = {
  className: 'primary',
  disabled: false,
  error: false,
  errorMsg: '',
  value: '',
  type: 'text',
  required: false,
  name: '',
  maxLength: 500,
}; */
