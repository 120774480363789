import { ButtonBack, ButtonNext, CarouselProvider } from 'pure-react-carousel';
import { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import arrowImage from './assets/arrow.svg';

export const StyledCarouselProvider = styled(CarouselProvider)`
  position: relative;
  .carousel__slide {
    margin: 8px;
  }
  margin: 0 -8px;
`;

const PaginationButton = css`
  position: absolute;
  top: calc(50% - 20px);
  z-index: 1;
  background: transparent url(${arrowImage});
  background-size: cover;
  border: 0;
  width: 40px;
  height: 40px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export const StyledButtonBack = styled<
  { offset?: number } & ComponentType<any>
>(ButtonBack)`
  ${PaginationButton}
  transform: scaleX(-1);
  left: 30px;
  ${({ offset }) =>
    offset &&
    css`
      top: calc(50% - 20px + ${offset}px);
    `}
`;

export const StyledButtonNext = styled<
  { offset?: number } & ComponentType<any>
>(ButtonNext)`
  ${PaginationButton}
  right: 30px;
  ${({ offset }) =>
    offset &&
    css`
      top: calc(50% - 20px + ${offset}px);
    `}
`;
