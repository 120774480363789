import styled from 'styled-components';

interface CardEventTypesContainerProps {
  backgroundImage: string | undefined;
  firstGradientColor: string | undefined;
  secondGradientColor: string | undefined;
}

export const CardEventContainer = styled.button<CardEventTypesContainerProps>`
  background: linear-gradient(
      180deg,
      ${({ firstGradientColor }) => firstGradientColor} 0%,
      ${({ secondGradientColor }) => secondGradientColor} 100%,
      #eaf2fb
    ),
    url(${({ backgroundImage }) => backgroundImage}) no-repeat center center;
  background-size: cover;

  /* accessability stuff: */
  outline-color: transparent;
  :focus-visible {
    outline: 2px solid #00a1f1;
    outline-offset: 2px;
  }

  /* because it's wrapped in a button for accessability (default is text-align: center) */
  text-align: left;

  /* hover properties on the hover section */
  .section-hover {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.5s ease;
  }

  :hover .section-hover {
    max-height: 200px;
  }
`;

export const StyledSection = styled.div`
  width: 100%;
`;
