import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { CardContainer } from './Card.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { PrintVariations } from './variations';
// export { CardStory } from './Card.stories';

export const settings = {
  text: {
    header: 'Hashicorp User Group',
    title: 'Amsterdam',
    subtitle: 'Netherlands',
    numberVotes: '205',
    votes: 'Votes',
    buttonText: 'Vote',
    signedIn: 'Signed in',
  },
  callbacks: {
    onSubmit: (...args: any[]) => {
      console.log('onSubmit fired', args);
    },
    onShare: (...args: any[]) => {
      console.log('onShare fired', args);
    },
  },
  isSubmited: false,
  isLoading: false,
  isFinished: false,
  showShareButton: true,
};

const CardPropTypes = {
  settings: PropTypes.shape({
    text: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      numberVotes: PropTypes.string.isRequired,
      votes: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onSubmit: PropTypes.func.isRequired,
      onShare: PropTypes.func.isRequired,
    }).isRequired,
    isSubmited: PropTypes.bool,
    isLoading: PropTypes.bool,
    isFinished: PropTypes.bool,
    showShareButton: PropTypes.bool,
  }),
  className: PropTypes.string,
};

export const CardStory = {
  settings: settings,
  className: 'primary',
};

export type CardTypes = InferProps<typeof CardPropTypes>;

export const Card: React.FC<CardTypes> = ({ className, settings }) => {
  return (
    <CardContainer open={true} className={classNames('Card', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </CardContainer>
  );
};

const atoms = [
  /*   {
    atom: Heading,
    description: 'Heading',
    settings: HeadingSettings,
    atomVariant: '&h1',
  },
  {
    atom: Text,
    description: 'Text header',
    settings: TextSettings,
    atomVariant: '&small',
  },
  {
    atom: Text,
    description: 'Text middle',
    settings: TextSettings,
    atomVariant: '&medium',
  },
  {
    atom: Text,
    description: 'Text bottom 1',
    settings: TextSettings,
    atomVariant: '&large',
  },
  {
    atom: Text,
    description: 'Text bottom 2',
    settings: TextSettings,
    atomVariant: '&custom',
  }, */
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button signup',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~secondary',
    description: 'Button cancel',
    settings: ButtonSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Button,
    name: 'Button~tertiary',
    description: 'Button test',
    settings: ButtonSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon check in',
    settings: IconSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    name: 'Icon~secondary',
    description: 'Icon social',
    settings: IconSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Text,
    name: 'Text~small',
    description: 'Text Small',
    settings: TextSettings,
    atomVariant: '&small',
  },
  {
    atom: Text,
    name: 'Text~medium',
    description: 'Text Medium',
    settings: TextSettings,
    atomVariant: '&medium',
  },
  {
    atom: Text,
    name: 'Text~large',
    description: 'Text Large',
    settings: TextSettings,
    atomVariant: '&large',
  },

  /*  
  {
    atom: Icon,
    description: 'Icon animated spinner',
    settings: IconSettings,
    atomVariant: '&primary',
  }, */
];

export const CardSettings: ISettings = {
  states: [State.default, State.hover],
  include: [
    CmsConfig.flexbox,
    CmsConfig.vmargin,
    CmsConfig.frame,
    CmsConfig.padding,
    CmsConfig.fill,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-header',
      description: 'Section header',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-middle',
      description: 'Section middle',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-bottom',
      description: 'Section bottom',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'Card primary',
    },
    {
      className: '&secondary',
      description: 'Card secondary',
    },
  ],
};

Card.propTypes = CardPropTypes;
