import styled from 'styled-components';
import { Button } from '../../Atoms/Button/Button';

export const StyledQnACard = styled.section`
  flex: 0 0 292px;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.19);
`;

export const StyledProfile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledScrollContainer = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #858687;
    width: 2px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #373942;
    border-radius: 10px;
  }
`;

export const StyledSeeAnswerButton = styled(Button)`
  padding: 0;
  border: none;
  background-color: transparent;
  color: #ac72f0;
  outline: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: 2px solid #000;
  }
`;

export const StyledBackButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  color: #fff;
  outline: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
  height: 26px;
  min-width: 100px;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: 2px solid #000;
  }
`;

export const StyledHasVoted = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 42px;
  padding: 0 20px;
  color: #000;
  background-color: initial;
  border: 1px solid #000;
  border-radius: 6px;
`;
