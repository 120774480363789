import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { TooltipButtonStyle, ImgStyle, TooltipTextStyle } from './Tooltip.css';

/*
Implementation example:
=======================

import {Tooltip} from '@dexper.events/product-atomic-ui';

const svgSrc = 'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Ficons%2Finfo.svg?alt=media&token=75241e90-81ba-4349-98a7-15926ade8d75';

<Tooltip
  className='primary'
  image={svgSrc}
  alt='Info'
>
  <div className='section-tooltip'>
    5 minutes before session, link will be provided
  </div>
</Tooltip>

In the parent component, add the tooltip atom and a section for the popup text:

export const ResourceCardSettings: ISettings = {
  …
  atoms: [
    …
    {
      atom: Tooltip,
      name: 'Tooltip~primary',
      description: 'Tooltip icon',
      settings: TooltipSettings,
      atomVariant: '&primary',
    },
  ],
  sections: [
    …
    {
      className: 'section-tooltip',
      description: 'Section tooltip',
      include: [
        CmsConfig.text,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
  ],
  …
};

*/

const TooltipPropTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export type TooltipTypes = InferProps<typeof TooltipPropTypes>;

/*
    Note: The Tooltip is not functional on its own.
          To style the text popup, wrap it in a section.
          See the implementation example.
 */
export const TooltipStory = {
  className: 'primary',
  image:
    'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Ficons%2Finfo.svg?alt=media&token=75241e90-81ba-4349-98a7-15926ade8d75',
  alt: 'Info',
  children: '5 minutes before session, link will be provided',
};

export const Tooltip: React.FC<TooltipTypes> = ({
  className,
  image,
  alt,
  children,
}) => {
  return (
    <TooltipButtonStyle
      className={classNames('Tooltip', className)}
      aria-label={alt || ''}
    >
      {image && (
        <ImgStyle viewBox='0 0 100 100'>
          <defs>
            <mask id={`mask-${image}`}>
              <image xlinkHref={image} width='100' height='100' />
            </mask>
          </defs>
          <rect
            width='100'
            height='100'
            style={{ fill: 'var(--alt-color, currentColor)' }}
            mask={`url(#mask-${image})`}
          />
        </ImgStyle>
      )}
      <TooltipTextStyle>{children}</TooltipTextStyle>
    </TooltipButtonStyle>
  );
};

export const TooltipSettings: ISettings = {
  states: [State.default, State.active, State.hover, State.disabled],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.text,
    CmsConfig.color,
    CmsConfig.altColor,
    CmsConfig.fill,
    // CmsConfig.effects,
    CmsConfig.padding,
    CmsConfig.flexbox,
    CmsConfig.cursor,
  ],
  variants: [
    {
      className: '&primary',
      description: 'Tooltip Primary',
    },
  ],
};

Tooltip.propTypes = TooltipPropTypes;
