import { Series } from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import { useContext } from 'react';
import GlobalContext from '../../../../../context/GlobalContext';
import { GenericWithDocumentID } from '../../../../../types';
import { getCategoriesByKey } from '../../../../../utils/helpers/categories/getCategoriesByKey';
import { getGradientColors } from '../../../../Events/Overview/helpers';

export const settings = (
  series: GenericWithDocumentID<Series>,
  callbacks: any
) => {
  const {
    id,
    accentColor,
    cardImage,
    categories: eventCategoryKeys,
    language: {
      EN: { title = '', descriptionShort: description = '' },
    },
  } = series;
  let categories: any;
  const globalData = useContext(GlobalContext);
  if (eventCategoryKeys && globalData?.categories) {
    categories = getCategoriesByKey(
      eventCategoryKeys,
      globalData?.categories?.categories
    )?.map((category: any) => {
      const {
        shape,
        color,
        language: {
          EN: { label },
        },
      } = category;
      return {
        className: 'primary',
        shape,
        label,
        color,
      };
    });
  }
  const gradientColors = getGradientColors(accentColor, [0.9, 0.3]);
  const cardEventSettings = {
    text: {
      titleType: 'h1',
      title,
      description,
    },
    callbacks,
    categories,
    badgeVariant: '',
    topImage: `${process.env.REACT_APP_STORAGE_URL}/assets/serieslogos/${id}.png`,
    backgroundImage: `${process.env.REACT_APP_STORAGE_URL}${cardImage}`,
    firstGradientColor: gradientColors[0],
    secondGradientColor: gradientColors[1],
    baseUrl: process.env.PUBLIC_URL,
  };
  return cardEventSettings;
};
