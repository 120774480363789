import styled from 'styled-components';

export const StyledButton = styled.button`
  all: initial;
  position: relative;
  left: -2px;
  top: -2px;
  width: 60px;
  height: 60px;
  border: 1px solid transparent;
  border-radius: 30px;

  svg {
    position: relative;
    left: 2px;
    top: 2px;
  }

  .box {
    stroke: var(--stroke);
    fill: var(--fill);
  }

  .arrow {
    stroke: var(--arrow);
  }

  &:focus-visible {
    border-color: #00a1f1;
  }

  &.back {
    --stroke: black;
    --fill: transparent;
    --arrow: white;
  }

  &.back:hover {
    --arrow: #dbdbdc;
  }

  &.back:active {
    --arrow: #bfbfc0;
  }

  &.back:disabled {
    --stroke: #ababab;
    --arrow: #ebebeb;
  }

  &.forward svg {
    transform: rotate(180deg);
  }

  &.forward {
    --stroke: black;
    --fill: black;
    --arrow: white;
  }

  &.forward:hover {
    --arrow: #dbdbdc;
  }

  &.forward:active {
    --stroke: #343536;
    --fill: #343536;
    --arrow: white;
  }

  &.forward:disabled {
    --stroke: #ababab;
    --fill: #ababab;
    --arrow: white;
  }
`;
