import styled from 'styled-components';

/* interface T {
  variant?: Variant;
  type?: string;
  error?: boolean;
}
interface BG {
  disabled?: string;
  default?: string;
  error?: string;
}
interface TC {
  disabled?: string;
  hover?: string;
  over?: string;
  active?: string;
  error?: string;
  default?: string;
}
interface BC {
  over?: string;
  active?: string;
  hover?: string;
  error?: string;
  default?: string;
}
interface NC {
  default?: string;
}
interface Variant {
  backgroundColor: BG;
  textColor: TC;
  borderColor: BC;
  noticeColor: NC;
  autocomplete?: string;
} */

export const TextAreaContainer = styled.div`
  /* @ts-ignore */
`;

export const StyledSection = styled.div`
  /* @ts-ignore */
`;
