import useGetDemStyle from '../styling/useGetDemStyle';
import useGetDemFontStyle from '../styling/useGetDemFontStyle';
import { dexperFirestore } from '../../firebase';
import useGetDocumentOnSnapshot from '../firebase/dexper/useGetDocumentOnSnapshot';

export const useGetGlobalData = () => {
  const [demStyle] = useGetDemStyle();
  const [demFontStyle] = useGetDemFontStyle();

  // Fetch content
  const content365Path = dexperFirestore.content365();
  const [content] = useGetDocumentOnSnapshot(content365Path, true);

  // Fetch config
  const config365Path = dexperFirestore.config365();
  const [config] = useGetDocumentOnSnapshot(config365Path, true);

  // Fetch categories
  const configCategoriesPath = dexperFirestore.clientCategoryConfig();
  const [categories] = useGetDocumentOnSnapshot(configCategoriesPath, true);

  const isFetching =
    !demStyle || !demFontStyle || !config || !content || !categories;

  return {
    isFetching,
    demStyle,
    demFontStyle,
    content,
    config,
    categories,
  };
};
