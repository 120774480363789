/* eslint-disable react/react-in-jsx-scope */
import { Text } from '../../Atoms/Text/Text';
import { DateTime } from '../../Atoms/DateTime/DateTime';
import { Heading } from '../../Atoms/Heading/Heading';
import { Button } from '../../Atoms/Button/Button';
import { Tooltip } from '../../Atoms/Tooltip/Tooltip';
import { RichText } from '../../Atoms/RichText/RichText';
import { StyledSection } from './MeetupCard.css';

const printButtons = (settings: any) => {
  switch (settings.buttonStatus) {
    case 'live':
      return (
        <Button
          className='primary'
          onClick={settings.callbacks.onGoToLive}
          image='/assets/icons/watch-gotolive-white.svg'
        >
          {settings.text.goToLive}
        </Button>
      );
      break;
    case 'signup':
      return (
        <Button
          className='secondary'
          onClick={settings.callbacks.onSignUp}
          image='/assets/icons/add-white.svg'
        >
          {settings.text.signup}
        </Button>
      );
      break;
    case 'full':
      return <Button className='tertiary'>{settings.text.full}</Button>;
      break;
    case 'check':
      return (
        <Button className='quaternary' image='/assets/icons/check.svg'></Button>
      );
      break;
    case 'concluded':
      return (
        <div style={{ textTransform: 'uppercase' }}>
          <Button className='octonary'>{settings.text.concluded}</Button>
        </div>
      );
      break;
    default:
      '';
  }
};

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;
  switch (variation) {
    case 'primary':
      return (
        <>
          <StyledSection className='section-date-time'>
            <DateTime
              className='primary'
              settings={settings.dateTime}
            ></DateTime>
          </StyledSection>
          <StyledSection className='section-title'>
            <Heading className='primary' tag={settings.text.titleType}>
              {settings.text.title}
            </Heading>
          </StyledSection>
          <StyledSection className='section-description'>
            <RichText className='primary'>{settings.text.description}</RichText>
            <Button
              className='septanary'
              onClick={settings.callbacks.onReadMore}
            >
              {settings.text.readMoreButton}
            </Button>
          </StyledSection>
          <StyledSection className='section-space-limited'>
            <Text className='primary'>{settings.text.spaceLimited}</Text>
          </StyledSection>
          <StyledSection className='section-signups'>
            {settings.signups.map((signup: any, index: number) => {
              return (
                <Button
                  key={index}
                  className='quinary'
                  onClick={settings.callbacks.onSignups}
                  background={signup.backgroundImage}
                  alt='signup'
                ></Button>
              );
            })}
            {settings.showSignupPlus ? (
              <Button
                className='senary'
                onClick={settings.callbacks.onSignupsPlus}
              >
                +{settings.text.signupPlus}
              </Button>
            ) : null}
          </StyledSection>

          <StyledSection className='section-buttons'>
            {settings.onConcluded || settings.onLinkWillFollow ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text className='secondary'>
                  {settings.onConcluded
                    ? settings.text.concluded
                    : settings.text.onLinkWillFollow}
                </Text>
                {settings.showTooltip && (
                  <Tooltip
                    className='primary'
                    image={settings.infoIcon}
                    alt='Info'
                  >
                    <div className='section-tooltip'>
                      {settings.text.tooltip}
                    </div>
                  </Tooltip>
                )}
              </div>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>{printButtons(settings)}</div>
          </StyledSection>
        </>
      );
      break;
    default:
      return <p>no card variation selected</p>;
  }
};
