import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { RichTextContainer } from './RichText.css';
import classNames from 'classnames';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import * as H from 'history';
import ReactMarkdown from 'react-markdown';

const TextPropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export const RichTextStory = {
  className: 'primary',
  children:
    '## Lorem ipsum dolor sit amet Consectetur adipiscing elit.\n\
  **Donec et neque pulvinar**, \
  cursus urna non, _fermentum massa. Nam vitae justo et ipsum \
  pulvinar pellentesque nec sollicitudin lectus_. Pellentesque habitant \
  morbi tristique senectus et netus et malesuada fames ac turpis egestas. \
  Etiam nec augue nec nulla iaculis aliquam. [Phasellus](https://dexper.io) \
  lacus justo, vulputate tempor mattis vel, blandit vitae neque.\
  \n\
  * list item one\n\
  * list item two\n\
  \n\
  ',
};

export type RichTextType = InferProps<typeof TextPropTypes>;

export const RichText: React.FC<RichTextType> = ({ className, children }) => {
  const history = useHistory();

  //console.log('children', children);

  return (
    <RichTextContainer
      className={classNames('RichText', className)}
      onClick={(event) => {
        handleClick(history, event as unknown as Event);
      }}
    >
      <ReactMarkdown>{children}</ReactMarkdown>
    </RichTextContainer>
  );
};

const allCategories = [
  CmsConfig.brandText,
  CmsConfig.color,
  CmsConfig.linkStyle,
  CmsConfig.vmargin,
  CmsConfig.hmargin,
  CmsConfig.flexbox,
  CmsConfig.transform,
];

export const RichTextSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'Formatted text',
    },
  ],
};

RichText.propTypes = TextPropTypes;

function handleClick(history: H.History, event: Event) {
  const link = (event.target as HTMLElement).getAttribute('href') as string;
  if (link) {
    if (isLinkExternal(link)) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
    event.preventDefault();
  }
}

function isLinkExternal(url: string) {
  return /^https?:\/\//.test(url);
}
