import styled from 'styled-components';

export const FaqContainer = styled.div`
  /* @ts-ignore */
`;

export const StyledText = styled.div`
  /* @ts-ignore */
`;

export const StyledSection = styled.div`
  /* @ts-ignore */
`;

export const HeadingWrapper = styled.div`
  cursor: pointer;
`;

/* This will need some refactoring */
export const TextWrapper = styled.div`
  padding-top: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.25s ease-in;

  &.fold-out-enter {
    padding-top: 0;
    max-height: 0;
  }

  &.fold-out-enter-active {
    padding-top: 1.5rem;
    max-height: 999px;
  }

  &.fold-out-enter-done {
    padding-top: 1.5rem;
    max-height: 999px;
  }

  &.fold-out-exit {
    padding-top: 1.5rem;
    max-height: 999px;
  }

  &.fold-out-exit-active {
    padding-top: 0;
    max-height: 0;
  }

  &.fold-out-exit-done {
    padding-top: 0;
    max-height: 0;
  }

  & a {
    color: inherit;
    text-decoration: underline;
  }
`;

export interface ToggleWrapper {
  toggle?: boolean;
}

export const ToggleWrapper = styled.div<ToggleWrapper>`
  transform: ${({ toggle }) => (toggle ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: ${({ toggle }) => (toggle ? 'rotate 0.5s ease;' : '')};
`;
