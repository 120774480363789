import PropTypes from 'prop-types';

export const ShapePropTypes = PropTypes.oneOf([
  'square',
  'triangle-up',
  'circle',
]);

export const ShapeTextPropTypes = PropTypes.shape({
  shape: ShapePropTypes,
  color: PropTypes.string,
  label: PropTypes.string,
});

export const SignedUpPropTypes = PropTypes.oneOf([
  'none',
  'sign_up',
  'signed_up',
  'watch',
]);

export const LinkPropTypes = PropTypes.oneOf(['none', 'coming', 'live']);

export const SignupsPropTypes = PropTypes.shape({
  displayName: PropTypes.string,
  profilePicture: PropTypes.string,
  uid: PropTypes.string,
});

export const SocialPropTypes = PropTypes.shape({
  socialName: PropTypes.string,
  socialLogo: PropTypes.string,
  socialURL: PropTypes.string,
  onClickSocial: PropTypes.func,
});

export const createdCMSPropTypes = PropTypes.shape({
  displayName: PropTypes.string,
  profilePicture: PropTypes.string,
  jobTitle: PropTypes.string,
  companyName: PropTypes.string,
  id: PropTypes.string,
});

export const categoriesPropTypes = PropTypes.shape({
  className: PropTypes.string,
  shape: PropTypes.string,
  children: PropTypes.string,
});
