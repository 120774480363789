import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Navigation365Container } from './Navigation365.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';

import { Link, LinkSettings } from '../../Atoms/Link/Link';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';

/* 
Implementation example:
=======================

import {Navigation365Story} from '@dexper.events/product-atomic-ui';

const {settings : navigation365Settings} = Navigation365Story;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <Navigation365 settings={navigation365Settings} className="primary"></$> 
     </div>
 </div> 

*/

export const navigation365Settings = {
  text: {},
  links: [
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Home',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Events',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'On Demand',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Resources',
      location: '#',
      children: null,
    },
  ],
  logo: {
    content: {
      label: 'Remote',
    },
    location: '/assets/icons/remote-logo.svg',
    className: 'primary',
  },
  callbacks: {},
  // more props here:
};

export const Navigation365Story = {
  settings: navigation365Settings,
  className: 'primary',
};

const Navigation365PropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({}).isRequired,
    logo: PropTypes.shape({
      content: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
      location: PropTypes.string.isRequired,
      viewbox: PropTypes.string,
      imageWidth: PropTypes.string,
      imageHeight: PropTypes.string,
      className: PropTypes.string,
    }).isRequired,
    logoLink: PropTypes.shape({
      location: PropTypes.string.isRequired,
      anchorType: PropTypes.string,
      targetBlank: PropTypes.bool,
      className: PropTypes.string.isRequired,
      callback: PropTypes.func,
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        anchorType: PropTypes.string,
        targetBlank: PropTypes.bool,
        callback: PropTypes.func,
        label: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        children: PropTypes.string,
      }),
    ).isRequired,
  }),
};

export type Navigation365Types = InferProps<typeof Navigation365PropTypes>;

export const Navigation365: React.FC<Navigation365Types> = ({
  className,
  settings,
}) => {
  return (
    <Navigation365Container className={classNames('Navigation365', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </Navigation365Container>
  );
};

const atoms = [
  {
    atom: Link,
    name: 'Link~primary',
    description: 'Link navbar',
    settings: LinkSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon logo',
    settings: IconSettings,
    atomVariant: '&primary',
  },
];
//more atoms here:

export const Navigation365Settings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-logo',
      description: 'Section logo',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-links',
      description: 'Section links',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'Navigation365 primary',
    },
  ],
};

Navigation365.propTypes = Navigation365PropTypes;
