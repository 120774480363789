import styled from 'styled-components';

export const RowContainer = styled.section`
  --page-padding: 16px;
  padding: 0 var(--page-padding);

  /* Tablet */
  @media screen and (min-width: 768px) {
    --page-padding: 88px;
  }
  /* Desktop */
  @media screen and (min-width: 1200px) {
    --page-padding: 192px;
  }
`;
