import styled from 'styled-components';

interface IHeaderPlaceholder {
  hasButton?: boolean;
}

export const HeadingContainer = styled.div``;

export const Content = styled.div<IHeaderPlaceholder>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .description {
    flex: 0 1 943px;
    margin: 0 20px 20px 0;
  }

  .Button.header-button {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`;
