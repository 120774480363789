import React, { useState, useRef, useEffect } from 'react';
import { Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useResizeObserver } from '../../utils/hooks/dom/useResizeObserver';
import {
  StyledButtonBack,
  StyledButtonNext,
  StyledCarouselProvider,
} from './styles';

const Carousel = ({ slides, config }: any) => {
  const [visibleSlides, setVisibleSlides] = useState(4);
  const [showButtons, setShowButtons] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    setShowButtons(!config.hideButtons);
  }, []);

  useEffect(() => {
    const nrOfSlides = (slides?.props?.children ?? []).length;
    let visibleSlides = 1;
    switch (true) {
      case width < 640:
        visibleSlides = 1;
        break;
      case width >= 640 && width < 1024:
        visibleSlides = 2;
        break;
      case width >= 1024 && width < 1600:
        visibleSlides = 3;
        break;
      case width >= 1600:
        visibleSlides = 4;
        break;
    }
    setVisibleSlides(visibleSlides);
    setShowButtons(nrOfSlides > visibleSlides);
  }, [width, setShowButtons]);

  const settings = {
    hasMasterSpinner: false,
    naturalSlideWidth: config.naturalSlideWidth ?? 300,
    naturalSlideHeight: config.naturalSlideHeight ?? 567,
    totalSlides: slides?.props?.children?.length ?? 0,
    visibleSlides: config.visibleSlides ?? visibleSlides,
    infinite: true,
    isIntrinsicHeight: true,
    lockOnWindowScroll: false,
    dragEnabled: true,
  };

  return (
    <div ref={ref}>
      <StyledCarouselProvider {...settings}>
        {showButtons && (
          <>
            <StyledButtonBack offset={config.buttonOffset}>
              &nbsp;
            </StyledButtonBack>
            <StyledButtonNext offset={config.buttonOffset}>
              &nbsp;
            </StyledButtonNext>
          </>
        )}
        <Slider>{slides}</Slider>
      </StyledCarouselProvider>
    </div>
  );
};

Carousel.defaultProps = {
  config: {},
};

export default Carousel;
