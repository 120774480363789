import { State } from '../definitions/States';
import { CmsConfig } from '../definitions/CmsConfig';
import { Heading, HeadingSettings } from '../stories/Atoms/Heading/Heading';
import { Text, TextSettings } from '../stories/Atoms/Text/Text';

const headingAtoms = [
  {
    atom: Heading,
    description: 'Heading text',
    settings: HeadingSettings,
    atomVariant: '&h1',
  },
  {
    atom: Text,
    description: 'Text text',
    settings: TextSettings,
    atomVariant: '&medium',
  },
];

export const PageSettings = [
  {
    name: 'Pages',
    className: 'Page',
    settings: {
      states: [State.default],
      variants: [
        {
          className: '&Schedule',
          description: 'Schedule page',
          include: [],
          atoms: headingAtoms,
          sections: [
            {
              className: 'Header',
              description: 'Section Header top',
              include: [CmsConfig.fill],
            },
          ],
        },
        {
          className: '&Meetups',
          description: 'Meetups page',
          include: [],
          atoms: headingAtoms,
          sections: [
            {
              className: 'Header',
              description: 'Section Header top',
              include: [CmsConfig.fill],
            },
          ],
        },
        {
          className: '&HashicorpZone',
          description: 'HashicorpZone page',
          include: [],
          atoms: headingAtoms,
          sections: [
            {
              className: 'Header',
              description: 'Section Header top',
              include: [CmsConfig.fill],
            },
            {
              className: 'carousel-featured',
              description: 'Section carousel featured',
              include: [CmsConfig.fill],
            },
            {
              className: 'carousel-demo',
              description: 'Section carousel demo',
              include: [CmsConfig.fill],
            },
            {
              className: 'carousel-ask-an-expert',
              description: 'Section carousel ask an expert',
              include: [CmsConfig.fill],
            },
            {
              className: 'carousel-meetup',
              description: 'Section carousel meetup',
              include: [CmsConfig.fill],
            },
          ],
        },
        {
          className: '&Swag',
          description: 'Swag page',
          include: [],
          atoms: headingAtoms,
          sections: [
            {
              className: 'Header',
              description: 'Section Header top',
              include: [CmsConfig.fill],
            },
          ],
        },
        {
          className: '&Hug',
          description: 'Hug page',
          include: [],
          atoms: headingAtoms,
          sections: [
            {
              className: 'Header',
              description: 'Section Header top',
              include: [CmsConfig.fill],
            },
          ],
        },
      ],
    },
  },
];
