import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import classNames from 'classnames';
import { PrintVariations } from './variations';

export const inputFieldAtomSettings = {
  className: 'primary',
  required: true,
  disabled: false,
  error: false,
  value: 'bla',
  type: '',
  placeholder: 'First name',
  name: 'firstName',
  autocomplete: '',
  id: 'id',
  customRef: null,
  onBlur: (...args: any[]) => {
    console.log('onBlur fired', args);
  },
  onChange: (...args: any[]) => {
    console.log('onChange fired', args);
  },
};

export const InputFieldAtomStory = {
  className: inputFieldAtomSettings.className,
  required: inputFieldAtomSettings.required,
  disabled: inputFieldAtomSettings.disabled,
  error: inputFieldAtomSettings.error,
  value: inputFieldAtomSettings.value,
  type: inputFieldAtomSettings.value,
  placeholder: inputFieldAtomSettings.placeholder,
  name: inputFieldAtomSettings.name,
  //variant: inputFieldAtomSettings.variant,
  autocomplete: inputFieldAtomSettings.autocomplete,
  id: inputFieldAtomSettings.id,
  customRef: inputFieldAtomSettings.customRef,
  onBlur: inputFieldAtomSettings.onBlur,
  onChange: inputFieldAtomSettings.onChange,
};

const InputFieldAtomPropTypes = {
  className: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  //variant: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
  id: PropTypes.string,
  customRef: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export type InputFieldAtomTypes = InferProps<typeof InputFieldAtomPropTypes>;

export const InputFieldAtom: React.FC<InputFieldAtomTypes> = ({
  className,
  disabled,
  error,
  value,
  type,
  placeholder,
  name,
  autocomplete,
  id,
  customRef,
  onBlur,
  onChange,
}) => {
  return (
    <PrintVariations
      className={classNames('InputFieldAtom', className)}
      // variation={className}
      id={id}
      customRef={customRef}
      name={name}
      disabled={disabled}
      type={type}
      defaultValue={value}
      error={error}
      placeholder={placeholder}
      autoComplete={autocomplete}
      onBlur={onBlur}
      onChange={onChange}
    ></PrintVariations>
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.text,
  CmsConfig.placeholder,
];

export const InputFieldAtomSettings: ISettings = {
  states: [State.default, State.active, State.disabled, State.focus],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'InputFieldAtom Primary',
    },
    {
      className: '&secondary',
      description: 'InputFieldAtom secondary',
    },
    {
      className: '&tertiary',
      description: 'InputFieldAtom tertiary',
    },
    {
      className: '&error',
      description: 'InputFieldAtom error',
    },
  ],
};

/* InputFieldAtom.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  autocomplete: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'bordered']),
  className: PropTypes.string,
};

InputFieldAtom.defaultProps = {
  disabled: false,
  error: false,
  value: '',
  type: 'text',
  placeholder: '',
  required: false,
  name: '',
  variant: 'default',
  autocomplete: '',
  className: 'primary',
}; */
