import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import { ISettings } from '../../../definitions/ISettings';
import { SvgContainer } from './Icon.css';

const IconPropTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  viewbox: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,

  location: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const IconStory = {
  content: {
    label: 'linkedin',
  },
  location: '/assets/icons/linkedin-white.svg',
  className: 'primary',
};

export type IconTypes = InferProps<typeof IconPropTypes>;

export const Icon: React.FC<IconTypes> = ({
  className,
  content: { label },
  location,
  viewbox,
  imageWidth,
  imageHeight,
}) => (
  <SvgContainer
    className={classNames('Icon', className)}
    viewBox={viewbox ? viewbox : '0 0 25 25'}
    aria-label={label}
  >
    <defs>
      <mask id={location}>
        <image
          xlinkHref={location}
          width={imageWidth ? imageWidth : '25'}
          height={imageHeight ? imageHeight : '25'}
        />
      </mask>
    </defs>
    <rect
      width='25'
      height='25'
      style={{ fill: 'currentColor' }}
      mask={`url(#${location})`}
    />
  </SvgContainer>
);

export const IconSettings: ISettings = {
  states: [State.default, State.hover, State.active],
  include: [
    CmsConfig.color,
    CmsConfig.fill,
    CmsConfig.border,
    CmsConfig.padding,
    CmsConfig.hmargin,
    CmsConfig.vmargin,
    CmsConfig.frame,
  ],
  variants: [
    {
      className: '&primary',
      description: 'Icon primary',
    },
    {
      className: '&secondary',
      description: 'Icon secondary',
    },
    {
      className: '&ternary',
      description: 'Icon ternary',
    },
    {
      className: '&quaternary',
      description: 'Icon quaternary',
    },
    {
      className: '&custom',
      description: 'Icon custom',
    },
    {
      className: '&social',
      description: 'Icon social',
    },
    {
      className: '&loading',
      description: 'Icon loading',
    },
  ],
};

Icon.propTypes = IconPropTypes;
