/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { StyledContainer } from './styles';
import { HubspotFormProps } from './types';

/*
  We have to polyfill window.jQuery in the document referencing the HubSpot library.
*/
(function () {
  // @ts-ignore
  window.jQuery =
    // @ts-ignore
    window.jQuery ||
    // @ts-ignore
    function (nodeOrSelector) {
      if (typeof nodeOrSelector == 'string') {
        // @ts-ignore
        return document.querySelector(s);
      }
      return nodeOrSelector;
    };
})();

const HubspotForm = ({
  region,
  portalId,
  formId,
  target,
}: HubspotFormProps) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target,
          onFormReady: () => setIsLoading(false),
        });
      }
    });
  }, []);

  return (
    <StyledContainer id="hubspot-form">
      <ClipLoader color="#999" loading={isLoading} size={50} />
    </StyledContainer>
  );
};

export default HubspotForm;
