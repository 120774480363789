import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { StyledButton } from './Arrow.css';
import classNames from 'classnames';

const ArrowPropTypes = {
  variant: PropTypes.oneOf(['back', 'forward']).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export type ArrowType = InferProps<typeof ArrowPropTypes>;

export const Arrow: React.FC<ArrowType> = ({
  variant,
  disabled,
  className,
  onClick,
}) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <StyledButton
    className={classNames('Arrow', className, variant)}
    disabled={disabled || false}
    onClick={onClick}
  >
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label={variant}
    >
      <rect x='0.5' y='0.5' width='55' height='55' rx='27.5' className='box' />
      <path
        d='M36 28H20'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='arrow'
      />
      <path
        d='M26 22L20 28L26 34'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='arrow'
      />
    </svg>
  </StyledButton>
);

Arrow.propTypes = ArrowPropTypes;
