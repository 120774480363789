export const settings = (
  callback: (selection: string) => void,
  currentSelection: string
) => {
  const navigation = [
    {
      label: 'All',
      callback: () => callback('all'),
      anchorType: 'internal',
      location: '#',
      active: currentSelection === 'all',
    },
    {
      label: 'Live',
      callback: () => callback('live'),
      anchorType: 'internal',
      location: '#',
      active: currentSelection === 'live',
    },
    {
      label: 'Upcoming',
      callback: () => callback('upcoming'),
      anchorType: 'internal',
      location: '#',
      active: currentSelection === 'upcoming',
    },
    {
      label: 'Concluded',
      callback: () => callback('concluded'),
      anchorType: 'internal',
      location: '#',
      active: currentSelection === 'concluded',
    },
  ];
  return { navigation };
};
