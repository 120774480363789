/*eslint-disable */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { VerticalBar, DateWrapper, StyledTextWrapper } from './DateTime.css';
import { getSuffixedDay } from './utils';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { Text, TextSettings } from '../Text/Text';
import { State } from '../../../definitions/States';

export const DateTimeStory = {
  className: 'primary',
  settings: {
    locale: 'en-US',
    timeStartExpected: 1634540400000,
    timeEndExpected: 1634543400000,
    hour12: true,
    showDate: true,
    showTime: true,
    showTimeZone: true,
    showYear: false,
    longMonth: false,
    useSuffixedDay: false,
  },
};

const DateTimePropTypes = {
  className: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    locale: PropTypes.string,
    timeStartExpected: PropTypes.number.isRequired,
    timeEndExpected: PropTypes.number,
    hour12: PropTypes.bool,
    showDate: PropTypes.bool,
    showTime: PropTypes.bool,
    showTimeZone: PropTypes.bool,
    showYear: PropTypes.bool,
    longMonth: PropTypes.bool,
    useSuffixedDay: PropTypes.bool,
  }),
};

export const getTimeZone = () =>
  ` ${
    new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      timeZoneName: 'short',
    }).formatToParts(new Date())[2]?.value
  }`;

export type DateTimeTypes = InferProps<typeof DateTimePropTypes>;

export const DateTime: React.FC<DateTimeTypes> = ({ className, settings }) => {
  const timeLocale = settings?.hour12 ? settings?.locale : undefined;
  const timeConfig = {
    hour: 'numeric',
    minute: 'numeric',
  } as const;

  return settings?.locale && settings.timeStartExpected ? (
    <div className={classNames('DateTime', className)}>
      <div>
        <DateWrapper>
          {settings.showDate && (
            <>
              <StyledTextWrapper>
                <div>
                  {new Intl.DateTimeFormat(
                    settings.locale,
                    settings.showYear
                      ? {
                          month: settings.longMonth ? 'long' : 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      : {
                          month: settings.longMonth ? 'long' : 'short',
                          day: 'numeric',
                        },
                  ).format(settings.timeStartExpected)}
                </div>
              </StyledTextWrapper>
              {settings.useSuffixedDay &&
                getSuffixedDay(settings.locale, settings.timeStartExpected)}
              {settings.showTime && (
                <div style={{ color: 'var(--alt-color, currentColor)' }}>
                  &nbsp;|&nbsp;
                </div>
              )}
            </>
          )}
          {settings.showTime && (
            <>
              {settings.timeEndExpected
                ? // @ts-ignore
                  `${new Intl.DateTimeFormat(timeLocale, timeConfig).format(
                    settings.timeStartExpected,
                    // @ts-ignore
                  )} - ${new Intl.DateTimeFormat(timeLocale, timeConfig).format(
                    settings.timeEndExpected,
                  )}${settings.showTimeZone ? getTimeZone() : ''}`
                : // @ts-ignore
                  new Intl.DateTimeFormat(timeLocale, timeConfig).format(
                    settings.timeStartExpected,
                  )}
            </>
          )}
        </DateWrapper>
      </div>
    </div>
  ) : (
    <div />
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.hmargin,
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.altColor,
  CmsConfig.text,
];

export const DateTimeSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'DateTime primary',
    },
    {
      className: '&secondary',
      description: 'DateTime secondary',
    },
  ],
};

DateTime.propTypes = DateTimePropTypes;

DateTime.defaultProps = {
  settings: {
    locale: 'en-EN',
    timeStartExpected: 1634540400000,
    timeEndExpected: 1634543400000,
    showDate: true,
    showTime: true,
    showTimeZone: false,
    showYear: false,
    longMonth: false,
    useSuffixedDay: false,
    hour12: true,
  },
};
