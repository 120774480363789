import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { CardContainer } from './ResourceCard.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { Heading } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';

export const ResourceCardStorySettings = {
  text: {
    heading:
      'Episode 12 with Ellen Pao and McKensie Mack - Creating People-First Remote Workplaces',
    text: 'Innovation, R&D, and investments to achieve seamless global connectivity are all fueled by a balanced framework for standardization.',
    buttonAlt: 'Download',
  },
  callbacks: {
    onClick: (...args: any[]) => {
      console.log('Click!', args);
    },
  },
  icon: 'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Ficons%2Fdownload.svg?alt=media&token=8ed8afe4-0d75-402d-ab6c-58a739c974e0',
  background:
    'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Fimages%2Fcard-background.png?alt=media&token=c6f0914d-f221-4b45-a755-4f0e89d88e3e',
};

const ResourceCardPropTypes = {
  settings: PropTypes.shape({
    text: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      buttonAlt: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    icon: PropTypes.string,
    background: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};

export const ResourceCardStory = {
  settings: ResourceCardStorySettings,
  className: 'primary',
};

export type ResourceCardTypes = InferProps<typeof ResourceCardPropTypes>;

export const ResourceCard: React.FC<ResourceCardTypes> = ({
  className,
  settings,
}) => {
  return settings ? (
    <CardContainer
      className={classNames('ResourceCard', className)}
      background={settings.background}
      onClick={(event) => settings.callbacks.onClick(event, settings)}
    >
      <div>
        <div className='section-text'>
          <Button
            className='primary'
            onClick={(event) => settings.callbacks.onClick(event, settings)}
            image={settings.icon}
            alt={settings.text.buttonAlt}
          />
          <Heading tag='h3' className='primary'>
            {settings.text.heading}
          </Heading>
        </div>
        <div className='section-hover'>
          <Text className='primary'>{settings.text.text}</Text>
        </div>
      </div>
    </CardContainer>
  ) : null;
};

const atoms = [
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Icon Button',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text',
    settings: TextSettings,
    atomVariant: '&primary',
  },
];

export const ResourceCardSettings: ISettings = {
  states: [State.default, State.hover],
  include: [
    CmsConfig.flexbox,
    CmsConfig.vmargin,
    CmsConfig.frame,
    CmsConfig.padding,
    CmsConfig.fill,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-text',
      description: 'Section contents',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-hover',
      description: 'Section hover',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'ResourceCard Primary',
    },
  ],
};

ResourceCard.propTypes = ResourceCardPropTypes;
