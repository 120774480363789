import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FooterContainer } from './Footer.css';
import classNames from 'classnames';

const FooterPropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export type FooterType = InferProps<typeof FooterPropTypes>;

export const Footer: React.FC<FooterType> = ({ className, children }) => (
  <FooterContainer className={classNames('Row', className)}>
    {children}
  </FooterContainer>
);

Footer.propTypes = FooterPropTypes;
