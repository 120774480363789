import React, { useState } from 'react';
import { LiveDot } from './Badge.css';
export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { children } = args;

  switch (variation) {
    case 'primary':
      return <>{children}</>;
      break;
    case 'secondary':
      return <>{children}</>;
      break;
    case 'tertiary':
      return (
        <>
          <LiveDot></LiveDot>
          {children}
        </>
      );
      break;
    default:
      return <p>No variation selected</p>;
  }
};
