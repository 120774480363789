import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { PageContainer } from './Page.css';
import classNames from 'classnames';

const PagePropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export type PageType = InferProps<typeof PagePropTypes>;

export const Page: React.FC<PageType> = ({ className, children }) => (
  <PageContainer className={classNames('Page', className)}>
    {children}
  </PageContainer>
);

Page.propTypes = PagePropTypes;
