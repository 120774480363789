import styled from 'styled-components';

export const SvgContainer = styled.svg`
  /*  width: 50px;
      height: 50px;
      background: darkblue;
      color: yellow;
      border-radius: 4px;
      padding: 5px;*/
`;
