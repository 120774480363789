import styled from 'styled-components';

export const CardSpeakerContainer = styled.div``;

export const StyledSection = styled.div`
  /* @ts-ignore */
`;

export const StyledSectionCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px 0px;
`;

/* interface A {
  img?: string;
}
export const SpeakerImg = styled.div<A>`
  width: 144px;
  height: 144px;
  background: ${({ img }) => `no-repeat center/cover url(${img})`};
`;
 */
