import { css } from 'styled-components';

interface ShapeProps {
  color: string;
}

export default css<ShapeProps>`
  margin-top: 7px;
  width: 0;
  height: 0;
  margin-right: 3px;
  background-color: transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: 10px solid ${({ color }) => color};
  display: block;
`;
