import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckboxWrapper } from './CheckBox.css';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import {
  CheckboxAtom,
  CheckboxAtomSettings,
} from '../../Atoms/CheckboxAtom/CheckboxAtom';
import { State } from '../../../definitions/States';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import classNames from 'classnames';

export interface CheckBoxType {
  className?: string;
  label?: string;
  id: string;
}

export const CheckBoxStory = {
  className: 'primary',
  label: 'I am a label of Checkbox',
  id: 'id',
};

export const CheckBox: React.FC<CheckBoxType> = ({ className, label, id }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxWrapper className={classNames('CheckBox', className)}>
      <CheckboxAtom
        className='primary'
        id={id}
        checked={isChecked}
        onChange={handleChange}
      />

      <Text htmlFor={id} className='primary'>
        {label}
      </Text>
    </CheckboxWrapper>
  );
};
const allCategories = [
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.border,
  CmsConfig.fill,
];

const atoms = [
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text Primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: CheckboxAtom,
    name: 'CheckboxAtom~primary',
    description: 'CheckboxAtom Primary',
    settings: CheckboxAtomSettings,
    atomVariant: '&primary',
  },
];

export const CheckBoxSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  variants: [
    {
      className: '&primary',
      description: 'CheckBox primary',
    },
  ],
};

export const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

CheckBox.propTypes = propTypes;

CheckBox.defaultProps = {
  className: '',
  label: '',
};

export default CheckBox;
