import { Heading } from '@dexper.events/product-atomic-ui';
import { orderBy } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Head from '../../components/Head';
import { currentLanguage } from '../../config/language.config';
import GlobalContext from '../../context/GlobalContext';
import { dexperFirestore } from '../../utils/firebase';
import { useAnalytics } from '../../utils/hooks/analytics/useAnalytics';
import useOnClickPing from '../../utils/hooks/analytics/useOnClickPing';
import useGetCollectionOnSnapshot from '../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { Dex365HeaderContainer } from '../containers/Dex365Header';
import * as SC from './styles';
import { getGradientColors } from '../Events/Overview/helpers';

function ResourcesPage() {
  useAnalytics();
  const OnClickPing = (data: any) => useOnClickPing(data) as any;
  const intl = useIntl();

  const [headerData, setHeaderData] = useState<any>(null);
  const [headerSettings, setHeaderSettings] = useState<any>(null);
  const [adaptedData, setAdaptedData] = useState<any>(null);
  const [adaptedResourceTypes, setAdaptedResourceTypes] = useState<any>(null);

  const openDownload = (clickedDownload: any, id: string) => {
    const { target, internal } = clickedDownload;
    let urlToOpen = target;
    if (internal) {
      urlToOpen = `${process.env.REACT_APP_STORAGE_URL}${target}`;
    }
    if (urlToOpen) {
      const analyticsRequest = {
        ping: {
          userAgentString: navigator?.userAgent,
          page: window?.location?.pathname,
          event: 'dex365',
        },
        visitID: sessionStorage?.visitID,
        target: `${process.env.REACT_APP_STORAGE_URL}${urlToOpen}`,
        targetID: id,
        source: 'on download resource',
      };

      OnClickPing(analyticsRequest);
      window.open(urlToOpen, '_blank');
    }
  };

  const [data] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Resources(),
    true,
    [orderBy('order', 'asc')]
  );
  const globalData = useContext(GlobalContext);
  const {
    // TODO: Fix in datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config: { resourceTypes },
    // TODO: Fix in datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    content: { pageHeaders },
  } = globalData;

  useEffect(() => {
    if (!data || !adaptedData) {
      return;
    }
    const pageHeader = pageHeaders?.resources;
    const pageHeaderData = data.find((resource: any) => {
      return resource.id === pageHeader.resourceID;
    });

    const { label: buttonLabel } = pageHeader?.language[currentLanguage] || {};
    const { title, description } =
      // TODO: Fix in datamodel
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pageHeaderData?.language[currentLanguage] || {};
    // TODO: Fix in datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { accentColor, categories } = pageHeaderData;

    const headerData = {
      id: pageHeader.resourceID,
      headerImage: pageHeader.image,
      titleType: 'h1',
      language: {
        EN: {
          title,
          descriptionShort: description,
        },
      },
      accentColor,
      categories,
    };

    setHeaderData(headerData);

    const headerSettings = {
      showButtonLabel: true,
      buttonLabel,
      callbacks: {
        onSubmit: () => {
          const clickedDownload = adaptedData.find((resource: any) => {
            return resource.id === pageHeader.resourceID;
          });
          if (clickedDownload) {
            openDownload(clickedDownload, pageHeader.resourceID);
          }
        },
      },
    };

    setHeaderSettings(headerSettings);
  }, [data, adaptedData]);

  useEffect(() => {
    if (data) {
      const adaptedData = data.map((resource: any) => {
        const { language, cardImage } = resource;
        const translations = language[currentLanguage];
        const { title, description } = translations;
        return {
          ...resource,
          cardImage: `${process.env.REACT_APP_STORAGE_URL}${cardImage}`,
          title,
          description,
        };
      });
      setAdaptedData(adaptedData);
    }
  }, [data]);

  useEffect(() => {
    if (resourceTypes) {
      const adaptedResourceTypes = Object.entries(resourceTypes).map(
        ([key, value]) => {
          const { language } = value as any;
          const translations = language[currentLanguage];
          const { label } = translations;
          return {
            resourceType: key,
            label,
          };
        }
      );
      setAdaptedResourceTypes(
        adaptedResourceTypes.sort((a, b) =>
          a.resourceType
            .toLowerCase()
            .localeCompare(b.resourceType.toLowerCase())
        )
      );
    }
  }, [resourceTypes]);

  const adaptResource = (resource: any) => {
    const { title, description, cardImage, internal, accentColor } = resource;
    const gradientColors = getGradientColors(accentColor, [0.9, 0.3]);
    const resourceCardStorySettings = {
      text: {
        heading: title,
        text: description,
        buttonAlt: 'Download',
      },
      callbacks: {
        onClick: (e: any, data: any) => {
          const clickedDownload = adaptedData.find((resource: any) => {
            return (
              resource.title === data.text?.heading &&
              resource.description === data.text?.text
            );
          });
          if (clickedDownload) {
            openDownload(clickedDownload, resource.id);
          }
        },
      },
      icon: `${process.env.PUBLIC_URL}/assets/icons/download-${
        internal ? 'internal' : 'external'
      }.svg`,
      background: cardImage,
      firstGradientColor: gradientColors?.[0],
      secondGradientColor: gradientColors?.[1],
    };
    return resourceCardStorySettings;
  };

  const getResource = (resource: any) => {
    const { resourceType } = resource;
    return adaptedData?.filter(
      (resource: any) => resource.resourceType === resourceType
    );
  };

  return (
    <SC.StyledContainer>
      <Head title={intl.formatMessage({ id: 'analytics.resources' })} />
      <main className="Dex365EventPage">
        <section className="section-row1">
          <Dex365HeaderContainer
            className="primary"
            data={headerData}
            appSettings={headerSettings}
          />
        </section>
        {adaptedResourceTypes?.map((resourceType: any) => (
          <>
            {getResource(resourceType) && getResource(resourceType).length > 0 && (
              <SC.ResourceContainer key={resourceType.label}>
                <SC.ResponsiveRow>
                  <Heading tag="h3" className="channels-heading">
                    {resourceType.label}
                  </Heading>
                </SC.ResponsiveRow>
                <SC.CustomStyledGrid columns={3} className="section-top">
                  {getResource(resourceType)?.map((resource: any) => (
                    <SC.StyledResourceCard
                      key={resource.id}
                      className="primary"
                      settings={adaptResource(resource)}
                    />
                  ))}
                </SC.CustomStyledGrid>
              </SC.ResourceContainer>
            )}
          </>
        ))}
      </main>
    </SC.StyledContainer>
  );
}

export default ResourcesPage;
