import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { InputContainer } from './InputField.css';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import {
  ErrorMessage,
  ErrorMessageSettings,
} from '../../Atoms/ErrorMessage/ErrorMessage';
import {
  InputFieldAtom,
  InputFieldAtomSettings,
} from '../../Atoms/InputFieldAtom/InputFieldAtom';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { State } from '../../../definitions/States';
import classNames from 'classnames';
import { PrintVariations } from './variations';

/* 
Implementation example :
className : primary -> normal input
className : error -> user error input

<div className="AnotherPageTest">
    <div className="section-bottom">
      <InputField settings={inputFieldSettings} className="primary"></InputField>
    </div>
</div>

*/

export const inputFieldSettings = {
  text: {
    errorMsg: 'No curse words are allowed!',
    placeholder: 'A simple placeholder',
    label: 'Just a default label',
    required: 'Required',
  },
  required: true,
  disabled: false,
  error: false,
  type: 'text',
  name: '',
  variant: 'default',
  autocomplete: '',
  className: 'primary',
  id: 'id-1',
  value: '',
  categoryIcon: '/assets/icons/github.svg',
  customRef: null,
  callbacks: {
    onBlur: (...args: any[]) => {
      console.log('onBlur fired', args);
    },
    onChange: (...args: any[]) => {
      console.log('onChange fired', args);
    },
  },
};

export const InputFieldStory = {
  className: 'primary',
  settings: inputFieldSettings,
};

const InputFieldPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      errorMsg: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      label: PropTypes.string,
      required: PropTypes.string,
    }).isRequired,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    autocomplete: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    categoryIcon: PropTypes.string,
    customRef: PropTypes.string,

    callbacks: PropTypes.shape({
      onBlur: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

export type InputFieldTypes = InferProps<typeof InputFieldPropTypes>;

export const InputField: React.FC<InputFieldTypes> = ({
  className,
  settings,
}) => {
  return (
    <InputContainer className={classNames('InputField', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </InputContainer>
  );
};

const atoms = [
  {
    atom: ErrorMessage,
    name: 'ErrorMessage~primary',
    description: 'ErrorMessage primary',
    settings: ErrorMessageSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text secondary',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
  {
    atom: InputFieldAtom,
    name: 'InputFieldAtom~primary',
    description: 'InputFieldAtom primary',
    settings: InputFieldAtomSettings,
    atomVariant: '&primary',
  },
  {
    atom: InputFieldAtom,
    name: 'InputFieldAtom~error',
    description: 'InputFieldAtom error',
    settings: InputFieldAtomSettings,
    atomVariant: '&error',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon socials',
    settings: IconSettings,
    atomVariant: '&primary',
  },
];

const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.hmargin,
  CmsConfig.vmargin,
  CmsConfig.flexbox,
];

export const InputFieldSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  sections: [
    {
      className: 'section-label',
      description: 'Section label',
    },
    {
      className: 'section-input-and-icon',
      description: 'Section Input and Icon',
    },
    {
      className: 'section-error-message',
      description: 'Section Error Message',
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'InputField Primary',
    },
    {
      className: '&secondary',
      description: 'InputField secondary',
    },
    {
      className: '&tertiary',
      description: 'InputField tertiary',
    },
    {
      className: '&error',
      description: 'InputField error',
    },
  ],
};

/* InputField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  autocomplete: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'bordered']),
  className: PropTypes.string,
}; */

/* InputField.defaultProps = {
  disabled: false,
  error: false,
  errorMsg: '',
  type: 'text',
  placeholder: '',
  required: false,
  name: '',
  variant: 'default',
  autocomplete: '',
  className: 'primary',
}; */
