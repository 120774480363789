import React from 'react';
import RouteTree from './components/RouteTree';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import Theme from './utils/theme';
import { GlobalContextProvider } from './context/GlobalContext';
import { IntlProvider } from 'react-intl';
import GlobalStyle from './globalStyle';
import Styled from './styles';
import { Navigation365Container } from './containers/Navigation365';
import { Footer365Container } from './containers/Footer365';
import { useGetGlobalData } from './utils/hooks/data/useGetGlobalData';
import { currentLanguage, getLocale } from './config/language.config';
import { useLoadingIndicators } from './config/general.config';
import { ClipLoader } from 'react-spinners';
import CookieConsent from './components/CookieConsent';

function App() {
  const globalData = useGetGlobalData();

  if (globalData.isFetching) {
    return useLoadingIndicators ? (
      <ClipLoader color="#999" loading size={50} />
    ) : null;
  }

  const { config, content, categories } = globalData;

  const title = 'Dexper';
  const description = 'Empowering the enterprise narrative';

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={process.env.REACT_APP_DEFAULT_LANG} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_STORAGE_URL}/assets/dex365/logos/dexper-logo-navigation.svg`}
        />
        <meta property="og:description" content={description} />
        <meta
          property="og:url"
          content="https://dexper-product-dev-dex365.web.app"
        />
        {/* {process.env.REACT_APP_ASSETS_EMBED_MODE === 'development' && (
          <>
            {demStyle && <style>{`${demStyle}`}</style>}
            {demFontStyle && <style>{`${demFontStyle}`}</style>}
          </>
        )} */}
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <GlobalContextProvider value={{ config, content, categories }}>
          <IntlProvider locale={currentLanguage} messages={getLocale()}>
            <Styled.article className="Dex365BasePage">
              <Styled.header className="section-header">
                <Navigation365Container
                  className="primary"
                  data={{ static: true }}
                />
              </Styled.header>
              <RouteTree />
              <CookieConsent />
              <Styled.footer className="section-footer">
                <Footer365Container
                  className="primary"
                  data={{ static: true }}
                />
              </Styled.footer>
            </Styled.article>
          </IntlProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
