import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 40px var(--responsive);
  height: 100%;
`;

export const Container = styled.div`
  flex-grow: 1;
  iframe {
    border: 0;
  }
`;
