import styled from 'styled-components';

interface ItemType {
  variant?: any;
  onClick?: any;
}

export const Item = styled.li<ItemType>`
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  height: 36px;
  padding-right: 1.5em;
  padding-left: 1em;
  line-height: 36px;
  &:hover {
    background: #212324;
    cursor: pointer;
  }
  &.itemFocused {
    background: #212324;
    cursor: pointer;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
