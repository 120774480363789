import { ThemeContext } from 'styled-components';
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { State } from '../../../definitions/States';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import {
  ErrorMessage,
  ErrorMessageSettings,
} from '../../Atoms/ErrorMessage/ErrorMessage';
import {
  InputFieldAtom,
  InputFieldAtomSettings,
} from '../../Atoms/InputFieldAtom/InputFieldAtom';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import {
  SelectBoxAtom,
  SelectBoxAtomSettings,
} from '../../Atoms/SelectBoxAtom/SelectBoxAtom';
import {
  SelectBoxOptions,
  SelectBoxOptionsSettings,
} from '../../Atoms/SelectBoxOptions/SelectBoxOptions';
import classNames from 'classnames';
import {
  Wrapper,
  Container,
  ListContainer,
  List,
  Item,
  Header,
  Chevron,
  SelectedElm,
} from './SelectBox.css';
import variants from './variants';
import { IconSettings } from '../../Atoms/Icon/Icon';

interface optionsType {
  label: string;
  value: string;
}

const english = {
  mandatory: 'This field is mandatory',
};

export const SelectBoxStory = {
  className: 'primary',
  defaultValue: 'Saluation',
  preSelectedValue: '',
  name: 'initial',
  onSelect: () => 'on Select',
  options: [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Diverse', value: 'diverse' },
  ],
  required: true,
  translation: english,
  error: false,
  variant: 'default',
  selectedIcon: '/assets/icons/selected.svg',
  openIcon: '/assets/icons/arrow-down.svg',
};

export interface SelectBoxType {
  name?: string;
  options: Array<optionsType>;
  defaultValue?: string;
  preSelectedValue?: string;
  required?: boolean;
  error?: boolean;
  onSelect: (label: any, value: any) => void;
  variant?: string;
  translation?: any;
  customRef?: any;
  className?: string;
  selectedIcon: string;
  openIcon: string;
}

export const SelectBox: React.FC<SelectBoxType> = ({
  options,
  name,
  defaultValue,
  preSelectedValue,
  required,
  error,
  onSelect,
  variant,
  translation,
  customRef,
  className,
  selectedIcon,
  openIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedOption, setSelectedOption] = useState(
    required && variant === 'default' ? `${defaultValue} *` : defaultValue,
  );

  const outerElement = useRef(null);
  const [selectedKeyboard, setSelectedKeyboard] = useState(false);
  const [selectedFromKeyboard, setSelectedFromKeyboard] = useState('');

  const toggling = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setShowError(true);
    }
  };

  const handleOnClickItem =
    ({ label, value }: any) =>
    () => {
      setSelected(value);
      setSelectedOption(label);
      setIsOpen(false);
      setShowError(false);

      setSelectedFromKeyboard(value);

      if (onSelect) {
        onSelect(label, value);
      }
    };

  const handleOnKeyUpItem = (e: any) => {
    const keyIndex = options.findIndex(
      (item: any) => item.value === selectedFromKeyboard,
    );

    if (e.keyCode === 9) {
      toggling();
      if (selectedFromKeyboard === '') {
        setSelectedKeyboard(true);
        setSelectedFromKeyboard(options[0].value);
      } else {
        setSelectedKeyboard(true);
        setSelectedFromKeyboard(selected);
      }
    }

    if (e.keyCode === 27) {
      toggling();
    }

    if (e.keyCode === 13) {
      setIsOpen(false);
      setSelectedKeyboard(false);
      setSelected(selectedFromKeyboard);
    }
    if (e.keyCode === 40) {
      setSelectedKeyboard(true);
      if (selectedFromKeyboard === '') {
        onSelect(options[0].label, options[0].value);
        setSelectedFromKeyboard(options[0].value);
        setSelectedOption(options[0].label);
      } else if (keyIndex < options.length - 1) {
        onSelect(options[keyIndex + 1].label, options[keyIndex + 1].value);
        setSelectedFromKeyboard(options[keyIndex + 1].value);
        setSelectedOption(options[keyIndex + 1].label);
      }
    } else if (e.keyCode === 38) {
      setSelectedKeyboard(true);
      if (keyIndex > 0) {
        onSelect(options[keyIndex - 1].label, options[keyIndex - 1].value);
        setSelectedFromKeyboard(options[keyIndex - 1].value);
        setSelectedOption(options[keyIndex - 1].label);
      }
    }
  };

  const handleOnBlurItem = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const handleOnKeyDownItem = (e: any) => {
    if (e.code === 'Tab') {
      setIsOpen(false);
    }
    if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  };

  const handleOnMouseHover = () => {
    setSelectedKeyboard(false);
  };

  useEffect(() => {
    if (preSelectedValue) {
      setSelected(preSelectedValue);
      const option = options.find(
        (option: any) => option.value === preSelectedValue,
      );
      if (option) {
        setSelectedOption(option.label);
      }
    }
  }, [options, preSelectedValue]);

  useEffect(() => {
    document.addEventListener('click', handleOuterClick);

    return () => {
      document.removeEventListener('click', handleOuterClick);
    };
  }, []);

  const handleOuterClick = (e: any) => {
    /* @ts-ignore */
    if (!(outerElement.current && outerElement.current.contains(e.target))) {
      setIsOpen(false);
    }
  };

  const currentVariant = variants(useContext(ThemeContext), variant);

  return (
    <div className={classNames('SelectBox', className)}>
      <Text className='ternary'>{name}</Text>
      {options.length !== 0 && (
        <Wrapper selected={selected} variant={currentVariant}>
          <Container ref={outerElement}>
            <SelectBoxAtom
              className='primary'
              onClick={toggling}
              onKeyUp={handleOnKeyUpItem}
              onKeyDown={handleOnKeyDownItem}
              onBlur={handleOnBlurItem}
              onMouseOut={handleOnMouseHover}
              isOpen={isOpen}
              error={error}
              openIcon='/assets/icons/arrow-down.svg'
              selectedOption={selectedOption}
            >
              <Chevron
                className='primary'
                isOpen={isOpen}
                location={openIcon}
                content={{ label: 'check' }}
              />
            </SelectBoxAtom>
            {isOpen && (
              <ListContainer>
                <List>
                  <SelectBoxOptions
                    className='primary'
                    options={options}
                    onClick={handleOnClickItem}
                    onMouseOut={handleOnMouseHover}
                    selectedKeyboard={selectedKeyboard}
                    selectedFromKeyboard={selectedFromKeyboard}
                    selected={selected}
                  >
                    <SelectedElm
                      className='secondary'
                      location={selectedIcon}
                      content={{ label: 'tickSelect' }}
                    />
                  </SelectBoxOptions>
                </List>
              </ListContainer>
            )}
            {(error || (required && showError && selected === '')) && (
              <ErrorMessage
                className='primary'
                message={translation.mandatory}
              />
            )}
          </Container>
          <input
            name={name}
            type='hidden'
            className='hide'
            value={selected}
            ref={customRef}
          />
        </Wrapper>
      )}
    </div>
  );
};

const atoms = [
  {
    atom: ErrorMessage,
    name: 'ErrorMessage~primary',
    description: 'ErrorMessage primary',
    settings: ErrorMessageSettings,
    atomVariant: '&primary',
  },
  {
    atom: Chevron,
    name: 'Icon~primary',
    description: 'Icon arrow open',
    settings: IconSettings,
    atomVariant: '&primary',
  },
  {
    atom: SelectedElm,
    name: 'Icon~secondary',
    description: 'Icon tick select',
    settings: IconSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text secondary',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Text,
    name: 'Text~ternary',
    description: 'Text ternary',
    settings: TextSettings,
    atomVariant: '&ternary',
  },
  {
    atom: SelectBoxAtom,
    name: 'SelectBoxAtom~primary',
    description: 'SelectBoxAtom primary',
    settings: SelectBoxAtomSettings,
    atomVariant: '&primary',
  },
  {
    atom: SelectBoxOptions,
    name: 'SelectBoxOptions~primary',
    description: 'SelectBoxOptions primary',
    settings: SelectBoxOptionsSettings,
    atomVariant: '&primary',
  },
];

const allCategories = [
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.border,
];
const headerCategories = [
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.border,
  CmsConfig.fill,
  CmsConfig.color,
];
const listCategories = [
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.border,
  CmsConfig.fill,
  CmsConfig.color,
];

export const SelectBoxSettings: ISettings = {
  states: [State.default, State.hover],
  include: allCategories,
  atoms: atoms,
  sections: [
    {
      className: 'section-header',
      description: 'Section header',
      include: headerCategories,
    },
    {
      className: 'section-list',
      description: 'Section list',
      include: listCategories,
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'SelectBox primary',
    },
  ],
};

SelectBox.propTypes = {
  /* @ts-ignore */
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  translation: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  classname: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'dark', 'bordered']),
};

SelectBox.defaultProps = {
  options: [
    {
      label: '',
      value: '',
    },
  ],
  name: '',
  defaultValue: 'Select',
  preSelectedValue: '',
  required: false,
  error: false,
  translation: {},
  // onSelect: () => '',
  variant: 'default',
};
