import { Category } from '@dexper.events/dexper-datamodel/lib/src/AllModels';

export function getCategoriesByParent(
  parentKeys: string[],
  globalCategories: Category[]
) {
  return parentKeys
    .map((parentKey: string) => {
      return globalCategories.filter((globalCategory: Category) => {
        return globalCategory.parent === parentKey;
      });
    })
    .flat();
}
