import { ContextProps } from '../../../../../context/GlobalContext';

export const settings = (globalData: Partial<ContextProps>) => {
  const eventTypes = globalData?.config?.eventTypes;
  const categories = globalData?.categories;
  if (!categories || !eventTypes) {
    return;
  }
  return {
    translation: {
      title: 'Filter Events',
      options: {},
      saveBtn: 'SAVE',
      resetBtn: 'Reset Filter',
    },
    toggleGroups: {
      eventType: Object.keys(eventTypes).map((key: string) => ({
        key,
      })),
    },
  };
};
