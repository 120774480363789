import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Container } from './Burger.css';
import classNames from 'classnames';

export const BurgerPropTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export type BurgerTypes = InferProps<typeof BurgerPropTypes>;

export const Burger: React.FC<BurgerTypes> = ({ className, open, onClick }) => (
  <Container
    open={open}
    onClick={() => onClick && onClick()}
    className={classNames('Burger', className)}
  >
    <div />
    <div />
    <div />
  </Container>
);

Burger.propTypes = BurgerPropTypes;
Burger.defaultProps = {
  open: false,
};
