import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import {
  DownloadContainer,
  DownloadImage,
  DownloadLink,
  StyledIcon,
} from './Download.css';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { State } from '../../../definitions/States';

const DownloadPropTypes = {
  settings: PropTypes.shape({
    tile: PropTypes.string,
    imageUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
    buttonText: PropTypes.string,
    isExternal: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
};

export interface DownloadType {
  title?: string;
  imageUrl?: string;
  downloadUrl?: string;
  buttonText?: string;
  isExternal?: boolean;
}

export interface DownloadMoleculeType {
  className?: string;
  settings: DownloadType;
}

export const Download: React.FC<DownloadMoleculeType> = ({
  settings: {
    title = 'Download',
    imageUrl,
    downloadUrl,
    buttonText = 'Download',
    isExternal,
  },
  className,
}) => {
  return (
    <DownloadContainer className={classNames('Download', className)}>
      <Heading tag='h4' className='h4'>
        {title}
      </Heading>
      <DownloadImage src={imageUrl} alt='test' className='image' />
      <DownloadLink
        href={downloadUrl}
        target='_blank'
        rel='noopener'
        className='link'
      >
        <Text className='medium'>{buttonText}</Text>
        <StyledIcon
          className='icon'
          location={
            isExternal ? '/icons/external-link.svg' : '/icons/download.svg'
          }
          content={{ label: buttonText }}
        />
      </DownloadLink>
    </DownloadContainer>
  );
};
/* @ts-ignore */
Download.propTypes = DownloadPropTypes;
Download.defaultProps = {
  settings: {
    title: 'download',
    imageUrl: '#',
    downloadUrl: '#',
    buttonText: 'Download',
    isExternal: false,
  },
};

const atoms = [
  {
    atom: Heading,
    description: 'Heading Download title',
    settings: HeadingSettings,
    atomVariant: '&h4',
  },
  {
    atom: Text,
    description: 'Text Download',
    settings: TextSettings,
    atomVariant: '&medium',
  },
  {
    atom: Icon,
    description: 'Icon Download',
    settings: IconSettings,
    atomVariant: '&icon',
  },
];

export const DownloadSettings: ISettings = {
  states: [State.default],
  variants: [
    {
      className: '&primary',
      description: 'Default',
      include: [CmsConfig.frame, CmsConfig.fill, CmsConfig.flexbox],
      atoms,
      sections: [
        {
          className: 'download-section',
          description: 'Section Download block',
          include: [CmsConfig.flexbox, CmsConfig.vmargin],
        },
        {
          className: 'image',
          description: 'Section Download image',
          include: [
            CmsConfig.frame,
            CmsConfig.border,
            CmsConfig.flexbox,
            CmsConfig.vmargin,
            CmsConfig.hmargin,
          ],
        },
      ],
    },
  ],
};
