import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { TextContainer, TopBarContainer } from './TopBar.css';
import classNames from 'classnames';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Link, LinkSettings } from '../../Atoms/Link/Link';
import { Icon } from '../../Atoms/Icon/Icon';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { CSSTransition } from 'react-transition-group';
import { State } from '../../../definitions/States';

const TopBarPropTypes = {
  settings: PropTypes.shape({
    text: PropTypes.shape({
      text: PropTypes.any.isRequired,
      buttonText: PropTypes.any.isRequired,
    }),
    callbacks: PropTypes.shape({
      onClick: PropTypes.func,
    }),
    buttonLink: PropTypes.any.isRequired,
    isShown: PropTypes.bool.isRequired,
  }),
  className: PropTypes.string,
};

export type TopBarType = InferProps<typeof TopBarPropTypes>;

export const TopBar: React.FC<TopBarType> = ({
  settings: {
    // @ts-ignore
    text: { text, buttonText },
    // @ts-ignore
    callbacks,
    // @ts-ignore
    buttonLink,
    // @ts-ignore
    isShown,
  },
  className,
}) => {
  const [isOpen, setIsOpen] = useState(isShown);

  useEffect(() => {
    setIsOpen(isShown);
  }, [isShown]);

  const openLink = () => {
    if (callbacks?.onClick) {
      callbacks.onClick();
    }
    setIsOpen(false);
  };

  return (
    <CSSTransition in={isOpen} timeout={500} classNames='fold'>
      <TopBarContainer className={classNames('TopBar', className)}>
        <TextContainer className='text-container'>
          <Text className='medium'>
            {text}

            <Link
              className='large'
              anchorType='internal'
              callback={openLink}
              label={buttonText}
              location={buttonLink}
            />
          </Text>
        </TextContainer>

        <Button
          className='custom1'
          onClick={() => {
            setIsOpen(false);
            callbacks.onClick();
          }}
        >
          <Icon location='/icons/close.svg' content={{ label: 'close' }} />
        </Button>
      </TopBarContainer>
    </CSSTransition>
  );
};

TopBar.propTypes = TopBarPropTypes;

export const TopBarSettings: ISettings = {
  states: [State.default],

  variants: [
    {
      className: '&primary',
      description: 'TopBar',
      include: [CmsConfig.frame, CmsConfig.fill, CmsConfig.flexbox],
      atoms: [
        {
          atom: Text,
          description: 'Text',
          settings: TextSettings,
          atomVariant: '&medium',
        },
        {
          atom: Link,
          description: 'Link',
          settings: LinkSettings,
          atomVariant: '&large',
        },
        {
          atom: Button,
          description: 'Button close',
          settings: ButtonSettings,
          atomVariant: '&custom1',
        },
      ],
      sections: [
        {
          className: 'text-container',
          description: 'Section text container',
          include: [CmsConfig.flexbox, CmsConfig.padding],
        },
      ],
    },
  ],
};
