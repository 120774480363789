import { getCategoriesByKey } from './getCategoriesByKey';

export const adaptCategories = (globalCategories: any, categoryKeys: any) => {
  if (
    !globalCategories ||
    !categoryKeys ||
    !categoryKeys.length ||
    typeof categoryKeys === 'undefined'
  ) {
    return [];
  }
  const categories = getCategoriesByKey(
    categoryKeys,
    globalCategories?.categories
  )?.map((category: any) => {
    const {
      key,
      shape,
      color,
      language: {
        EN: { label },
      },
    } = category;
    return {
      key,
      className: 'primary',
      shape,
      label,
      color,
      children: label,
    };
  });
  return categories;
};
