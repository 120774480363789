import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { BadgeContainer } from './Badge.css';
import { PrintVariations } from './Variations';

/* 
Implementation example:
=======================

import {Badge, BadgeStory} from '@dexper.events/product-atomic-ui';

const {settings : badgeSettings} = Dex365HeaderStory;

<div className='ProductAtomicUI'>
    <div className='section-top'>
       <Badge className={badgeSetting.className}>
              {badgeSettings.children}
       </Badge>
    </div>
</div>

*/

const BadgePropTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export type BadgeTypes = InferProps<typeof BadgePropTypes>;

export const BadgeStory = {
  className: 'primary',
  children: 'Im a badge',
};

export const Badge: React.FC<BadgeTypes> = ({ className, children }) => (
  <BadgeContainer className={classNames('Badge', className)}>
    <PrintVariations className={className}>{children} </PrintVariations>
  </BadgeContainer>
);

export const BadgeSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.text,
    CmsConfig.color,
    CmsConfig.altColor,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
  ],
  variants: [
    {
      className: '&primary',
      description: 'Badge Upcoming',
    },
    {
      className: '&secondary',
      description: 'Badge Concluded',
    },
    {
      className: '&tertiary',
      description: 'Badge Live',
    },
    {
      className: '&quaternary',
      description: 'Badge quaternary',
    },
  ],
};

Badge.propTypes = BadgePropTypes;
