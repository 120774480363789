import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Container } from './ExampleComponent.css';

const ExampleComponentPropTypes = {
  text: PropTypes.string,
};

export type ExampleComponentType = InferProps<typeof ExampleComponentPropTypes>;

export const ExampleComponent: React.FC<ExampleComponentType> = ({ text }) => (
  <Container className='ExampleComponent'>{text}</Container>
);

ExampleComponent.propTypes = {
  text: PropTypes.string.isRequired,
};
