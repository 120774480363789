/* eslint-disable react/react-in-jsx-scope */
import { Link } from '../../Atoms/Link/Link';
import { Button } from '../../Atoms/Button/Button';
import { StyledSection } from './FilterBar365.css';
import { LinkWrapper } from './FilterBar365.css';
export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;
  switch (variation) {
    case 'primary':
      return (
        <>
          <StyledSection className='section-links'>
            {settings.links.map((link: any, index: number) => {
              return (
                <LinkWrapper key={index}>
                  <Link
                    className={link.className}
                    anchorType={link.anchorType}
                    targetBlank={link.targetBlank}
                    callback={link.callback}
                    label={link.label}
                    location={link.location}
                  >
                    {link.label}
                  </Link>
                </LinkWrapper>
              );
            })}
          </StyledSection>
          <StyledSection className='section-button'>
            {settings.filterIcon && settings.filterIcon !== '' && (
              <Button
                className='primary'
                image={settings.filterIcon}
                onClick={settings.callbacks.onFilter}
              >
                {settings.text.filter}
              </Button>
            )}
          </StyledSection>
        </>
      );
      break;
    default:
      return <p>no card variation selected</p>;
  }
};
