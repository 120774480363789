import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { MeetupCardContainer } from './MeetupCard.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { RichText, RichTextSettings } from '../../Atoms/RichText/RichText';
import { Tooltip, TooltipSettings } from '../../Atoms/Tooltip/Tooltip';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { DateTime, DateTimeSettings } from '../../Atoms/DateTime/DateTime';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';

/* 
Implementation example:
=======================

import {MeetupCardStory} from '@dexper.events/product-atomic-ui';

const {settings : meetupCardSettings} = MeetupCardStory;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <MeetupCard settings={meetupCardSettings} className="primary"></MeetupCard> 
     </div>
 </div> 

*/

export const meetupCardSettings = {
  text: {
    titleType: 'h1',
    title: 'How to run your first virtual event with Dexper ',
    description: `Running your first event can be scary, but we \
    are here to help you. Our experience will take your event. `,
    spaceLimited: '*Space limited',
    readMoreButton: 'Read more',
    signupPlus: '13',
    concluded: 'CONCLUDED',
    onLinkWillFollow: 'LINK WILL FOLLOW',
    tooltip: '5 minutes before session, link will be provided',
    goToLive: 'GO TO LIVE',
    signup: 'SIGNUP',
    full: 'FULL',
  },
  buttonStatus: 'live', // live, signup, full, check, concluded
  callbacks: {
    onGoToLive: (...args: any[]) => {
      console.log('onGoToLive fired', args);
    },
    onSignUp: (...args: any[]) => {
      console.log('onSignUp fired', args);
    },
    onSignups: (...args: any[]) => {
      console.log('onSignups fired', args);
    },
    onSignupsPlus: (...args: any[]) => {
      console.log('onSignupsPlus fired', args);
    },
    onReadMore: (...args: any[]) => {
      console.log('onReadMore fired', args);
    },
  },
  signups: [
    {
      backgroundImage: '/assets/images/meetupCard1.svg',
    },
    {
      backgroundImage: '/assets/images/meetupCard2.svg',
    },
    {
      backgroundImage: '/assets/images/meetupCard3.svg',
    },
    {
      backgroundImage: '/assets/images/meetupCard4.svg',
    },
  ],
  showTooltip: true,
  showSignupPlus: true,
  infoIcon: '/assets/icons/info.svg',
  checkIcons: '/assets/icons/check.svg',
  onConcluded: false,
  onLinkWillFollow: false,
  dateTime: {
    locale: 'en-US',
    timeStartExpected: 1634540400000,
    timeEndExpected: 1634543400000,
    hour12: true,
    showDate: true,
    showTime: true,
    showTimeZone: true,
    showYear: false,
    longMonth: false,
    useSuffixedDay: false,
  },
};

export const MeetupCardStory = {
  settings: meetupCardSettings,
  className: 'primary',
};

const MeetupCardPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      readMoreButton: PropTypes.string.isRequired,
      spaceLimited: PropTypes.string.isRequired,
      signupPlus: PropTypes.string.isRequired,
      concluded: PropTypes.string.isRequired,
      onLinkWillFollow: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      goToLive: PropTypes.string.isRequired,
      signup: PropTypes.string.isRequired,
      full: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onGoToLive: PropTypes.func.isRequired,
      onSignUp: PropTypes.func.isRequired,
      onSignups: PropTypes.func.isRequired,
      onSignupsPlus: PropTypes.func.isRequired,
      onReadMore: PropTypes.func.isRequired,
    }).isRequired,
    buttonStatus: PropTypes.string.isRequired,
    signups: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundImage: PropTypes.string,
      }),
    ).isRequired,
    showSignupPlus: PropTypes.bool.isRequired,
    showTooltip: PropTypes.bool.isRequired,
    dateTime: PropTypes.shape({
      locale: PropTypes.string.isRequired,
      timeStartExpected: PropTypes.number.isRequired,
      timeEndExpected: PropTypes.number.isRequired,
      hour12: PropTypes.bool.isRequired,
      showDate: PropTypes.bool.isRequired,
      showTime: PropTypes.bool.isRequired,
      showTimeZone: PropTypes.bool.isRequired,
      showYear: PropTypes.bool.isRequired,
      longMonth: PropTypes.bool.isRequired,
      useSuffixedDay: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};

export type MeetupCardTypes = InferProps<typeof MeetupCardPropTypes>;

export const MeetupCard: React.FC<MeetupCardTypes> = ({
  className,
  settings,
}) => {
  return (
    <MeetupCardContainer className={classNames('MeetupCard', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </MeetupCardContainer>
  );
};

const atoms = [
  {
    atom: Tooltip,
    name: 'Tooltip~primary',
    description: 'Tooltip icon',
    settings: TooltipSettings,
    atomVariant: '&primary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading speaker name',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
  {
    atom: DateTime,
    name: 'DateTime~primary',
    description: 'DateTime',
    settings: DateTimeSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text secondary',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
  {
    atom: RichText,
    name: 'RichText~primary',
    description: 'RichText primary',
    settings: RichTextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button goToLive',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~secondary',
    description: 'Button Signup',
    settings: ButtonSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Button,
    name: 'Button~tertiary',
    description: 'Button Full',
    settings: ButtonSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Button,
    name: 'Button~quaternary',
    description: 'Button Check',
    settings: ButtonSettings,
    atomVariant: '&quaternary',
  },
  {
    atom: Button,
    name: 'Button~quinary',
    description: 'Button signup',
    settings: ButtonSettings,
    atomVariant: '&quinary',
  },
  {
    atom: Button,
    name: 'Button~senary',
    description: 'Button signup+',
    settings: ButtonSettings,
    atomVariant: '&senary',
  },
  {
    atom: Button,
    name: 'Button~septanary',
    description: 'Button check',
    settings: ButtonSettings,
    atomVariant: '&septanary',
  },
  {
    atom: Button,
    name: 'Button~octonary',
    description: 'Button Concluded',
    settings: ButtonSettings,
    atomVariant: '&octonary',
  },
];

export const MeetupCardSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
    CmsConfig.text,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-date-time',
      description: 'Section date time',
      include: [
        CmsConfig.text,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-title',
      description: 'Section time',
      include: [
        CmsConfig.text,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-description',
      description: 'Section description',
      include: [
        CmsConfig.text,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-signups',
      description: 'Section signups',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-tooltip', // only required for the Rich Text to work
      description: 'Section tooltip',
      include: [
        CmsConfig.text,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-buttons',
      description: 'Section buttons',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-space-limited',
      description: 'Section Space Limited',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
        CmsConfig.text,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'MeetupCard primary',
    },
  ],
};

MeetupCard.propTypes = MeetupCardPropTypes;
