import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Dex365HeaderContainer } from './Dex365Header.css';
import classNames from 'classnames';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { Badge, BadgeSettings } from '../../Atoms/Badge/Badge';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';

/* 
Implementation example:
=======================

import {Dex365Header, Dex365HeaderStory} from '@dexper.events/product-atomic-ui';

const {settings : dex365HeaderSettings} = Dex365HeaderStory;

<div className='ProductAtomicUI'>
    <div className='section-top'>
        <Dex365Header settings={dex365HeaderSettings} className='primary' />
    </div>
</div>

*/

export const dex365HeaderSettings = {
  text: {
    badgeLabel: 'Upcoming', // empty string to not show Badge
    dateTime: 'April 11, 2021 | 3:00pm - 7:00pm CEST',
    titleType: 'h1',
    title: 'Dexper Digital 2021',
    description:
      'At a time of transformation across industries and sectors, we want to help you along your journey towards a scalable event program',
    buttonLabel: 'Register now', // empty string to not show Button
  },
  callbacks: {
    onSubmit: (...args: any[]) => {
      console.log('onSubmit fired', args);
    },
  },
  badgeVariant: 'primary', // primary = upcoming | secondary = concluded | tertiary = live
  backgroundImage: '/assets/images/Page-banner.png',
  topTitleImage: '/assets/images/Page-banner.png',
  firstGradientColor: 'rgba(20, 28, 40, 0.9)',
  secondGradientColor: 'rgba(20, 28, 40, 0.50)',
  thirdGradientColor: 'rgba(20, 28, 40, 0.9)',
};

export const Dex365HeaderStory = {
  settings: dex365HeaderSettings,
  className: 'primary',
};

const Dex365HeaderPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      badgeLabel: PropTypes.string.isRequired,
      dateTime: PropTypes.string.isRequired,
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonDisabled: PropTypes.bool,
    }).isRequired,
    callbacks: PropTypes.shape({
      onSubmit: PropTypes.func.isRequired,
    }).isRequired,
    backgroundImage: PropTypes.string.isRequired,
    topTitleImage: PropTypes.any,
    firstGradientColor: PropTypes.string.isRequired,
    secondGradientColor: PropTypes.string.isRequired,
    thirdGradientColor: PropTypes.string.isRequired,
    badgeVariant: PropTypes.string.isRequired,
  }).isRequired,
};

export type Dex365HeaderTypes = InferProps<typeof Dex365HeaderPropTypes>;

export const Dex365Header: React.FC<Dex365HeaderTypes> = ({
  className,
  settings,
}) => (
  <Dex365HeaderContainer
    backgroundImage={settings.backgroundImage}
    firstGradientColor={settings.firstGradientColor}
    secondGradientColor={settings.secondGradientColor}
    thirdGradientColor={settings.thirdGradientColor}
    className={classNames('Dex365Header', className, settings)}
  >
    <PrintVariations
      className={className}
      settings={settings}
    ></PrintVariations>
  </Dex365HeaderContainer>
);

const atoms = [
  {
    atom: Badge,
    name: 'Badge~primary',
    description: 'Badge primary',
    settings: BadgeSettings,
    atomVariant: '&primary',
  },
  {
    atom: Badge,
    name: 'Badge~secondary',
    description: 'Badge secondary',
    settings: BadgeSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Badge,
    name: 'Badge~tertiary',
    description: 'Badge tertiary',
    settings: BadgeSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button primary',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
];

export const Dex365HeaderSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-top',
      description: 'Section top',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-middle',
      description: 'Section middle',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-bottom',
      description: 'Section bottom',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'Dex365Header primary',
    },
    {
      className: '&secondary',
      description: 'Dex365Header secondary',
    },
  ],
};

Dex365Header.propTypes = Dex365HeaderPropTypes;
