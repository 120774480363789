import styled from 'styled-components';

export const VerticalBar = styled.span`
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 4px 6px;
  background-color: var(--alt-color);
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextWrapper = styled.div`
  white-space: nowrap;
`;
