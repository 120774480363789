import styled from 'styled-components';

type GridContainerProps = {
  columns: number;
  minWidthTablet: string;
  minWidthDesktop: string;
};

export const GridContainer = styled.section<GridContainerProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 20px;

  @media screen and (min-width: ${({ minWidthTablet }) =>
      minWidthTablet ? minWidthTablet : '768px'}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
  }
  /* Desktop */
  @media screen and (min-width: ${({ minWidthDesktop }) =>
      minWidthDesktop ? minWidthDesktop : '1200px'}) {
    grid-template-columns: repeat(
      var(--grid-columns, ${({ columns }) => columns}),
      minmax(0, 1fr)
    );
    gap: 32px;
  }
`;
