import { Grid } from '@dexper.events/product-atomic-ui';
import styled from 'styled-components';
import { Slide } from 'pure-react-carousel';
import { ResponsiveRow } from '../../styles';

export const StyledOnDemandContainer = styled.div`
  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );
  .section-content-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .Text {
      font-size: 16px;
      color: #00234b;
    }
    .Button {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
`;

export const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

export const ResponsiveRowAllVideos = styled(ResponsiveRow)`
  padding: 40px var(--responsive);
`;

export const StyledSlide = styled(Slide)``;

export const FilterBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFilterBar = styled.div`
  display: flex;
  padding: 0 calc(var(--responsivePadding) + 10px);
  align-items: center;
  justify-content: space-between;
  background-color: #3d4859;
  height: 55px;
  .FilterManager-filterButtonWrapper {
    button {
      font-size: 14px;
      padding: 0;
      color: #fff;
      text-transform: initial;
      background: transparent;
    }
    button:hover {
      font-size: 14px;
      padding: 0;
      color: #fff;
      opacity: 0.8;
      text-transform: initial;
      background: transparent;
    }
    button:first-child {
      color: white !important;
      font-weight: 400 !important;
      .on-default,
      .on-hover {
        display: none;
      }
      :after {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background: 0 0 no-repeat url(/assets/icons/filter.svg);
        margin-left: 10px;
      }
    }
  }
`;
