import React, { useContext } from 'react';
import { FilterList } from '@dexper.events/old-atomic-ui';
import { settings } from './settings';
import { FilterOption, IFilterListContainer } from './types';
import GlobalContext from '../../../../../context/GlobalContext';

export const FilterListContainer = ({
  className,
  setEventTypeFilter,
  setCategoryFilter,
  setSelectedFilterValues,
  selectedFilterValues,
  setShowSidePanel,
}: IFilterListContainer) => {
  const globalData = useContext(GlobalContext);

  const categories = globalData?.categories;
  const visibleCategories = categories?.categories;

  if (!visibleCategories) {
    return null;
  }

  function resetEventTypeFilter() {
    setEventTypeFilter([]);
  }

  const filterListSettings = settings(globalData);

  function onFilterListSelect(
    selectionOverview: any,
    selectedFilterValues: string[]
  ) {
    const { eventType } = selectionOverview;
    if (eventType) {
      const eventTypeSelected = eventType.filter(
        (option: FilterOption) => option.checked
      );
      if (eventTypeSelected.length) {
        setEventTypeFilter(eventTypeSelected);
      } else {
        resetEventTypeFilter();
      }
    } else {
      resetEventTypeFilter();
    }

    let selectedCategories = [] as any;

    Object.entries(selectionOverview).forEach(([key, value]) => {
      if (key !== 'eventType') {
        const cats = value as any;
        const newCats = cats.filter((value: any) => {
          return value.checked === true;
        });
        if (newCats.length) {
          selectedCategories = selectedCategories.concat(newCats);
        }
      }
    });

    setCategoryFilter(selectedCategories);

    setSelectedFilterValues(selectedFilterValues);
    setShowSidePanel(false);
  }
  return (
    <FilterList
      className={className}
      translation={filterListSettings?.translation}
      togglesGroups={filterListSettings?.toggleGroups}
      onSelect={onFilterListSelect}
      values={selectedFilterValues}
    />
  );
};
