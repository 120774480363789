import { ReactComponent as Logo } from './assets/check.svg';
import styled from 'styled-components';

interface T {
  onClick: () => any;
  checked: boolean;
}

export const TickLogo = styled(Logo)`
  width: 15px;
  height: 22px;
`;

export const CheckboxContainer = styled.div<T>`
  margin: 1px;
  border-radius: 1px;
  display: flex;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

interface A {
  checked: boolean;
}
export const StyledCheckbox = styled.label<A>`
  width: 23px;
  height: 23px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  user-select: none;
  & > * {
    display: ${(props) => (props.checked ? 'flex' : 'none')};
  }
`;
