import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dexperFirestore } from '../../../utils/firebase';
import useGetCollectionOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { Heading, Button } from '@dexper.events/product-atomic-ui';
import { SidePanel } from '@dexper.events/old-atomic-ui';
import { StyledEventContainer, StyledFilterBar, Wrapper } from './styles';
import { Dex365HeaderContainer } from '../../containers/Dex365Header';
import { SubMenuContainer } from './containers/SubMenu';
import { CardEventContainer } from './containers/CardEvent';
import { FilterListContainer } from './containers/FilterList';
import { filterByEventTime, filterByEventType } from './helpers/filters';
import { FilterOption } from './containers/FilterList/types';
import Styled, { StyledGrid } from '../../styles';
import { GenericWithDocumentID } from '../../../types';
import { Event365 } from '@dexper.events/dexper-datamodel';
import GlobalContext from '../../../context/GlobalContext';
import useGetDocumentOnSnapshot from '../../../utils/hooks/firebase/dexper/useGetDocumentOnSnapshot';
import { getCategoriesByParent } from '../../../utils/helpers/categories/getCategoriesByParent';
import { Category } from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import { limit, orderBy } from 'firebase/firestore';
import { EVENTS_FILTERBUTTON } from '../../../constants/storage.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dex365ContainerAppSettings } from '../../containers/Dex365Header/types';
import Head from '../../../components/Head';
import { currentLanguage } from '../../../config/language.config';
import { useAnalytics } from '../../../utils/hooks/analytics/useAnalytics';
import { EventsOverviewTranslationsType } from './types';
import { filterByCategory } from './helpers/filters/filterByCategory';
import { getStatusBadgeStatus } from './helpers';

function EventPage() {
  useAnalytics();
  const history = useHistory();
  const intl = useIntl();
  const globalData = useContext(GlobalContext);
  const [eventData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Events(),
    true,
    [orderBy('startTimeMs', 'asc'), limit(100)]
  );
  const [headerData] = useGetDocumentOnSnapshot(
    dexperFirestore.dex365Events(
      globalData?.content?.pageHeaders?.event?.eventID ?? ''
    ),
    true
  );
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [filteredEventData, setFilteredEventData] =
    useState<GenericWithDocumentID<Event365>[]>();
  const [eventTimeFilter, setEventTimeFilter] = useState<string>('all');
  const [eventTypeFilter, setEventTypeFilter] = useState<FilterOption[]>([]);
  const [categoryParentFilter, setCategoryParentFilter] = useState<
    FilterOption[]
  >([]);
  const [categoryFilter, setCategoryFilter] = useState<FilterOption[]>([]);
  const [categoriesByParent, setCategoriesByParent] = useState<Category[]>();
  const [selectedFilterValues, setSelectedFilterValues] = useState<
    string[] | null
  >([]);

  const adaptEventData = (eventData: any) => {
    const concludedEvents = eventData
      .filter((event: any) => {
        const { startTimeMs, endTimeMs } = event;
        const badgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
        const status = badgeStatus.status;
        return status === 'concluded';
      })
      .sort((a: any, b: any) => {
        return b.startTimeMs - a.startTimeMs;
      });
    const activeEvents = eventData.filter((event: any) => {
      const { startTimeMs, endTimeMs } = event;
      const badgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
      const status = badgeStatus.status;
      return status !== 'concluded';
    });
    const customSortedEvents = activeEvents.concat(concludedEvents);
    const adaptedEventData = customSortedEvents.map((event: any) => {
      return {
        ...event,
      };
    });
    return adaptedEventData;
  };

  useEffect(() => {
    if (!eventData) {
      return;
    }
    setFilteredEventData(adaptEventData(eventData));
  }, [eventData]);

  useEffect(() => {
    if (!eventData) {
      return;
    }
    // Pass through to all filter function, and let the filter function apply a filter
    // just or return the current filter value
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filteredByEventTime = filterByEventTime(eventData, eventTimeFilter);
    const filteredByEventType = filterByEventType(
      filteredByEventTime,
      eventTypeFilter
    );

    const filteredByCategory = filterByCategory(
      filteredByEventType,
      categoryFilter
    );

    setFilteredEventData(adaptEventData(filteredByCategory));
  }, [eventTimeFilter, eventTypeFilter, categoriesByParent]);

  useEffect(() => {
    if (!globalData.categories) {
      return;
    }
    const { categories } = globalData.categories;
    setCategoriesByParent(
      getCategoriesByParent(
        categoryParentFilter.map((category: FilterOption) => {
          return category.key;
        }),
        categories
      )
    );
  }, [categoryParentFilter, globalData.categories]);

  const appSettings: { dex365HeaderContainer: Dex365ContainerAppSettings } = {
    dex365HeaderContainer: {
      showButtonLabel: true,
      customButtonLabel:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        globalData?.content?.pageHeaders?.event?.language[currentLanguage]
          ?.label || '',
      callbacks: {
        onSubmit: (slug: string) => {
          history.push(`/events/detail/${slug}`);
        },
      },
    },
  };

  let pageContent;
  if (!globalData.content) {
    pageContent = {};
  } else {
    const {
      content: {
        eventsOverview: {
          language: { [currentLanguage]: content },
        },
      },
    } = globalData;
    pageContent = content;
  }

  return (
    <Wrapper>
      <Head title={intl.formatMessage({ id: 'analytics.event.overview' })} />
      <Styled.main className="Dex365EventPage">
        <section className="section-row1">
          <Dex365HeaderContainer
            className="primary"
            data={headerData}
            appSettings={appSettings.dex365HeaderContainer}
          />
        </section>
        <section className="section-row2">
          <StyledFilterBar>
            <SubMenuContainer
              className="primary"
              setEventTimeFilter={setEventTimeFilter}
              eventTimeFilter={eventTimeFilter}
            />
            <Button
              image={EVENTS_FILTERBUTTON}
              onClick={() => setShowSidePanel(true)}
              className="secondary"
            />
          </StyledFilterBar>
        </section>
        <section className="section-row3">
          <StyledEventContainer>
            <Heading className="channels-heading" tag="h2">
              {pageContent[
                `${eventTimeFilter}EventsLabel` as EventsOverviewTranslationsType
              ] || 'All Events'}
            </Heading>
            {filteredEventData && (
              <>
                {filteredEventData.length === 0 ? (
                  <p>
                    <FormattedMessage id="events.noResults" />
                  </p>
                ) : (
                  <StyledGrid columns={2}>
                    {filteredEventData.map((dex365Event) => (
                      <section
                        key={dex365Event.startTimeMs}
                        className="section-top"
                      >
                        <CardEventContainer
                          className="primary"
                          dex365Event={dex365Event}
                        />
                      </section>
                    ))}
                  </StyledGrid>
                )}
              </>
            )}
          </StyledEventContainer>
        </section>
        <SidePanel
          isVisible={showSidePanel}
          onClose={() => {
            setShowSidePanel(false);
          }}
        >
          <FilterListContainer
            className="primary"
            setEventTypeFilter={setEventTypeFilter}
            setCategoryParentFilter={setCategoryParentFilter}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
            setSelectedFilterValues={setSelectedFilterValues}
            selectedFilterValues={selectedFilterValues}
            setShowSidePanel={setShowSidePanel}
          />
        </SidePanel>
      </Styled.main>
    </Wrapper>
  );
}

export default EventPage;
