import styled from 'styled-components';

interface A {
  variant?: any;
  isOpen?: boolean;
  error?: boolean;
}

export const Header = styled.div<A>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-bottom: 4px;
  padding-right: 1.5em;
  padding-left: 1em;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px pink solid;
  background: gold;
  &:focus {
    outline: none;
  }
  line-height: 45px;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
