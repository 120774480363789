import React, { useLayoutEffect, useState } from 'react';
import { Footer365 } from '@dexper.events/product-atomic-ui';
import { IFooter365Container } from './types';
import { settings } from './settings';
import { StyledContainer } from './styles';

export const Footer365Container = ({
  className,
  data,
}: IFooter365Container) => {
  const [Footer365Settings, setFooter365Settings] = useState<any>();
  useLayoutEffect(() => {
    if (!data) {
      return;
    }
    setFooter365Settings(settings());
  }, [data]);
  // Footer navigation has not been added in the config data yet
  // Se we'll define it manually until it is added
  // const globalData = useContext(GlobalContext);
  // console.log(globalData.config, globalData.content);
  return (
    <StyledContainer>
      {Footer365Settings && (
        <Footer365 className={className} settings={Footer365Settings} />
      )}
    </StyledContainer>
  );
};
