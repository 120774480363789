import styled from 'styled-components';
export const SponsorCardContainer = styled.button`
  cursor: pointer;
`;

export const StyledSection = styled.div``;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  color: #00b568;
`;

export const StyledHRSection = styled.div`
  hr {
    border: none;
  }
`;

export const TextStyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
  h4 {
    text-align: initial;
  }
  p {
    text-align: initial;
  }
`;

/* media queries to be refactord */
/* export const StyledImageSection = styled.div`
  @media screen and (max-width: 768px) {
    :first-child {
      width: 200px !important;
      height: 80px !important;
    }
  }
`; */

/* media queries working version !important */
/* export const StyledImageSection = styled.div`
  @media screen and (max-width: 768px) {
    button {
      width: 200px !important;
      height: 80px !important;
    }
  }
`; */

/* type StyledImageSection2Props = {
  className: string;
};
 */

/* type ResponsiveImageButtonProps = {
  background: string;
  alt: string;
}; */

/* export const ResponsiveImageButton = styled.button<ResponsiveImageButtonProps>`
  ${({ background }) =>
    css`
      background: ${background};
    `};

  @media screen and (max-width: 768px) {
    width: 200px !important;
    height: 80px !important;
  }
`; */

export const ResponsiveContainer = styled.div``;
