import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { RowContainer } from './Row.css';
import classNames from 'classnames';

const RowPropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export type RowType = InferProps<typeof RowPropTypes>;

export const Row: React.FC<RowType> = ({ className, children }) => (
  <RowContainer className={classNames('Row', className)}>
    {children}
  </RowContainer>
);

Row.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
