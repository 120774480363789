import PropTypes, { InferProps } from 'prop-types';

export const QandACardPropTypes = {
  content: PropTypes.shape({
    translation: PropTypes.shape({
      nvotes: PropTypes.string.isRequired,
      back_to_question: PropTypes.string.isRequired,
      voted: PropTypes.string.isRequired,
      vote: PropTypes.string.isRequired,
      qa_cards: PropTypes.string.isRequired,
      ask_question: PropTypes.string.isRequired,
      see_answer: PropTypes.string.isRequired,
      qa_will_start: PropTypes.string.isRequired,
      qa_will_start_sub: PropTypes.string.isRequired,
      ask_a_question: PropTypes.string.isRequired,
      ask_a_question_desc: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
      question_sent: PropTypes.string.isRequired,
      question_sent_desc: PropTypes.string.isRequired,
      go_to_live: PropTypes.string.isRequired,
    }).isRequired,
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answered: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    questioner_pict: PropTypes.string.isRequired,
    votesCount: PropTypes.number.isRequired,
    hasVoted: PropTypes.bool.isRequired,
    userPublic: PropTypes.string,
    onOpenMiniProfile: PropTypes.func,
    onVote: PropTypes.func.isRequired,
    useProcessingAnimation: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    activeElm: PropTypes.shape({
      voteIsLoading: PropTypes.bool,
      votedElm: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  useCheckIcon: PropTypes.bool,
  className: PropTypes.string,
};

export const QandACardDefaultProps = {
  useCheckIcon: true,
};

export type QandACardTypes = InferProps<typeof QandACardPropTypes>;
