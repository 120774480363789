import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { CardSeriesContainer, TopSection } from './CardSeries.css';
import classNames from 'classnames';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
import { Category, CategorySettings } from '../../Atoms/Category/Category';

/* 
Implementation example:
=======================

import {CardSeriesStory} from '@dexper.events/product-atomic-ui';

const {settings : cardSeriesSettings} = SeriesCardStory;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <CardSeries settings={seriesCardSettings} className="primary"></CardSeries> 
     </div>
 </div> 

*/

export const cardSeriesSettings = {
  text: {
    titleType: 'h1',
    title: 'Follow John Legend',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In imperdiet est sit amet turpis pellentesque, vel sodales dolor aliquam.',
  },
  callbacks: {
    onClick: (...args: any[]) => {
      console.log('onClick fired', args);
    },
    onPlay: (e: any) => {
      e.stopPropagation(); // to not pass the event up to the parent (Card wrapped on button)
      console.log('onPlay fired!');
    },
    onInfo: (e: any) => {
      e.stopPropagation();
      console.log('onInfo fired!');
    },
  },
  categories: [
    {
      className: 'primary',
      shape: 'circle',
      children: 'Fun',
      color: '#FF8330',
    },
    {
      className: 'primary',
      shape: 'circle',
      children: 'Dexper',
      color: '#1881FF',
    },
  ],
  backgroundImage: '/assets/images/serieCard-1.jpg',
  topImage: '/assets/images/serieCard-1.jpg',
  firstGradientColor: 'rgb(253, 129, 179)',
  secondGradientColor: 'rgba(253, 129, 179, 0)',
};

export const CardSeriesStory = {
  settings: cardSeriesSettings,
  className: 'primary',
};

const CardSeriesPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      onPlay: PropTypes.func,
      onInfo: PropTypes.func.isRequired,
    }).isRequired,
    categories: PropTypes.array.isRequired,
    backgroundImage: PropTypes.string.isRequired,
    topImage: PropTypes.any,
    firstGradientColor: PropTypes.string.isRequired,
    secondGradientColor: PropTypes.string.isRequired,
    baseUrl: PropTypes.string,
  }).isRequired,
};

export type CardSeriesTypes = InferProps<typeof CardSeriesPropTypes>;

export const CardSeries: React.FC<CardSeriesTypes> = ({
  className,
  settings,
}) => (
  <CardSeriesContainer
    backgroundImage={settings.backgroundImage}
    firstGradientColor={settings.firstGradientColor}
    secondGradientColor={settings.secondGradientColor}
    className={classNames('CardSeries', className, settings)}
    onClick={() => {
      settings?.callbacks.onClick();
    }}
  >
    <TopSection topImage={settings?.topImage} />
    <PrintVariations
      className={className}
      settings={settings}
    ></PrintVariations>
  </CardSeriesContainer>
);

const atoms = [
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
  {
    atom: Category,
    name: 'Category~primary',
    description: 'Category primary',
    settings: CategorySettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button primary',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
];

export const CardSeriesSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
    CmsConfig.cursor,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-heading',
      description: 'Section heading',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-categories',
      description: 'Section categories',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-hover',
      description: 'Section hover',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'CardSeries primary',
    },
  ],
};

CardSeries.propTypes = CardSeriesPropTypes;
