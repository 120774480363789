import styled, { css } from 'styled-components';

interface Dex365HeaderContainerProps {
  backgroundImage: string;
  firstGradientColor: string;
  secondGradientColor: string;
  thirdGradientColor: string;
}

export const Dex365HeaderContainer = styled.div<Dex365HeaderContainerProps>`
  background-image: linear-gradient(
      180deg,
      ${({ firstGradientColor }) => firstGradientColor} 0%,
      ${({ secondGradientColor }) => secondGradientColor} 51.56%,
      ${({ thirdGradientColor }) => thirdGradientColor} 100%
    ),
    url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 0px center;
`;

export const StyledSection = styled.div`
  width: 100%;
`;
interface Dex365HeaderImageProps {
  topImage?: string;
}

export const TopImage = styled.div<Dex365HeaderImageProps>`
  ${({ topImage }) =>
    topImage &&
    css`
      width: 100px;
      height: 100px;
      background: center/cover url(${topImage});
      background-size: contain;
      background-repeat: no-repeat;
    `}
`;
