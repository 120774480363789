import styled from 'styled-components';
import { Icon } from '../../Atoms/Icon/Icon';

export const Wrapper = styled.div`
  flex-direction: row;
`;

export const ErrorMessageWrapper = styled.div`
  width: 205px;
`;

export const ModalBox = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.8); */
  z-index: 999;
`;

export const ModalContent = styled.div`
  position: absolute;
  padding: 20px;
  /* background: #fff; */
  min-height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  @media (max-width: 375px) {
    display: block;
  }
`;

interface A {
  img?: string;
}
export const AvatarImg = styled.div<A>`
  width: 55px;
  height: 55px;
  background: ${({ img }) => `no-repeat center/cover url(${img})`};
`;

export const Img = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

export const Label = styled.label`
  display: flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  &:hover ${Img} {
    opacity: 50%;
  }
`;

export const Title = styled.p`
  color: #343536;
  font-size: ${({ theme }) => theme.headingStyles.h3.desktop.fontSize};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.headingStyles.h3.desktop.lineHeight};
  letter-spacing: 0;
  text-align: left;
`;

export const FileInput = styled.input`
  display: none;
`;

export const SubWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LinkWithLabel = styled.a`
  margin-left: 15px;
`;

export const CanvasWrapper = styled.div`
  position: relative;
  margin-right: 30px;
`;

export const ImgDiv = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  right: 2px;
  top: 2px;
  z-index: 5;
  /* background: #333; */
`;

export const StyledIcon = styled(Icon)`
  /* margin-left: 8px; */
  width: 18px;

  cursor: pointer;
  vertical-align: middle;
`;
