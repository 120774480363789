import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Container } from './ErrorMessage.css';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import classNames from 'classnames';
import { PrintVariations } from './variations';

export const errorMessageSettings = {
  message: 'Error message sample',
};

export const ErrorMessageStory = {
  className: 'primary',
  message: 'Error message sample',
};

const ErrorMessagePropTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export type ErrorMessageTypes = InferProps<typeof ErrorMessagePropTypes>;

export const ErrorMessage: React.FC<ErrorMessageTypes> = ({
  className,
  message,
}) => (
  <>
    <Container className={classNames('ErrorMessage', className)}>
      <PrintVariations
        className={className}
        message={message}
      ></PrintVariations>
    </Container>
  </>
);

const allCategories = [
  CmsConfig.fill,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.vmargin,
  CmsConfig.hmargin,
  CmsConfig.text,
];

export const ErrorMessageSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'Error Message',
    },
  ],
};

/* 
ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: '',
  className: 'primary',
};
 */
