import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Box } from '../../Layout/Box/Box';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { MiniProfileContainer } from './MiniProfile.css';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { State } from '../../../definitions/States';
import { SocialPropTypes } from '../../../definitions/PropTypes';

const MiniProfilePropTypes = {
  settings: PropTypes.shape({
    displayName: PropTypes.string,
    profilePicture: PropTypes.string,
    pronouns: PropTypes.string,
    jobTitle: PropTypes.string,
    company: PropTypes.string,
    buttonText: PropTypes.string,
    callbacks: PropTypes.shape({
      onSubmit: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
    }).isRequired,
    socials: PropTypes.arrayOf(SocialPropTypes),
  }),
  className: PropTypes.string,
};

export interface SocialType {
  socialName: string;
  socialLogo: string;
  onClickSocial: () => void;
}
export interface Callbacks {
  onSubmit: () => void;
  onClose: () => void;
}

export interface MiniProfileType {
  displayName?: string;
  profilePicture?: string;
  pronouns?: string;
  jobTitle?: string;
  company?: string;
  buttonText?: string;
  callbacks: Callbacks;
  socials?: Array<SocialType>;
}

export interface MiniProfileMoleculeType {
  className?: string;
  settings: MiniProfileType;
}

export const MiniProfile: React.FC<MiniProfileMoleculeType> = ({
  className,
  settings: {
    displayName,
    profilePicture,
    pronouns,
    jobTitle,
    company,
    buttonText,
    socials,
    callbacks,
  },
}) => {
  return (
    <MiniProfileContainer className={classNames('MiniProfile', className)}>
      <Box className='top-bar-section'>
        <Button className='secondary' onClick={callbacks.onClose}>
          <Icon
            className='secondary'
            location='/icons/x-circle.svg'
            content={{ label: '' }}
          />
        </Button>
      </Box>
      <Box className='middle-section'>
        <Button className='image' image={profilePicture}>
          {displayName}
        </Button>

        <Box className='all-text-section'>
          <Heading tag='h4' className='h4'>
            {displayName}
          </Heading>

          <Text className='small'>{pronouns}</Text>
          <Text className='medium'>{`${jobTitle} | ${company}`}</Text>

          <Box className='icons-section'>
            {socials &&
              socials.map(
                (social: any, index: number) =>
                  social && (
                    <Button
                      className='icon'
                      onClick={social.onClickSocial}
                      key={index}
                    >
                      <Icon
                        className='primary'
                        location={social.socialLogo}
                        content={{ label: social.socialLogo }}
                      />
                    </Button>
                  ),
              )}
          </Box>
        </Box>
      </Box>
      <Box className='bottom-section'>
        <Button className='primary' onClick={callbacks.onSubmit}>
          {buttonText}
        </Button>
      </Box>
    </MiniProfileContainer>
  );
};

/* @ts-ignore */
MiniProfile.propTypes = MiniProfilePropTypes;
MiniProfile.defaultProps = {
  className: 'primary',
  settings: {
    displayName: 'Armon Dadgar',
    profilePicture: '/mock/avatar.jpg',
    pronouns: 'him',
    jobTitle: 'Engineer',
    company: 'Ponzy',
    socials: [
      {
        socialName: 'linkedin',
        socialLogo: '/icons/linkedin-white.svg',
        onClickSocial: (...args: any[]) => {
          console.log('onClickSocial1 fired', args);
        },
      },
      {
        socialName: 'github',
        socialLogo: '/icons/github-white.svg',
        onClickSocial: (...args: any[]) => {
          console.log('onClickSocial2 fired', args);
        },
      },
      {
        socialName: 'twitter',
        socialLogo: '/icons/twitter-white.svg',
        onClickSocial: (...args: any[]) => {
          console.log('onClickSocial3 fired', args);
        },
      },
    ],
    callbacks: {
      onSubmit: (...args: any[]) => {
        console.log('onSubmit fired', args);
      },
      onClose: (...args: any[]) => {
        console.log('onClose fired', args);
      },
    },
  },
};

const atoms = [
  {
    atom: Heading,
    description: 'Heading name',
    settings: HeadingSettings,
    atomVariant: '&h4',
  },
  {
    atom: Text,
    description: 'Text pronoums',
    settings: TextSettings,
    atomVariant: '&small',
  },
  {
    atom: Text,
    description: 'Text job title',
    settings: TextSettings,
    atomVariant: '&medium',
  },
  {
    atom: Button,
    description: 'Button image',
    settings: ButtonSettings,
    atomVariant: '&image',
  },
  {
    atom: Button,
    description: 'Button primary',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    description: 'Button social icon',
    settings: ButtonSettings,
    atomVariant: '&icon',
  },
  {
    atom: Button,
    description: 'Button close cross',
    settings: ButtonSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Icon,
    description: 'Icon socials',
    settings: IconSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    description: 'Icon x-circle',
    settings: IconSettings,
    atomVariant: '&secondary',
  },
];

export const MiniProfileSettings: ISettings = {
  states: [State.default],
  variants: [
    {
      className: '&primary',
      description: 'Default',
      include: [
        CmsConfig.frame,
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.padding,
      ],
      atoms,
      sections: [
        {
          className: 'top-bar-section',
          description: 'Section Top bar',
          include: [
            CmsConfig.frame,
            CmsConfig.flexbox,
            CmsConfig.vmargin,
            CmsConfig.fill,
          ],
        },
        {
          className: 'middle-section',
          description: 'Section Photo and text',
          include: [
            CmsConfig.frame,
            CmsConfig.flexbox,
            CmsConfig.vmargin,
            CmsConfig.padding,
          ],
        },
        {
          className: 'all-text-section',
          description: 'Section all text',
          include: [CmsConfig.frame, CmsConfig.flexbox, CmsConfig.vmargin],
        },
        {
          className: 'icons-section',
          description: 'Section icons',
          include: [CmsConfig.frame, CmsConfig.flexbox, CmsConfig.vmargin],
        },
        {
          className: 'bottom-section',
          description: 'Section Bottom',
          include: [CmsConfig.flexbox, CmsConfig.vmargin, CmsConfig.padding],
        },
      ],
    },
  ],
};
