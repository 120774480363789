import styled from 'styled-components';
export const Navigation365Container = styled.div``;
export const StyledSectionLogo = styled.div``;

export const StyledSectionLinks = styled.div`
  a {
    white-space: nowrap;
  }
`;

export const StyledSectionLogoInvisible = styled.div`
  visibility: hidden;
`;
