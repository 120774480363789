import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import {
  StyledCardContainer,
  StyledScrollButtonContainer,
  StyledCarousel,
} from './Carousel.css';
import classNames from 'classnames';
import { Arrow } from '../../Atoms/Arrow/Arrow';

const CarouselPropTypes = {
  children: PropTypes.any.isRequired,
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number,
  className: PropTypes.string,
  scrollBeginning: PropTypes.bool,
};

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export type CarouselType = InferProps<typeof CarouselPropTypes>;

export const Carousel: React.FC<CarouselType> = ({
  children,
  columns = 3,
  rows = 1,
  className,
  scrollBeginning = true,
}) => {
  const padding = 32;
  const [offset, setOffset] = useState(0);
  const [scrollBackEnabled, setScrollBackEnabled] = useState(false);
  const [scrollForwardEnabled, setScrollForwardEnabled] = useState(false);
  const [childrenCount, setChildrenCount] = useState(0);
  const [childrenColumns, setChildrenColumns] = useState(0);
  const [rowsCurrent, setRowsCurrent] = useState(1);
  const [columnsCurrent, setColumnsCurrent] = useState(columns);
  const [cardWidth, setCardWidth] = useState(1);

  const windowWidth = useWindowSize();
  const scrollContainer = useRef(null) as any;

  const scroll = useCallback(
    (dx: number) => {
      const container = scrollContainer.current as any;
      let newOffset = offset + dx;

      const scrolledBack = newOffset <= 0;
      setScrollBackEnabled(!scrolledBack);
      if (scrolledBack) {
        newOffset = 0;
      }
      const scrolledForward = newOffset >= childrenColumns - columnsCurrent;
      setScrollForwardEnabled(!scrolledForward);
      if (scrolledForward) {
        newOffset = childrenColumns - columnsCurrent;
      }
      setOffset(newOffset);
      container.scrollTo({
        left: newOffset * cardWidth - ((newOffset * cardWidth) % cardWidth),
        behavior: 'smooth',
      });
    },
    [cardWidth, childrenColumns, columnsCurrent, offset],
  );

  useEffect(() => {
    setRowsCurrent(rows || 1);
  }, [rows]);

  useEffect(() => {
    if (columns >= 3 && window.innerWidth < 1400) {
      setColumnsCurrent(2);
    } else if (columns >= 2 && window.innerWidth < 768) {
      setColumnsCurrent(1);
    }
  }, [columns]);

  useEffect(() => {
    const firstItem = scrollContainer.current?.firstChild;
    setCardWidth(
      firstItem ? firstItem.getClientRects()[0].width + padding : 200,
    );

    const childrenCount = children.filter(Boolean).length;
    setChildrenCount(childrenCount);
    if (childrenCount > 0 && childrenCount <= columnsCurrent) {
      setRowsCurrent(1);
    }

    const childrenColumns = Math.ceil(childrenCount / rowsCurrent);
    setChildrenColumns(childrenColumns);
    setScrollForwardEnabled(childrenColumns > columnsCurrent);
    scrollBeginning && scroll(0);
  }, [
    children,
    columnsCurrent,
    children.length,
    rowsCurrent,
    cardWidth,
    scroll,
    windowWidth,
    scrollBeginning,
  ]);

  useEffect(() => {
    let timer: any = null;

    function hashChangeHandler() {
      clearTimeout(timer);
      timer = setTimeout(debounced, 100);
    }

    function debounced() {
      const container = scrollContainer.current as any;
      if (container) {
        setScrollBackEnabled(container.scrollLeft > 0);
        setScrollForwardEnabled(
          container.scrollLeft < container.scrollWidth - container.offsetWidth,
        );
        setOffset(Math.round(container.scrollLeft / cardWidth));
      }
    }

    const container = scrollContainer.current as any;
    if (windowWidth >= 1200) {
      container.addEventListener('scroll', hashChangeHandler);
    }

    return () => {
      clearTimeout(timer);
      container.removeEventListener('scroll', hashChangeHandler);
    };
  }, [cardWidth, windowWidth]);

  return (
    <StyledCarousel className={classNames('Grid', className)}>
      <StyledScrollButtonContainer className='left'>
        <Arrow
          variant='back'
          disabled={!scrollBackEnabled}
          onClick={() => {
            scroll(-1);
          }}
        />
      </StyledScrollButtonContainer>

      <StyledScrollButtonContainer className='right'>
        <Arrow
          variant='forward'
          disabled={!scrollForwardEnabled}
          onClick={() => {
            scroll(1);
          }}
        />
      </StyledScrollButtonContainer>

      <StyledCardContainer
        columns={columnsCurrent}
        childrenCount={childrenCount}
        childrenColumns={childrenColumns}
        ref={scrollContainer}
      >
        {children}
      </StyledCardContainer>
    </StyledCarousel>
  );
};
