import React from 'react';
import EventDetailPage from '../pages/Events/Detail';
import {
  BASE_PATH,
  EVENT_DETAIL_BASE_PATH,
  EVENT_PATH,
  ON_DEMAND_DETAIL_PATH,
  ON_DEMAND_OVERVIEW_PATH,
  RESOURCES_PATH,
  FORM_PATH,
} from '../constants/routes.constants';
import EventPage from '../pages/Events/Overview';
import HomePage from '../pages/Home';
import { Route, Switch } from 'react-router-dom';
import Error404Page from '../pages/Error/Error404';
import OnDemandOverviewPage from '../pages/OnDemand/Overview';
import OnDemandDetailPage from '../pages/OnDemand/Detail';
import ResourcesPage from '../pages/Resources';
import FormPage from '../pages/Form';

const RouteTree = () => (
  <Switch>
    <Route exact path={BASE_PATH}>
      {/* <Redirect to={EVENT_PATH} /> */}
      <HomePage />
    </Route>
    <Route exact path={EVENT_PATH}>
      <EventPage />
    </Route>
    <Route exact path={EVENT_DETAIL_BASE_PATH}>
      <EventDetailPage />
    </Route>
    <Route exact path={ON_DEMAND_OVERVIEW_PATH}>
      <OnDemandOverviewPage />
    </Route>
    <Route exact path={ON_DEMAND_DETAIL_PATH}>
      <OnDemandDetailPage />
    </Route>
    <Route exact path={RESOURCES_PATH}>
      <ResourcesPage />
    </Route>
    <Route exact path={FORM_PATH}>
      <FormPage
        src="https://tally.so/embed/waQNAB?alignLeft=1&hideTitle=1&transparentBackground=1"
        title="Event Inquiry"
      />
    </Route>
    <Route path="" component={Error404Page} />
  </Switch>
);

export default RouteTree;
