import { css } from 'styled-components';

interface ShapeProps {
  color: string;
}

export default css<ShapeProps>`
  width: 16px;
  height: 0;
  margin-right: 3px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 8px solid ${({ color }) => color};
  display: block;
`;
