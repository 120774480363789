import React from 'react';
import { SubMenu } from '@dexper.events/product-atomic-ui';
import { settings } from './settings';
import { ISubMenuContainer } from './types';

export const SubMenuContainer = ({
  className,
  setEventTimeFilter,
  eventTimeFilter,
}: ISubMenuContainer) => {
  function onFilterByEventTime(selection: string) {
    setEventTimeFilter(selection);
  }
  return (
    <SubMenu
      className={className}
      settings={settings(onFilterByEventTime, eventTimeFilter)}
    />
  );
};
