import styled from 'styled-components';
import { Button } from '../../Atoms/Button/Button';

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCounter = styled.div`
  text-align: center;
  width: 100px;
`;

export const ImgButtonWrapperRight = styled(Button)`
  transform: rotate(270deg) scaleX(-1);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease;
`;

export const ImgButtonWrapperLeft = styled(Button)`
  transform: rotate(90deg) scaleX(-1);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease;
`;
