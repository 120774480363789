export enum CmsConfig {
  frame /* border-radius, width, height */,
  border /* border, border-radius, shadow */,
  text,
  textStyle,
  brandText,
  brandHeading,
  heading,
  color,
  altColor,
  fill /* background */,
  padding,
  vmargin /* margin-top/-bottom */,
  hmargin /* margin-left/-right */,
  effects,
  flexbox /* flexbox props (align-items, justify-content, flex-direction…) */,
  grid /* grid props */,
  hover /* on hover props */,
  cursor /* pointer on active props */,
  placeholder,
  transform,
  linkStyle,
  box /* ex: red 0px 1px 7px */,
  display,
}

export enum CmsTypes {
  string,
  /* Initially, everything can be a string and later add more types */
  number,
  boolean,
  size /* For example '16px', '50%' or '1.8rem' */,
  color /* for example #e60000 or rgba(0, 0, 0, 0.19) */,
  solidColor,
  text /* Brand text */,
  textStyle /* Text styles */,
  heading /* Heading styles */,
  headingStyle /* Heading styles */,
  image /* An url */,
  fontWeight /* List of fonts weights */,
  select,
}
