import styled from 'styled-components';

/* @ts-ignore */
export const BadgeContainer = styled.span``;

export const LiveDot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: var(--alt-color);
  display: block;
`;
