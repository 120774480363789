import { TypedPath } from '@dexper.events/dexper-datamodel/lib/src/DataMappings';
import {
  DocumentReference,
  onSnapshot,
  Query,
  CollectionReference,
  Unsubscribe,
  DocumentData,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  getTypedReference,
  ReferenceType,
} from '../../../firebase/getTypedReference';
import { usePersist } from '../../storage/usePersist';
import { GenericWithDocumentID } from '../../../../types';

const useGetDocumentOnSnapshot = <T>(
  mapping: TypedPath<T>,
  persist?: boolean
) => {
  const reference:
    | DocumentReference<T>
    | CollectionReference<T>
    | Query<T>
    | undefined = getTypedReference(mapping, ReferenceType.Document);
  const path = mapping.path;
  const [storageResponseData, setStorageResponseData] = usePersist(path);
  const [responseData, setResponseData] =
    useState<GenericWithDocumentID<T>>(storageResponseData);
  useEffect(() => {
    let unsubscribe: Unsubscribe;
    (async () => {
      unsubscribe = onSnapshot(
        reference as Query<T>,
        (document: DocumentData) => {
          const data: GenericWithDocumentID<T> = document.data();
          data.id = document.id;
          setResponseData(data);
          if (process.env.REACT_APP_DEBUG === 'true') {
            // eslint-disable-next-line no-console
            console.info('Getting document from firebase:', data);
          }
          if (persist) {
            setStorageResponseData(data);
          }
        }
      );
    })();
    return () => unsubscribe();
  }, [path, persist, setStorageResponseData]);
  return [responseData] as const;
};

export default useGetDocumentOnSnapshot;
