import styled from 'styled-components';

interface T {
  htmlFor?: string;
}

export const TextContainer = styled.p<T>`
  margin: 0px 0;
`;
export const TextContainerLabel = styled.label<T>`
  margin: 0px 0;
`;
