import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import parsedConfig from '../../config/firebase.config';
import { DataMappings } from '@dexper.events/dexper-datamodel';
import { CLOUD_FUNCTION_REGION } from '../../constants/general.constants';

export const env = process.env;
export const app = initializeApp(parsedConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, CLOUD_FUNCTION_REGION);
export const dexperFirestore = new DataMappings.DexperFirestore();
