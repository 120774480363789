import { LOGO_HEADER } from './../../constants/storage.constants';
import { INavigation365Settings } from './types';

const getClassName = (location: string) => {
  const pathArray = window.location.pathname.split('/');
  let path1 = pathArray[1];
  const path2 = location.split('/')[1];
  if (!path1) {
    path1 = '';
  }
  return path1 === path2 ? 'active' : 'primary';
};

export const settings = () => {
  const settings: INavigation365Settings = {
    links: [
      {
        className: getClassName('/'),
        anchorType: 'internal',
        targetBlank: false,
        callback: undefined,
        label: 'Home',
        location: '/',
        children: undefined,
      },
      {
        className: getClassName('/events'),
        anchorType: 'internal',
        targetBlank: false,
        callback: undefined,
        label: 'Events',
        location: '/events',
        children: undefined,
      },
      {
        className: getClassName('/on-demand'),
        anchorType: 'internal',
        targetBlank: false,
        callback: undefined,
        label: 'On Demand',
        location: '/on-demand',
        children: undefined,
      },
      {
        className: getClassName('/resources'),
        anchorType: 'internal',
        targetBlank: false,
        callback: undefined,
        label: 'Resources',
        location: '/resources',
        children: undefined,
      },
    ],
    logo: {
      content: {
        label: 'Dexper',
      },
      location: LOGO_HEADER,
      className: 'primary',
    },
    logoLink: {
      location: '/',
      anchorType: 'internal',
      targetBlank: false,
      className: 'header-logo-link',
      callback: () => {
        // callback
      },
    },
    callbacks: {},
  };
  return settings;
};
