import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import {
  PlayButton,
  StyledVideo,
  StyledVideoThumb,
  RichTextWrapped,
} from './VideoThumb.css';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { RichTextSettings } from '../../Atoms/RichText/RichText';
import {
  Category,
  CategoryType,
  CategorySettings,
} from '../../Atoms/Category/Category';
import ExampleFrame from './assets/example-frame.jpg';

export const VideoThumbStory = {
  className: 'primary',
  thumbnail: ExampleFrame,
  categories: [
    {
      className: 'primary',
      shape: 'circle',
      children: 'Fun',
      color: '#FF8330',
    },
    {
      className: 'primary',
      shape: 'circle',
      children: 'Dexper',
      color: '#1881FF',
    },
  ],
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  videoId: 'Default',
  seriesInfo: 'Lorem ipsum dolor sit amet',
  onClick: () => {
    console.log('Click');
  },
};
export interface VideoThumbTypes {
  thumbnail: string;
  categories: Array<CategoryType>;
  title: string;
  description?: string;
  videoId: string;
  onClick: (a: string) => void;
  skeleton?: boolean;
  seriesInfo?: string;
  className?: string;
}

export const VideoThumb: React.FC<VideoThumbTypes> = ({
  thumbnail,
  categories,
  title,
  description,
  videoId,
  onClick,
  skeleton,
  seriesInfo,
  className,
}) => {
  function goToVideo(videoId: string) {
    onClick(videoId);
  }

  return skeleton ? (
    <div>loading...</div>
  ) : (
    <>
      <StyledVideoThumb
        className={classNames('VideoThumb', className)}
        onClick={() => goToVideo(videoId)}
      >
        <PlayButton className='section-thumbnail'>
          <StyledVideo src={thumbnail} />
        </PlayButton>
        {seriesInfo && (
          <div className='section-series'>
            <Text className='primary'>{seriesInfo}</Text>
          </div>
        )}
        <div className='section-categories'>
          {categories.map((category: any, index: number) => {
            return (
              <Category
                className={category.className}
                shape={category.shape}
                color={category.color}
                key={index}
              >
                {category.children}
              </Category>
            );
          })}
        </div>
        <div className='section-title'>
          <Heading tag='h5' className='primary'>
            {title}
          </Heading>
        </div>
        <div className='section-description'>
          {description && (
            <RichTextWrapped className='primary'>{description}</RichTextWrapped>
          )}
        </div>
      </StyledVideoThumb>
    </>
  );
};

const atoms = [
  {
    atom: Category,
    name: 'Category~primary',
    description: 'Category primary',
    settings: CategorySettings,
    atomVariant: '&primary',
  },
  {
    atom: RichTextWrapped,
    name: 'RichText~primary',
    description: 'RichText primary',
    settings: RichTextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
];
const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.flexbox,
];

export const VideoThumbSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  sections: [
    {
      className: 'section-series',
      description: 'Section series',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-thumbnail',
      description: 'Section thumbnail',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-categories',
      description: 'Section categories',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-description',
      description: 'Section description',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-title',
      description: 'Section title',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'VideoThumb primary',
    },
  ],
};

VideoThumb.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  skeleton: PropTypes.bool,
};

VideoThumb.defaultProps = {
  description: '',
  onClick: () => '',
};
