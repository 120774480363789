import React from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { Text } from '../../Atoms/Text/Text';
import { Button } from '../../Atoms/Button/Button';
import { StyledSection, StyledSectionCategories } from './CardSpeaker.css';
import { Category } from '../../Atoms/Category/Category';

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;

  switch (variation) {
    case 'primary':
      return (
        <>
          <StyledSection className='section-image'>
            <Button
              className='secondary'
              alt='speaker'
              background={settings.speakerImg}
              onClick={settings.callbacks.onClick}
            ></Button>
          </StyledSection>

          <StyledSection className='section-text'>
            <Heading tag={settings.titleType} className='primary'>
              {settings.text.title}
            </Heading>
            <Text className='primary'>{settings.text.description} </Text>
            <StyledSectionCategories>
              {settings?.categories?.map((category: any, index: number) => {
                return (
                  <Category
                    className={category.className}
                    shape={category.shape}
                    color={category.color}
                    key={index}
                  >
                    {category.label}
                  </Category>
                );
              })}
            </StyledSectionCategories>
          </StyledSection>

          <StyledSection className='section-button'>
            <Button
              className='primary'
              image={settings.buttonIcon}
              alt='Info'
              onClick={settings.callbacks.onInfo}
            />
          </StyledSection>
        </>
      );
      break;
    default:
      return <p>No CardSpeaker variation selected</p>;
  }
};
