import React, { useEffect, useState, useContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { Header } from './SelectBoxAtom.css';
import { State } from '../../../definitions/States';
import classNames from 'classnames';

export const SelectBoxAtomStory = {
  onClick: () => console.log('this is a console log '),
  onKeyUp: (e: any) => console.log('this is a console log '),
  onKeyDown: (e: any) => console.log('this is a console log '),
  onBlur: () => console.log('this is a console log '),
  onMouseOut: () => console.log('this is a console log '),
  isOpen: true,
  className: 'primary',
  error: true,
  selectedOption: '',
  openIcon: '/assets/icons/arrow-down.svg',
  children: '',
};

export interface SelectBoxAtomTypes {
  onClick: () => void;
  onKeyUp: (e: any) => void;
  onKeyDown: (e: any) => void;
  onBlur: () => void;
  onMouseOut: () => void;
  isOpen?: boolean;
  className?: string;
  error?: boolean;
  selectedOption?: string;
  openIcon: string;
  children?: React.ReactNode;
}

export const SelectBoxAtom: React.FC<SelectBoxAtomTypes> = ({
  onClick,
  onKeyUp,
  onKeyDown,
  onBlur,
  onMouseOut,
  isOpen,
  className,
  error,
  selectedOption,
  openIcon,
  children,
}) => {
  return (
    <Header
      className={classNames('SelectBoxAtom', className)}
      onClick={onClick}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onMouseOut={onMouseOut}
      isOpen={isOpen}
      error={error}
    >
      <div>{selectedOption}</div>
      {children && children}
    </Header>
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.text,
  CmsConfig.placeholder,
];

export const SelectBoxAtomSettings: ISettings = {
  states: [State.default, State.active, State.hover, State.disabled],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'SelectBoxAtom Primary',
    },
    {
      className: '&secondary',
      description: 'SelectBoxAtom Secondary',
    },
  ],
};
