import styled from 'styled-components';

const Styled = {
  article: styled.article`
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    min-height: 100%;
  `,
  header: styled.header``,
  footer: styled.footer``,
};

export default Styled;
