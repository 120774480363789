import { OnBackgroundPing } from '@dexper.events/dexper-datamodel';
import { httpsCallable } from 'firebase/functions';
import { OnBackgroundPingType } from '../../../types';
import { functions } from '../../firebase';

const requestOnBackgroundPing: OnBackgroundPing['name'] = 'onBackgroundPing';

const useOnBackgroundPing = async (data: OnBackgroundPingType) => {
  if (!data) throw new Error('No data provided');
  try {
    const callOnBackgroundPing = httpsCallable(
      functions,
      requestOnBackgroundPing,
      {}
    );

    return await callOnBackgroundPing(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    return console.log('useOnBackgroundPing error occurred:', error);
  }
};

export default useOnBackgroundPing;
