import { currentLanguage } from '../../../../../config/language.config';
import { ContextProps } from '../../../../../context/GlobalContext';

export const settings = (globalData: Partial<ContextProps>) => {
  const eventTypes = globalData?.config?.eventTypes;
  const categories = globalData?.categories;
  const categoriesParent = categories?.categoriesParent;
  if (!categories || !eventTypes) {
    return;
  }
  const getCategoryParentTitle = (parentKey: string) => {
    let categoryParentTitle = '';
    const categoryParent = categoriesParent?.find(({ key }) => {
      return key === parentKey;
    });
    if (categoryParent) {
      const {
        language: {
          [currentLanguage]: { label },
        },
      } = categoryParent;
      categoryParentTitle = label;
    }
    return categoryParentTitle;
  };
  const visibleCategories = categories.categories;
  const getCategoryData = () => {
    const categoryData2 = {} as any;
    visibleCategories.forEach((category: any) => {
      categoryData2[category.parent] = {
        ...categoryData2[category.parent],
        [category.key]: category.language[currentLanguage]?.label,
        _parent: getCategoryParentTitle(category.parent),
      };
    });
    return categoryData2;
  };
  const getCategoryToggleGroups = () => {
    const categoryToggleGroups = {} as any;
    visibleCategories.forEach((category: any) => {
      if (!categoryToggleGroups[category.parent]) {
        categoryToggleGroups[category.parent] = [];
      }
      categoryToggleGroups[category.parent].push({
        key: category.key,
      });
    });
    return categoryToggleGroups;
  };
  return {
    translation: {
      title: 'Filter Events',
      options: {
        eventType: Object.entries(eventTypes).reduce(
          (accumulator, [eventTypeKey, eventTypeValue]) => ({
            ...accumulator,
            [eventTypeKey]: eventTypeValue?.language[currentLanguage]?.label,
          }),
          { _parent: 'Event type' }
        ),
        ...getCategoryData(),
      },
      saveBtn: 'SAVE',
      resetBtn: 'Reset Filter',
    },
    toggleGroups: {
      eventType: Object.keys(eventTypes).map((key: string) => ({
        key,
      })),
      ...getCategoryToggleGroups(),
    },
  };
};
