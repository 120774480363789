import styled, { css } from 'styled-components';

export const TooltipTextStyle = styled.div`
  > * {
    position: absolute;
    left: -50px;
    top: 25px;
    width: 180px;
    padding: 10px;

    opacity: 0;
    transition: all 250ms ease;
    pointer-events: none;
  }
`;

export const TooltipButtonStyle = styled.button`
  position: relative;
  outline-color: transparent;

  :focus-visible {
    outline: 2px solid #00a1f1;
    outline-offset: 2px;
  }

  :hover div > * {
    opacity: 1;
    top: 21px;
  }
`;

export const ImgStyle = styled.svg`
  width: 1em;
  height: 1em;
`;
