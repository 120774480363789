import { Event365 } from '@dexper.events/dexper-datamodel';
import { GenericWithDocumentID } from '../../../../../types';
import { FilterOption } from '../../containers/FilterList/types';

export function filterByCategory(
  filterData: GenericWithDocumentID<Event365>[],
  selectedFilters: FilterOption[]
) {
  let filteredData: GenericWithDocumentID<Event365>[] = filterData;
  if (selectedFilters.length > 0) {
    selectedFilters.forEach((filter: FilterOption) => {
      filteredData = filteredData.filter(
        (event: GenericWithDocumentID<Event365>) => {
          return event.categories?.includes(filter.value);
        }
      );
    });
  }
  return filteredData;
}
