import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
import { Link, LinkSettings } from '../../Atoms/Link/Link';
import * as SC from './SubMenu.css';

export const submenuSettings = {
  navigation: [
    {
      label: 'profile settings',
      callback: () => console.log('profile settings... triggered!'),
      active: true,
      anchorType: 'internal',
      location: '/route',
    },
    {
      label: 'FAQ & support',
      callback: () => console.log('FAQ & support... triggered!'),
      anchorType: 'internal',
      location: '/route',
    },
    {
      label: 'pivacy & legal terms',
      callback: () => console.log('pivacy & legal terms... triggered!'),
      anchorType: 'internal',
      location: '/route',
    },
  ],
};

export const SubMenuStory = {
  settings: submenuSettings,
  className: 'primary',
};

const SubMenuPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    navigation: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        callback: PropTypes.func.isRequired,
        active: PropTypes.bool,
        location: PropTypes.string,
        anchorType: PropTypes.oneOf(['internal', 'external']),
      }),
    ),
  }),
};

export type SubMenuTypes = InferProps<typeof SubMenuPropTypes>;

export const SubMenu: React.FC<SubMenuTypes> = ({ className, settings }) => {
  return (
    <SC.Nav>
      <SC.DesktopNav className={classNames('SubMenu', className)}>
        <PrintVariations
          className={className}
          settings={settings}
        ></PrintVariations>
      </SC.DesktopNav>
    </SC.Nav>
  );
};

const atoms = [
  {
    atom: Link,
    name: 'Link~primary',
    description: 'Link primary',
    settings: LinkSettings,
    atomVariant: '&primary',
  },
  {
    atom: Link,
    name: 'Link~secondary',
    settings: LinkSettings,
    description: 'Link secondary',
    atomVariant: '&secondary',
  },
];

export const SubMenuSettings: ISettings = {
  states: [State.default],
  include: [CmsConfig.fill, CmsConfig.frame, CmsConfig.hmargin],
  atoms: atoms,
  sections: [
    {
      className: 'section-underline',
      description: 'Section underline',
      include: [
        CmsConfig.fill,
        CmsConfig.hmargin,
        CmsConfig.vmargin,
        CmsConfig.padding,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'SubMenu primary',
    },
  ],
};

SubMenu.defaultProps = {
  settings: {
    navigation: [
      {
        label: 'Tab X',
        callback: () => console.log(' Tab X... triggered!'),
        active: true,
        location: '#',
      },
    ],
  },
};

SubMenu.propTypes = SubMenuPropTypes;
