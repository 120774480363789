import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from '@dexper.events/old-atomic-ui';
import { useIntl } from 'react-intl';
import { CookieConsentWrapper } from './styles.css';

const CookieConsentComponent = ({ debug }: { debug: boolean }) => {
  const intl = useIntl();

  return (
    <CookieConsentWrapper>
      <CookieConsent
        debug={debug}
        location="bottom"
        buttonText={intl.formatMessage({
          id: 'legalBanner.CookieConsentBtn',
        })}
        cookieName="remoteCookie"
        style={{
          fontSize: '16px',
          fontWeight: 300,
          background: '#fff',
          color: '#141C28',
          fontFamily: 'Dexper',
        }}
        buttonStyle={{
          borderRadius: '4px',
          height: '48px',
          borderWidth: 0,
          borderColor: '#ffffff00',
          color: '#ffffffff',
          lineHeight: '140%',
          letterSpacing: '0.0025em',
          fontFamily: 'Dexper',
          display: 'flex',
          fontSize: 16,
          fontWeight: 700,
          alignItems: 'center',
          justifyContent: 'center',
          background: '#1BC492',
          padding: '13px 24px',
          cursor: 'pointer',
          margin: 0,
        }}
        expires={150}
      >
        {intl.formatMessage({
          id: 'legalBanner.cookiesText',
        })}
        <Link location="/#" anchorType="external">
          {intl.formatMessage({
            id: 'legalBanner.privacyPolicy',
          })}
        </Link>
      </CookieConsent>
    </CookieConsentWrapper>
  );
};

CookieConsentComponent.defaultProps = {
  debug: false,
};

export default CookieConsentComponent;
