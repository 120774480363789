import { CmsConfig, CmsTypes } from '../definitions/CmsConfig';
import { CssRules, ICmsProperties } from '../definitions/ICmsProperties';

export const CmsProperties: ICmsProperties = {
  [CmsConfig.frame]: {
    label: 'Frame',
    properties: [
      {
        label: 'Border radius (tl tr br bl)',
        property: 'border-radius',
        type: CmsTypes.size,
        value: '',
      },
      {
        label: 'Width',
        property: 'width',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Max Width',
        property: 'max-width',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Height',
        property: 'height',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Min Height',
        property: 'min-height',
        type: CmsTypes.string,
        value: '',
        // calculatedValue : label
      },
    ],
    /*   process: (props: CssRules) => ({
      '--height': props.height,
    }), */
  },
  [CmsConfig.border]: {
    label: 'Stroke',
    properties: [
      {
        label: 'Border width',
        property: 'border-width',
        type: CmsTypes.size,
        value: '',
      },
      {
        label: 'Border color',
        property: 'border-color',
        type: CmsTypes.solidColor,
        value: '',
      },
      {
        label: 'Border style',
        property: 'border-style',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.brandText]: {
    label: 'Text',
    properties: [
      {
        label: 'Name',
        property: 'name',
        type: CmsTypes.text,
        value: '',
      },
      {
        label: 'Font family',
        property: 'font-family',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Font size',
        property: 'font-size',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Font weight',
        property: 'font-weight',
        type: CmsTypes.select,
        value: '',
        oneOf: {
          100: '100 Thin',
          200: '300 Light',
          300: '350 Book',
          400: '400 Normal/Regular',
          500: '500 Medium',
          600: '600 Semi Bold/Demi Bold',
          700: '700 Bold',
          800: '800 Extra Bold/Ultra Bold',
          900: '900 Black/Heavy',
        },
      },
      {
        label: 'Font style',
        property: 'font-style',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Line height',
        property: 'line-height',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Letter spacing',
        property: 'letter-spacing',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.brandHeading]: {
    label: 'Heading',
    properties: [
      {
        label: 'Name',
        property: 'name',
        type: CmsTypes.heading,
        value: '',
      },
      {
        label: 'Font family',
        property: 'font-family',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Font size',
        property: 'font-size',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Font weight',
        property: 'font-weight',
        type: CmsTypes.select,
        value: '',
        oneOf: {
          100: '100 Thin',
          200: '300 Light',
          300: '350 Book',
          400: '400 Normal/Regular',
          500: '500 Medium',
          600: '600 Semi Bold/Demi Bold',
          700: '700 Bold',
          800: '800 Extra Bold/Ultra Bold',
          900: '900 Black/Heavy',
        },
      },
      {
        label: 'Font style',
        property: 'font-style',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Line height',
        property: 'line-height',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Letter spacing',
        property: 'letter-spacing',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.text]: {
    label: 'Text',
    properties: [
      {
        label: 'Text style',
        property: '_text',
        type: CmsTypes.textStyle,
        value: '',
      },
      {
        label: 'Color',
        property: 'color',
        type: CmsTypes.solidColor,
        value: '',
      },
      {
        label: 'Text decoration',
        property: 'text-decoration',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.heading]: {
    label: 'Heading',
    properties: [
      {
        label: 'Heading type',
        property: '_heading',
        type: CmsTypes.headingStyle,
        value: '',
      },
    ],
  },
  [CmsConfig.color]: {
    label: 'Color',
    properties: [
      {
        label: 'Text color',
        property: 'color',
        type: CmsTypes.solidColor,
        value: '',
        colorId: '',
      },
    ],
  },
  [CmsConfig.altColor]: {
    label: 'Alternative color',
    properties: [
      {
        label: 'Color',
        property: '--alt-color',
        type: CmsTypes.solidColor,
        value: '',
        colorId: '',
      },
    ],
  },
  [CmsConfig.linkStyle]: {
    label: 'Link style',
    properties: [
      {
        label: 'Link color',
        property: '--link-color',
        type: CmsTypes.solidColor,
        value: '',
        colorId: '',
      },
      {
        label: 'Link hover color',
        property: '--link-hover-color',
        type: CmsTypes.solidColor,
        value: '',
        colorId: '',
      },
      {
        label: 'Text decoration',
        property: '--link-decoration',
        type: CmsTypes.string,
        value: '',
        colorId: '',
      },
    ],
  },
  /*   [CmsConfig.fill]: {
    label: 'Fill',
    properties: [
      {
        label: 'Color',
        property: '_background1',
        type: CmsTypes.color,
      },
      {
        label: 'Second gradient color',
        property: '_background2',
        type: CmsTypes.color,
      },
      {
        label: 'Gradient direction',
        property: '_gradientdirection',
        type: CmsTypes.string,
      },
    ],
    process: (props: CssRules) => {
      return {
        background: props._background2
          ? `linear-gradient(${props._gradientdirection || 0}, ${
              props._background1
            }, ${props._background2})`
          : props._background1,
      };
    },
  }, */
  [CmsConfig.fill]: {
    label: 'Fill',
    properties: [
      {
        label: 'Color',
        property: 'background',
        type: CmsTypes.color,
        value: '',
      },
    ],
  },

  [CmsConfig.effects]: {
    label: 'Effects',
    properties: [
      {
        /* Or just one string value? */
        label: 'Shadow X',
        property: 'shadow-x',
        type: CmsTypes.number,
        value: '',
      },
      {
        label: 'Shadow Y',
        property: 'shadow-y',
        type: CmsTypes.number,
        value: '',
      },
      {
        label: 'Shadow Blur',
        property: 'shadow-blur',
        type: CmsTypes.number,
        value: '',
      },
      {
        label: 'Shadow spread',
        property: 'shadow-spread',
        type: CmsTypes.number,
        value: '',
      },
      {
        label: 'Shadow Color',
        property: 'shadow-color',
        type: CmsTypes.solidColor,
        value: '',
      },
      {
        label: 'Box Shadow',
        property: 'box-shadow',
        type: CmsTypes.number,
        value: '',
      },
    ],

    process: (props: CssRules) => ({
      // console.log('props : ', props);
      'box-shadow': `${props['shadow-x']} ${props['shadow-y']} ${props['shadow-blur']} ${props['shadow-spread']} ${props['shadow-color']}`,
    }),

    //  process: () => {
    // console.log('props : ', props);
    /*   return {
        label: 'Box Shadow',
        property: 'box-shadow',
        type: CmsTypes.string,
        value: '10px 10px 5px lightblue inset',
      }; */

    //  },
  },
  [CmsConfig.padding]: {
    label: 'Layout',
    /* Item spacing… */
    properties: [
      {
        label: 'Padding (top right bottom left)',
        property: 'padding',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Align self',
        property: 'align-self',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Flex',
        property: 'flex',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Order',
        property: 'order',
        type: CmsTypes.number,
        value: '',
      },
    ],
  },
  [CmsConfig.vmargin]: {
    label: 'Vertical margin',
    properties: [
      {
        label: 'Margin top',
        property: 'margin-top',
        type: CmsTypes.size,
        value: '',
      },
      {
        label: 'Margin bottom',
        property: 'margin-bottom',
        type: CmsTypes.size,
        value: '',
      },
    ],
  },
  [CmsConfig.hmargin]: {
    label: 'Horizontal margin',
    properties: [
      {
        label: 'Margin left',
        property: 'margin-left',
        type: CmsTypes.size,
        value: '',
      },
      {
        label: 'Margin right',
        property: 'margin-right',
        type: CmsTypes.size,
        value: '',
      },
    ],
  },
  [CmsConfig.flexbox]: {
    label: 'Flexbox',
    properties: [
      {
        label: 'Display (flex)',
        property: 'display',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Gap',
        property: 'gap',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Direction',
        property: 'flex-direction',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Justify content',
        property: 'justify-content',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Align items',
        property: 'align-items',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Align content',
        property: 'align-content',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Flex Wrap',
        property: 'flex-wrap',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.grid]: {
    label: 'Grid',
    properties: [
      {
        label: 'Display',
        property: 'display',
        type: CmsTypes.string,
        value: '',
      },
      {
        label: 'Number of columns',
        property: 'columns',
        type: CmsTypes.number,
        value: '',
      },
      {
        label: 'Horizontal gap size',
        property: 'column-gap',
        type: CmsTypes.size,
        value: '',
      },
      {
        label: 'Vertical gap size',
        property: 'row-gap',
        type: CmsTypes.size,
        value: '',
      },
    ],
  },
  [CmsConfig.cursor]: {
    label: 'Cursor',
    properties: [
      {
        label: 'Cursor',
        property: 'cursor',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.transform]: {
    label: 'Font transform',
    properties: [
      {
        label: 'Transform',
        property: 'text-transform',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.placeholder]: {
    label: 'Placeholder',
    properties: [
      {
        label: 'Placeholder',
        property: '--placeholder-color',
        type: CmsTypes.color,
        value: '',
      },
    ],
  },
  [CmsConfig.box]: {
    label: 'Box',
    properties: [
      {
        label: 'Box shadow',
        property: 'box-shadow',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
  [CmsConfig.display]: {
    label: 'Display',
    properties: [
      {
        label: 'Display (inline or block)',
        property: 'display',
        type: CmsTypes.string,
        value: '',
      },
    ],
  },
};
