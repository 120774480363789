import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeadProps } from './types';

const Head = ({ title }: HeadProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
    </Helmet>
  );
};

export default Head;
