import { Event365 } from '@dexper.events/dexper-datamodel/lib/src/AllModels';
import { DetailBodySettings } from './types';

export const settings = (headerData: Event365) => {
  const {
    eventType,
    language: {
      EN: {
        title = '',
        buttonLabel = '',
        descriptionLong: description = '',
        when = '',
        where = '',
      },
    },
  } = headerData;
  const settings: DetailBodySettings = {
    text: {
      when,
      where,
      eventType,
      title,
      description,
      buttonLabel,
    },
    callbacks: {
      onRegister: (...args: any[]) => {
        // eslint-disable-next-line no-console
        console.log('register fired', args);
      },
    },
  };
  return settings;
};
