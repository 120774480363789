import {
  onLoadPingRetryTimeout,
  onLoadPingUseRetry,
} from './../../../config/analytics.config';
import { OnLoadPing } from '@dexper.events/dexper-datamodel';
import { httpsCallable } from 'firebase/functions';
import { OnLoadPingType } from '../../../types';
import { functions } from '../../firebase';

const requestOnLoadPing: OnLoadPing['name'] = 'onLoadPing';
let retryTimer: ReturnType<typeof setTimeout>;

function clearRetryTimer() {
  if (retryTimer) {
    clearTimeout(retryTimer);
  }
}

const useOnLoadPing = async (obj: OnLoadPingType) => {
  const OnLoadPing = (data: any) => useOnLoadPing(data) as any;
  function retry() {
    if (onLoadPingUseRetry) {
      clearRetryTimer();
      retryTimer = setTimeout(() => OnLoadPing(obj), onLoadPingRetryTimeout);
    }
  }
  if (!obj) throw new Error('No data provided');
  try {
    sessionStorage.removeItem('visitID');
    const callOnLoadPing = httpsCallable(functions, requestOnLoadPing, {});

    const { data }: { data: any } = await callOnLoadPing({
      ping: obj,
    });
    if (data) {
      sessionStorage.setItem('visitID', data?.visitID);
      clearRetryTimer();
    }
    if (!data) {
      retry();
    }
    return data;
  } catch (error) {
    retry();
    // eslint-disable-next-line no-console
    return console.log('useOnLoadPing error occurred:', error);
  }
};

export default useOnLoadPing;
