const {
  REACT_APP_DEBUG,
  REACT_APP_FIREBASE_CONFIG,
  STORYBOOK_FIREBASE_CONFIG,
  REACT_APP_PROFILE_AVATAR_BUCKET_NAME,
  REACT_APP_PROFILE_RESOURCES_BUCKET_NAME,
  STORYBOOK_PROFILE_AVATAR_BUCKET_NAME,
  STORYBOOK_PROFILE_RESOURCES_BUCKET_NAME,
} = process.env;

const configString =
  STORYBOOK_FIREBASE_CONFIG || (REACT_APP_FIREBASE_CONFIG as any);
const profileBucketName =
  STORYBOOK_PROFILE_AVATAR_BUCKET_NAME || REACT_APP_PROFILE_AVATAR_BUCKET_NAME;
const resourcesBucketName =
  STORYBOOK_PROFILE_RESOURCES_BUCKET_NAME ||
  REACT_APP_PROFILE_RESOURCES_BUCKET_NAME;

declare global {
  interface Window {
    firebaseConfig?: any;
  }
}

const parseConfig = () => {
  try {
    return JSON.parse(configString);
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e.stack || e.message);
    return null;
  }
};

const parsedConfig = parseConfig();

export const storageBaseUrl = `https://firebasestorage.googleapis.com/v0/b/${parsedConfig.storageBucket}/o`;
export const profileBucketBaseUrl = `${storageBaseUrl}/${profileBucketName}%2F`;
export const resourcesBucketBaseUrl = `${storageBaseUrl}/${resourcesBucketName}%2F`;

if (REACT_APP_DEBUG) {
  window.firebaseConfig = parseConfig;
}

export default parsedConfig;
