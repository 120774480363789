import { ACTIVE_EVENT_NAME } from '../../../constants/general.constants';
import { dexperFirestore } from '../../firebase';
import useGetDocumentOnSnapshot from '../firebase/dexper/useGetDocumentOnSnapshot';

const useGetDemStyle = () => {
  const [demStyleData] = useGetDocumentOnSnapshot<{
    style: string;
  }>(dexperFirestore.configuratorCurrentStyle(ACTIVE_EVENT_NAME), true);
  return [demStyleData?.style] as const;
};

export default useGetDemStyle;
