import styled from 'styled-components';

interface ContainerProps {
  open: boolean | null | undefined;
  onClick: () => void;
}

export const Container = styled.div<ContainerProps>`
  display: none;
  width: 24px;
  height: 24px;
  z-index: 2;

  /* TODO: Use breakpoints */
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    height: 2px;
    transform-origin: 1px;
    transition: all 0.3s ease-in;
    background-color: ${({ open }) => (open ? 'var(--light)' : 'var(--dark)')};
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
