import React from 'react';
import { CategoryShape } from './Category.css';

export interface PrintVariationsType {
  children: any;
  shape: string;
  color: string;
}

export const PrintVariations: React.FC<PrintVariationsType> = ({
  children,
  shape,
  color,
}) => {
  return (
    <>
      <CategoryShape shape={shape} color={color} />
      {children}
    </>
  );
};
