/* eslint-disable indent */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import * as SC from './styles';
import { useHistory } from 'react-router-dom';
import { ModalSeries } from '@dexper.events/old-atomic-ui';
import { dexperFirestore } from '../../utils/firebase';
import useGetCollectionOnSnapshot from '../../utils/hooks/firebase/dexper/useGetCollectionOnSnapshot';
import { Dex365HeaderContainer } from '../containers/Dex365Header';
import GlobalContext from '../../context/GlobalContext';
import { orderBy, where } from 'firebase/firestore';
import { Dex365ContainerAppSettings } from '../containers/Dex365Header/types';
import Head from '../../components/Head';
import { currentLanguage } from '../../config/language.config';
import { useAnalytics } from '../../utils/hooks/analytics/useAnalytics';
import { useIntl } from 'react-intl';
import { Heading, VideoThumb } from '@dexper.events/product-atomic-ui';
import Carousel from '../../components/Carousel';
import { CardSeriesContainer } from '../OnDemand/Detail/containers/CardSeries';
import { CardEventContainer } from '../Events/Overview/containers/CardEvent';
import {
  getGradientColors,
  getStatusBadgeStatus,
} from '../Events/Overview/helpers';
import { adaptCategories } from '../../utils/helpers/categories/adaptCategories';
import useGetDocumentOnSnapshot from '../../utils/hooks/firebase/dexper/useGetDocumentOnSnapshot';
import { ResponsiveRow } from '../styles';
import { getSeriesTitle } from '../../utils/helpers/series/global';

function HomePage() {
  useAnalytics();
  const history = useHistory();
  const intl = useIntl();
  const globalData = useContext(GlobalContext);

  const [showModalSeries, setShowModalSeries] = useState<boolean>(false);
  const [seriesModalData, setSeriesModalData] = useState<any>();

  const [allVodData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365VODs(),
    true,
    [orderBy('order', 'asc')]
  );

  // Move to general helpers
  const adaptSeriesModalVodData = (vods: any, series: any) => {
    const adaptedVods = vods.map((value: any) => {
      value.orderInSeries = value?.series?.orderInSeries;
      const { id, thumbnail, videoID, language, categories, orderInSeries } =
        value as any;

      const seriesTitle = series
        ? getSeriesTitle(series.language[currentLanguage]?.title, orderInSeries)
        : '';
      const translations = language[currentLanguage];
      return {
        id,
        thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
        videoID,
        title: translations?.title,
        description: translations?.description,
        seriesTitle,
        categories: adaptCategories(globalData?.categories, categories),
        orderInSeries,
      };
    });
    return adaptedVods.sort((a: any, b: any) => {
      return a.orderInSeries - b.orderInSeries;
    });
  };

  // Move to general helpers
  const adaptModalData = (modalData: any) => {
    const { modalImage, accentColor, language, vods, categories } = modalData;
    const translations = language[currentLanguage];
    const adaptedModalData = {
      modalImage: `${process.env.REACT_APP_STORAGE_URL}${modalImage}`,
      accentColor: getGradientColors(accentColor, [0.7, 0.7, 0.7]),
      descriptionLong: translations?.descriptionLong,
      title: translations?.title,
      translation: {
        description: 'Description',
      },
      vodTitle: 'Episodes',
      vods: adaptSeriesModalVodData(vods, modalData),
      categories: adaptCategories(globalData?.categories, categories),
    };
    return adaptedModalData;
  };

  const openSeriesModal = (seriesId?: string) => {
    if (seriesId) {
      const currentModalData = seriesData.find(
        (serie: any) => serie.id === seriesId
      );
      const vods = allVodData.filter((vod: any) => {
        return vod.series && vod.series.seriesID === seriesId;
      });
      if (vods) {
        currentModalData.vods = vods;
      }
      setSeriesModalData(adaptModalData(currentModalData));
    }
  };

  // TODO: Align data in data model so we don't need thousands of data adapters
  const adaptHeaderData = (headerData: any) => {
    headerData = {
      ...headerData,
      headerImage: headerData?.image,
    };

    headerData.language[currentLanguage].descriptionShort =
      headerData?.language[currentLanguage].description;

    headerData.language[currentLanguage].buttonLabel =
      headerData?.language[currentLanguage].label;

    return headerData;
  };

  useEffect(() => {
    if (seriesModalData) {
      setShowModalSeries(true);
    }
  }, [seriesModalData]);

  const headerContent =
    // TODO: Fix in datamodel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalData?.content?.pageHeaders[
      // TODO: Fix in datamodel
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalData?.content?.pageHeaders?.homePageHeader
    ];

  let headerData: any;

  // We can't make getting data using hook conditional in this case
  const [fetchedHeaderData] = useGetDocumentOnSnapshot(
    dexperFirestore.dex365Events(headerContent.eventID),
    true
  );

  if (headerContent.eventID !== 'home') {
    headerData = fetchedHeaderData;
  } else {
    headerData = adaptHeaderData(headerContent);
  }

  const handleOnClick = useCallback((route) => history.push(route), [history]);

  const headerSettings: { dex365HeaderContainer: Dex365ContainerAppSettings } =
    {
      dex365HeaderContainer: {
        showButtonLabel: true,
        buttonLabel: headerContent?.language[currentLanguage]?.label,
        callbacks: {
          onSubmit: () => {
            if (headerContent.eventID && headerContent?.buttonTarget) {
              window.open(headerContent?.buttonTarget, '_blank');
            } else handleOnClick(`events/detail/${headerContent.eventID}`);
          },
        },
      },
    };

  const [eventData, setEventData] = useState<any>(null);

  const [highlightedEventData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Events(),
    true,
    [where('highlighted', '==', true), orderBy('startTimeMs', 'asc')]
  );

  const adaptEventData = (eventData: any) => {
    const concludedEvents = eventData.filter((event: any) => {
      const { startTimeMs, endTimeMs } = event;
      const badgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
      const status = badgeStatus.status;
      return status === 'concluded';
    });
    const activeEvents = eventData.filter((event: any) => {
      const { startTimeMs, endTimeMs } = event;
      const badgeStatus = getStatusBadgeStatus(startTimeMs, endTimeMs);
      const status = badgeStatus.status;
      return status !== 'concluded';
    });
    const customSortedEvents = activeEvents.concat(concludedEvents);
    const adaptedEventData = customSortedEvents.map((event: any) => {
      return {
        ...event,
      };
    });
    return adaptedEventData;
  };

  useEffect(() => {
    if (!highlightedEventData) {
      return;
    }
    setEventData(adaptEventData(highlightedEventData));
  }, [highlightedEventData]);

  const [seriesData, setSeriesData] = useState<any>(null);

  const [highlightedSeriesData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365Series(),
    true,
    [where('highlighted', '==', true), orderBy('order', 'asc')]
  );

  const adaptSeriesData = (seriesData: any) => {
    return seriesData;
  };

  useEffect(() => {
    if (!highlightedSeriesData) {
      return;
    }
    setSeriesData(adaptSeriesData(highlightedSeriesData));
  }, [highlightedSeriesData]);

  const [vodData, setVodData] = useState<any>(null);

  const [highlightedVodData] = useGetCollectionOnSnapshot(
    dexperFirestore.dex365VODs(),
    true,
    [where('highlighted', '==', true), orderBy('order', 'asc')]
  );

  const adaptVodData = (vodData: any) => {
    const adaptedVods = vodData
      .map((vod: any, index: number) => {
        const id = vod.id;
        const foundVod = vodData.find((v: any) => v.id === id);

        if (foundVod) {
          let seriesTitle = '';
          let orderInSeries;
          if (foundVod?.series) {
            const translations = foundVod.series?.language[currentLanguage];
            orderInSeries = foundVod?.series.orderInSeries;
            seriesTitle = `${translations.title} - EP. ${orderInSeries + 1}`;
          }
          const {
            categories,
            language,
            thumbnail,
            videoID,
            orderInEvent,
            slug,
          } = vod;
          // console.log(orderInEvent);
          const translations = language[currentLanguage];
          return {
            id,
            index,
            categories: adaptCategories(globalData?.categories, categories),
            description: translations?.description,
            title: translations?.title,
            thumbnail: `${process.env.REACT_APP_STORAGE_URL}${thumbnail}`,
            orderInEvent,
            orderInSeries,
            videoID,
            seriesTitle,
            slug,
          };
        }
      })
      .sort((a: any, b: any) => {
        return a.orderInEvent - b.orderInEvent;
      });

    return adaptedVods;
  };

  useEffect(() => {
    if (!highlightedVodData) {
      return;
    }
    setVodData(adaptVodData(highlightedVodData));
  }, [highlightedVodData]);

  return (
    <SC.Wrapper>
      <Head title={intl.formatMessage({ id: 'analytics.home' })} />
      <SC.StyledContainer className="Dex365EventPage">
        <section className="section-row1">
          <Dex365HeaderContainer
            className="primary"
            data={headerData}
            appSettings={headerSettings.dex365HeaderContainer}
          />
        </section>
        <SC.ContentWrapper>
          {eventData && eventData.length && (
            <ResponsiveRow>
              <Heading tag="h3" className="primary channels-heading">
                Highlighted Events
              </Heading>
              <Carousel
                slides={
                  <>
                    {eventData?.map((event: any, index: number) => (
                      <SC.StyledSlide
                        key={event.order}
                        index={event.order}
                        className={`section-row3 EventContainer slide-${
                          index + 1
                        }`}
                      >
                        <CardEventContainer
                          className="primary"
                          dex365Event={event}
                        />
                      </SC.StyledSlide>
                    ))}
                  </>
                }
              />
            </ResponsiveRow>
          )}
          {highlightedSeriesData && highlightedSeriesData.length && (
            <ResponsiveRow className="highlighted-channels">
              <Heading tag="h3" className="primary channels-heading">
                Highlighted Series
              </Heading>
              <Carousel
                // config={{ visibleSlides: 1, hideButtons: true }}
                slides={
                  <>
                    {highlightedSeriesData?.map((series: any) => (
                      <SC.StyledSlide
                        key={series.order}
                        index={series.order}
                        className="section-top"
                      >
                        <CardSeriesContainer
                          className="primary"
                          series={series}
                          callbacks={{
                            onClick: () => {
                              history.push(`/on-demand/${series.slug}`);
                            },
                            onPlay: (e: any) => {
                              e.stopPropagation();
                              history.push(`/on-demand/${series.slug}`);
                            },
                            onInfo: (e: any) => {
                              e.stopPropagation();
                              openSeriesModal(series.id);
                            },
                          }}
                        />
                      </SC.StyledSlide>
                    ))}
                  </>
                }
              />
            </ResponsiveRow>
          )}
          {vodData && vodData.length && (
            <ResponsiveRow className="highlighted-videos">
              <Heading tag="h3" className="primary channels-heading">
                Highlighted Videos
              </Heading>
              <Carousel
                config={{
                  buttonOffset: -80,
                }}
                slides={
                  <>
                    {vodData?.map((vod: any, index: number) => (
                      <SC.StyledSlide
                        key={vod.id}
                        index={index}
                        className="section-top"
                      >
                        <VideoThumb
                          className="primary"
                          thumbnail={vod.thumbnail}
                          categories={vod.categories || []}
                          description={vod.description}
                          title={vod.title}
                          videoId={vod.videoID}
                          seriesInfo={vod.seriesTitle}
                          onClick={() => {
                            history.push({
                              pathname: `/on-demand/${vod.slug}`,
                              state: { referrer: 'detail' },
                            });
                          }}
                        />
                      </SC.StyledSlide>
                    ))}
                  </>
                }
              />
            </ResponsiveRow>
          )}
          {showModalSeries && seriesModalData && (
            <ModalSeries
              {...seriesModalData}
              showModal={showModalSeries}
              onClose={() => {
                setShowModalSeries(false);
              }}
              onVideoThumb={(data: any) => {
                history.push(`/on-demand/${data.id}`);
              }}
            />
          )}
        </SC.ContentWrapper>
      </SC.StyledContainer>
    </SC.Wrapper>
  );
}

export default HomePage;
