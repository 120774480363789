import { OnClickPing } from '@dexper.events/dexper-datamodel';
import { httpsCallable } from 'firebase/functions';
import { OnClickPingType } from '../../../types';
import { functions } from '../../firebase';

const requestOnClickPing: OnClickPing['name'] = 'onClickPing';

const useOnClickPing = async (data: OnClickPingType) => {
  if (!data) throw new Error('No data provided');
  try {
    const callOnClickPing = httpsCallable(functions, requestOnClickPing, {});

    return await callOnClickPing(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    return console.log('useOnClickPing error occurred:', error);
  }
};

export default useOnClickPing;
