import { css } from 'styled-components';

interface ShapeProps {
  color: string;
}

export default css<ShapeProps>`
  width: 16px;
  height: 8px;
  margin-right: 3px;
  background-color: ${({ color }) => color};
  display: block;
`;
