import styled from 'styled-components';

export const TopBarContainer = styled.div`
  overflow: hidden;
  transition: all 250ms ease-in-out;
  max-height: 0;

  &.fold-enter {
    max-height: 0;
  }

  &.fold-enter-active {
    max-height: var(--height);
  }

  &.fold-enter-done {
    max-height: var(--height);
  }

  &.fold-exit {
    max-height: var(--height);
  }

  &.fold-exit-active {
    max-height: 0;
  }

  &.fold-exit-done {
    display: none !important;
  }

  /* TODO Ugly override for mobile devices, should be removed when DEM supports responsive designs */
  @media only screen and (max-width: 767px) {
    &.primary.primary.primary {
      height: 95px;
      --height: 95px;
    }
  }
`;

export const TextContainer = styled.div``;
