export function isEmpty(value: any) {
  return (
    // Will cover null or undefined
    value == null ||
    // Will cover has length and zero
    (Object.prototype.hasOwnProperty.call(value, 'length') &&
      value.length === 0) ||
    // Will cover if it is an Object without keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
}

export function getDomain(url?: string) {
  return new URL(url || window.location.href);
}
