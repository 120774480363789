import { firestore } from './index';
import { TypedPath } from '@dexper.events/dexper-datamodel/lib/src/DataMappings';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  Query,
  query,
  QueryConstraint,
} from 'firebase/firestore';

export enum ReferenceType {
  Document,
  Collection,
}

export function getTypedReference<T>(
  path: TypedPath<T>,
  type: ReferenceType,
  queryParams?: QueryConstraint[]
) {
  let reference;
  if (type == ReferenceType.Collection) {
    reference = collection(firestore, path.path) as CollectionReference<T>;
  } else if (type == ReferenceType.Document) {
    reference = doc(firestore, path.path) as DocumentReference<T>;
  }
  if (queryParams) {
    return query(reference as Query<T>, ...queryParams);
  }
  return reference;
}
