import { Grid, ResourceCard } from '@dexper.events/product-atomic-ui';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );

  .section-row1 {
    .Text {
      text-transform: initial !important;
    }
  }
  padding-bottom: 40px;
`;

export const ResourceContainer = styled.div``;

export const ResponsiveRow = styled.section`
  padding: 40px var(--responsive) 0;
`;

interface IResourceCard {
  settings: any;
}

export const StyledResourceCard = styled(ResourceCard)<IResourceCard>`
  cursor: pointer;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: end;
  height: 267px;
  color: #fff;
  div {
    margin-top: auto;
  }
  .section-hover {
    transition: max-height 0.7s ease-out;
    font-size: 15px;
    font-weight: 400;
  }
  .section-text {
    h3 {
      margin: 8px 0;
      padding: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
    }
    .Button {
      cursor: pointer;
      background: transparent;
      padding: 0;
      color: #1bc492;
      height: 18px;
      width: 18px;
      :after {
        display: block;
        content: ' ';
        background-size: 18px 18px;
        height: 18px;
        width: 18px;
        background-image: ${({ settings }) => `url(${settings?.icon})`};
      }
      svg {
        display: none;
      }
    }
  }
  .section-hover {
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      margin-top: 8px;
    }
  }
  background: ${({ settings }) =>
    `linear-gradient(${settings?.firstGradientColor}, ${settings?.secondGradientColor}),
    left/cover url(${settings?.background})`} !important;
`;

export const CustomStyledGrid = styled(Grid)`
  position: relative;
  flex-direction: row !important;
  justify-content: initial !important;
  align-items: initial !important;
  padding: 8px var(--responsive) 20px !important;
  padding-bottom: 60px !important;
  :after {
    display: flex;
    flex-grow: 1;
    content: '';
    position: absolute;
    bottom: 0;
    margin: 0 var(--responsive) 0 !important;
    width: calc(100% - 2 * var(--responsive)) !important;
    border-bottom: solid 1px #4d5358;
  }
`;
