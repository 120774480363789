import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Content, HeadingContainer } from './Header.css';
import classNames from 'classnames';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { Row } from '../../Layout/Row/Row';

export const HeaderStory = {
  className: 'primary',
  settings: {
    text: {
      titleType: 'h1',
      title: 'Lightning Talks',
      description:
        'Lightning Talks are HashiConf’s hallway track, a great place to meet and mingle with the community. Community Lighting Talks are 15-minute technical talks by our community, covering a range of topics, from technical deep-dives to exam prep resources. If you are interested in hosting a Lightning Talk, click on the ‘Request Lighting Talk.’ Submissions are due by noon (PDT), October 15. HashiCorp-led Lightning Talks can be found on the HashiCorp Zone.',
      buttonLabel: 'Request a Lightning Talk',
    },
    callbacks: {
      onClick: (...args: any[]) => {
        console.log('onClick fired', args);
      },
    },
    showButton: true,
  },
};

const HeaderPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    showButton: PropTypes.bool.isRequired,
  }),
};

export type HeaderTypes = InferProps<typeof HeaderPropTypes>;

export const Header: React.FC<HeaderTypes> = ({
  className,
  settings: {
    // @ts-ignore
    text: { titleType = 'h1', title, description, buttonLabel },
    // @ts-ignore
    callbacks: { onClick },
    // @ts-ignore
    showButton,
  },
}) => (
  <HeadingContainer className={classNames('Header', className)}>
    <Row>
      <Heading tag={titleType} className='primary'>
        {title}
      </Heading>
      <Content>
        <Text className='primary'>{description}</Text>
        {showButton && (
          <Button className='primary' onClick={onClick}>
            {buttonLabel}
          </Button>
        )}
      </Content>
    </Row>
  </HeadingContainer>
);

const atoms = [
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button primary',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
];

export const HeaderSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.fill,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
  ],
  atoms: atoms,
  variants: [
    {
      className: '&primary',
      description: 'Header primary',
    },
  ],
};

Header.propTypes = HeaderPropTypes;
