import React from 'react';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { State } from '../../../definitions/States';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { PrintVariations } from './variations';

export const textAreaAtomSettings = {
  className: 'primary',
  disabled: false,
  error: false,
  type: 'text',
  name: '',
  value: '',
  placeholder: 'Some placeholder',
  customRef: null,
  onBlur: (...args: any[]) => {
    console.log('onBlur fired', args);
  },
  onChange: (...args: any[]) => {
    console.log('onChange fired', args);
  },
};
export const TextAreaAtomStory = {
  className: textAreaAtomSettings.className,
  disabled: textAreaAtomSettings.disabled,
  error: textAreaAtomSettings.error,
  type: textAreaAtomSettings.type,
  name: textAreaAtomSettings.name,
  value: textAreaAtomSettings.value,
  placeholder: textAreaAtomSettings.placeholder,
  customRef: textAreaAtomSettings.customRef,
  onBlur: textAreaAtomSettings.onBlur,
  onChange: textAreaAtomSettings.onChange,
};

const TextAreaAtomPropTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  customRef: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

/* 
export interface TextAreaAtomTypes {
  className?: string;
  ref?: any;
  name?: string;
  disabled?: boolean;
  type?: string;
  defaultValue?: string;
  error?: boolean;
  placeholder?: string;
  onBlur?: any;
  props?: any;
} 
*/

export type TextAreaAtomTypes = InferProps<typeof TextAreaAtomPropTypes>;

export const TextAreaAtom: React.FC<TextAreaAtomTypes> = ({
  className,
  disabled,
  error,
  type,
  name,
  value,
  placeholder,
  customRef,
  onBlur,
  onChange,
}) => {
  return (
    <PrintVariations
      className={classNames('TextAreaAtom', className)}
      disabled={disabled}
      error={error}
      type={type}
      name={name}
      defaultValue={value}
      placeholder={placeholder}
      customRef={customRef}
      onBlur={onBlur}
      onChange={onChange}
    ></PrintVariations>
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.border,
  CmsConfig.color,
  CmsConfig.padding,
  CmsConfig.frame,
  CmsConfig.text,
  CmsConfig.placeholder,
];

export const TextAreaAtomSettings: ISettings = {
  states: [State.default, State.active, State.disabled, State.focus],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'TextAreaAtom Primary',
    },
    {
      className: '&secondary',
      description: 'TextAreaAtom Secondary',
    },
    {
      className: '&error',
      description: 'TextAreaAtom Error',
    },
  ],
};

/* TextAreaAtom.defaultProps = {
  className: 'primary',
  disabled: false,
  error: false,
  type: 'text',
  name: '',
};
 */
