//@ts-nocheck
const variants = (theme, variant) => {
  const variantConfig = {
    default: {
      header: {
        textColor: {
          default: '#343536',
          selected: '#1D1E23',
        },
        backgroundColor: {
          default: '#131414',
          disabled: '#212324',
        },
        borderColor: {
          focus: '#727274',
          error: '#F25054',
          default: '#343536',
        },
      },
      item: {
        textColor: {
          default: '#727274',
        },
        backgroundColor: {
          default: '#131414',
          hover: '#212324',
        },
        borderColor: {
          default: '#727274',
        },
      },
    },
    dark: {
      header: {
        textColor: {
          default: '#131414',
          selected: '#131414',
        },
        backgroundColor: {
          default: '#343536',
          disabled: '#343536',
        },
        borderColor: {
          focus: '#727274',
          error: '#F25054',
        },
      },
      item: {
        textColor: {
          default: '#727274',
        },
        backgroundColor: {
          default: '#131414',
          hover: '#212324',
        },
      },
    },
    bordered: {
      header: {
        textColor: {
          default: '#727274',
          selected: '#727274',
          focus: '#727274',
        },
        backgroundColor: {
          default: '#131414',
          disabled: '#212324',
        },
        borderColor: {
          default: '#343536',
          focus: '#727274',
          error: '#F25054',
        },
      },
      item: {
        textColor: {
          default: '#727274',
        },
        backgroundColor: {
          default: '#131414',
          hover: '#212324',
        },
        borderColor: {
          default: '#727274',
        },
      },
    },
  };
  return variantConfig[variant];
};

export default variants;
