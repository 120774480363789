import React from 'react';
import PropTypes from 'prop-types';
import { CategoryContainer } from './Category.css';
import { Text } from '../../Atoms/Text/Text';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import classNames from 'classnames';
import { State } from '../../../definitions/States';

/* 
Implementation example:
=======================

import {Category, CategorySettings} from '@dexper.events/product-atomic-ui';

const {className, children, shape} = CategoryStory;

<div className="AnotherPageTest"> //* page className
    <div className="section-bottom">  //* section className
        <Category className={CategoryStory.className} children={CategoryStory.children} shape={CategoryStory.shape} ></Category> //* component variation className
    </div>
</div> 

*/

import { PrintVariations } from './variations';
export interface CategoryType {
  children?: any;
  className: string;
  shape: any;
  color: string;
}

export const CategoryStory = {
  className: 'primary',
  children: 'Fun',
  shape: 'circle',
  color: 'orange',
};

export const Category: React.FC<CategoryType> = ({
  className,
  shape,
  children,
  color,
}) => (
  <CategoryContainer className={classNames('Category', className)}>
    <PrintVariations shape={shape} color={color}>
      {children}{' '}
    </PrintVariations>
  </CategoryContainer>
);

Category.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  shape: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Category.defaultProps = {
  className: 'primary',
  shape: 'circle',
  color: 'orange',
};

const variants = [
  {
    className: '&primary',
    description: 'Category primary',
  },
];

export const CategorySettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.text,
    CmsConfig.color,
    CmsConfig.padding,
    CmsConfig.flexbox,
  ],
  variants: variants,
};
