import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { DefaultLink, ReactRouterLink } from './Link.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';

const LinkPropTypes = {
  label: PropTypes.string,
  location: PropTypes.string.isRequired,
  anchorType: PropTypes.oneOf(['internal', 'external']),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  targetBlank: PropTypes.bool,
  callback: PropTypes.func,
  className: PropTypes.string,
};

export const LinkStory = {
  label: 'TargetBlank',
  anchorType: 'external',
  location: 'https://dexper.io',
  targetBlank: true,

  // children: <div>I am a Link</div>,
  disabled: false,
  callback: (...args: any[]) => {
    console.log('callback fired', args);
  },
  className: 'primary',
};

export type LinkType = InferProps<typeof LinkPropTypes>;

export const Link: React.FC<LinkType> = ({
  label,
  location,
  anchorType,
  targetBlank,
  callback,
  children,
  className,
}) => {
  const history = useHistory();

  function goLink() {
    if (callback) {
      callback();
    }
    if (location) {
      history.push(location);
    }
  }

  return (
    <>
      {anchorType === 'external' && (
        <DefaultLink
          className={classNames('Link', className)}
          href={location}
          target={targetBlank ? '_blank' : '_self'}
          rel={targetBlank ? 'noreferrer' : ''}
          onPointerDown={() => callback && callback()}
        >
          {label ? label : children}
        </DefaultLink>
      )}
      {anchorType === 'internal' && (
        <>
          <ReactRouterLink
            className={classNames('Link', className)}
            to={location}
            onPointerDown={() => goLink()}
          >
            {label ? label : children}
          </ReactRouterLink>
        </>
      )}
    </>
  );
};

export const LinkSettings: ISettings = {
  include: [
    CmsConfig.frame,
    CmsConfig.text,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.cursor,
    CmsConfig.color,
  ],
  states: [State.default, State.active, State.hover, State.disabled],
  variants: [
    {
      className: '&primary',
      description: 'Link primary',
    },
    {
      className: '&secondary',
      description: 'Link secondary',
    },
  ],
};

Link.propTypes = LinkPropTypes;

Link.defaultProps = {
  anchorType: 'internal',
  targetBlank: false,
  callback: null,
  className: '',
  disabled: false,
};
