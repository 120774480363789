import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { Container, RightNavigation } from './Navigation.css';
import classNames from 'classnames';
import { ISettings } from '../../../definitions/ISettings';
import { Burger } from '../../Atoms/Burger/Burger';

const NavigationPropTypes = {
  content: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      }).isRequired,
    ),
    test: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
};

export type NavigationTypes = InferProps<typeof NavigationPropTypes>;

export const Navigation: React.FC<NavigationTypes> = ({
  className,
  content: { links = [] },
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Container open={open} className={classNames('navigation', className)}>
      <div>&nbsp;</div>
      <Burger open={open} onClick={() => setOpen(!open)} />
      <RightNavigation open={open}>
        {links?.map(({ text, to }) => (
          <Link key={text} to={to}>
            {text}
          </Link>
        ))}
      </RightNavigation>
    </Container>
  );
};

export const NavigationSettings: ISettings = {
  variants: [
    {
      className: 'navigation',
      description: 'Navigation box',
    },
  ],
};

Navigation.propTypes = NavigationPropTypes;
