import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean | null | undefined;
}

export const Nav = styled.div`
  background: #000;
  white-space: nowrap;
`;

export const DesktopNav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  color: #76767d;
  width: 100%;
  height: 72px;
`;

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

export const NavLinksWrapper = styled.ul`
  flex: 1;
  display: flex;
  height: 100%;
  list-style: none;
  padding-left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 50px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox compatibility */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DivLine = styled.span`
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 0px;
`;

export const FlexWrapper = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  &:active {
    ${DivLine} {
      width: 100%;
    }
  }
  &:hover {
    ${DivLine} {
      transition: width 0.4s;
      transform: translate(-50%, 0);
      left: 50%;
      background: #76767d;
      width: 100%;
    }
  }

  ${(props) =>
    props.active &&
    css`
      ${DivLine} {
        transition: width 0.4s;
        transform: translate(-50%, 0);
        left: 50%;
        background: #76767d;
        width: 100%;
      }
    `}
`;
