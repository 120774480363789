export function getGradientColors(
  accentColor: string | undefined,
  alphas: number[]
): string[] {
  if (!accentColor) return alphas.map(() => 'rgba(0,0,0,0)');
  const accentColors = accentColor
    .replace('rgb(', '')
    .replace(')', '')
    .split(', ');
  return alphas.map(
    (alpha: number) =>
      `rgba(${accentColors[0]}, ${accentColors[1]}, ${accentColors[2]}, ${alpha})`
  );
}
