import { useLayoutEffect, useState } from 'react';

export const breakpoints = {
  smallMobileUp: 375,
  mobileUp: 414,
  largeMobileUp: 568,
  mobileLandscapeUp: 568,
  tabletPortraitUp: 768,
  tabletLandscapeUp: 1024,
  desktopUp: 1200,
  largeDesktopUp: 1600,
};

export const useWindowSize = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
