import React from 'react';
import { State } from '../../../definitions/States';
import { ISettings } from '../../../definitions/ISettings';
import { CmsConfig } from '../../../definitions/CmsConfig';
import classNames from 'classnames';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  TickLogo,
} from './CheckboxAtom.css';

export interface CheckboxAtomType {
  className?: string;
  onChange: () => any;
  checked: boolean;
  id: string;
}

export const CheckboxAtomStory = {
  className: 'primary',
  onChange: (...args: any[]) => {
    console.log('onChange fired', args);
  },
  checked: true,
  id: 'id1',
};

/**
 * For this component to work with a correspondent label, the "for"
 * attribute has to be passed with the respective id.
 */

export const CheckboxAtom: React.FC<CheckboxAtomType> = ({
  checked,
  onChange,
  id,
  className,
}) => {
  return (
    <>
      <CheckboxContainer checked={checked} onClick={onChange}>
        <HiddenCheckbox id={id} onChange={onChange} checked={checked} />
        <StyledCheckbox
          className={classNames('CheckboxAtom', className)}
          checked={checked}
        >
          <TickLogo stroke='var(--alt-color, currentColor)' />
        </StyledCheckbox>
      </CheckboxContainer>
    </>
  );
};

const allCategories = [
  CmsConfig.fill,
  CmsConfig.frame,
  CmsConfig.border,
  CmsConfig.vmargin,
  CmsConfig.hmargin,
  CmsConfig.altColor,
];

export const CheckboxAtomSettings: ISettings = {
  states: [State.default, State.hover, State.active, State.disabled],
  include: allCategories,
  variants: [
    {
      className: '&primary',
      description: 'CheckboxAtom square',
    },
  ],
};
