import styled from 'styled-components';
export const MeetupCardContainer = styled.div``;
export const StyledSection = styled.div`
  width: 100%;
  .RichText.primary {
    p {
      display: inline;
    }
  }
`;
