import React from 'react';
import PropTypes from 'prop-types';
import { State } from '../../../definitions/States';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import classNames from 'classnames';
import Close from './assets/close.svg';
import {
  Wrapper,
  Label,
  Img,
  SubWrapper,
  LinkWithLabel,
  AvatarImg,
  CanvasWrapper,
  ImgDiv,
  StyledIcon,
} from './AvatarProfileUpload.css';

export const AvatarProfileUploadStory = {
  label: 'Profile picture',
  imgUrl: '/assets/icons/avatar.svg',
  defaultImage: '/assets/icons/avatar.svg',
  onDelete: (data: any) => console.log('this is a console log ', data),
  isDeletable: true,
};
export interface AvatarProfileUploadAtomType {
  label: string;
  imgUrl?: string;
  skeleton?: boolean;
  defaultImage: string;
  onClick?: () => void;
  onDelete: (a: string) => void;
  isDeletable?: boolean;
  avatarModalActive?: boolean;
  className?: string;
}

export const AvatarProfileUpload: React.FC<AvatarProfileUploadAtomType> = ({
  label,
  imgUrl,
  skeleton,
  defaultImage,
  onClick,
  onDelete,
  isDeletable,
  avatarModalActive,
  className,
}) => {
  const handleDelete = () => {
    onDelete(defaultImage);
  };
  //* TO-DO: Skeleton
  return skeleton ? (
    <div>Loading...</div>
  ) : (
    <Wrapper className={classNames('AvatarProfileUpload', className)}>
      <SubWrapper>
        <CanvasWrapper>
          {isDeletable ? (
            <ImgDiv>
              <Img
                onClick={handleDelete}
                src={Close}
                alt=''
                style={{ verticalAlign: 'top' }}
              />
            </ImgDiv>
          ) : null}
          <AvatarImg
            className='section-image'
            img={imgUrl ? imgUrl : defaultImage}
          />
        </CanvasWrapper>
        {avatarModalActive && (
          <Label className='section-label' onClick={onClick}>
            <Text className='primary'>{label}</Text>
            <LinkWithLabel>
              <StyledIcon
                className='primary'
                location='/assets/icons/upload.svg'
                content={{ label: 'upload' }}
              />
            </LinkWithLabel>
          </Label>
        )}
      </SubWrapper>
    </Wrapper>
  );
};

const allCategories = [CmsConfig.padding, CmsConfig.color, CmsConfig.frame];
const imageCategories = [CmsConfig.frame, CmsConfig.hmargin, CmsConfig.vmargin];
const labelCategories = [
  CmsConfig.padding,
  CmsConfig.hmargin,
  CmsConfig.vmargin,
];
const atoms = [
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon upload',
    settings: IconSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
];

export const AvatarProfileUploadSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  sections: [
    {
      className: 'section-image',
      description: 'Section image',
      include: imageCategories,
    },
    {
      className: 'section-label',
      description: 'Section label',
      include: labelCategories,
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'AvatarProfileUpload primary',
    },
  ],
};

AvatarProfileUpload.propTypes = {
  onClick: PropTypes.func,
  avatarModalActive: PropTypes.bool,
  skeleton: PropTypes.bool,
  label: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  defaultImage: PropTypes.string.isRequired,
};

AvatarProfileUpload.defaultProps = {
  onClick: () => '',
  avatarModalActive: true,
  skeleton: false,
  label: 'Profile image',
  imgUrl: '',
  defaultImage: '',
};
