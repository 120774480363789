/* eslint-disable indent */
import { TypedPath } from '@dexper.events/dexper-datamodel/lib/src/DataMappings';
import {
  CollectionReference,
  DocumentReference,
  onSnapshot,
  Query,
  QueryConstraint,
  QuerySnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { GenericWithDocumentID } from '../../../../types';
import {
  getTypedReference,
  ReferenceType,
} from '../../../firebase/getTypedReference';
import { usePersist } from '../../storage/usePersist';

const useGetCollectionOnSnapshot = <T>(
  mapping: TypedPath<T>,
  persist?: boolean,
  queryParams?: QueryConstraint[]
) => {
  const reference:
    | DocumentReference<T>
    | CollectionReference<T>
    | Query<T>
    | undefined = getTypedReference(
    mapping,
    ReferenceType.Collection,
    queryParams
  );
  const path = mapping.path;
  let storageKey = path;
  if (queryParams?.length) {
    storageKey = `${storageKey}/${JSON.stringify(queryParams)}`;
  }
  const [storageResponseData, setStorageResponseData] = usePersist(storageKey);
  const [responseData, setResponseData] =
    useState<GenericWithDocumentID<T>[]>(storageResponseData);
  useEffect(() => {
    let unsubscribe: Unsubscribe;
    (async () => {
      unsubscribe = onSnapshot(
        reference as Query<T>,
        (snapshot: QuerySnapshot<T>) => {
          const data: GenericWithDocumentID<T>[] = [];
          snapshot.forEach((document) => {
            data.push({
              ...document.data(),
              id: document.id,
            });
          });
          setResponseData(data);
          if (process.env.REACT_APP_DEBUG === 'true') {
            // eslint-disable-next-line no-console
            console.info('Getting collection from firebase:', data);
          }
          if (persist) {
            setStorageResponseData(data);
          }
        }
      );
    })();
    return () => unsubscribe();
  }, [path, persist, setResponseData, setStorageResponseData]);
  return [responseData] as const;
};

export default useGetCollectionOnSnapshot;
