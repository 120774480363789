import styled from 'styled-components';

/* interface T {
  type?: string;
  error?: boolean;
}
 */
export const StyledTextArea = styled.textarea`
  /* @ts-ignore */
`;

/* export const StyledTextArea = styled.textarea<T>`
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 113px;
  padding: 10px 18px;
  background-color: #212324;
  color: #727274;
  font-size: 15px;
  line-height: 25px;
  outline: none;
  border-radius: 0;
  border: 1px solid #343536;

  &:disabled {
    background-color: #212324;
    color: #343536;
  }

  &:hover {
    color: #aaa;
    border-color: #212324;
  }

  &:focus {
    outline: none;
    color: #aaa;
    border-color: #727274;
  }

  &:focus-visible {
    outline: none;
    box-shadow: ${() => `0 0 0 2px #FFF, 0 0 0 4px #00A1F1`};
  }
`; */
