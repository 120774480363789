import useOnLoadPing from './useOnLoadPing';
import useAnalyticsBackgroundTask from './useAnalyticsBackgroundTask';
import { useEffect } from 'react';

const OnLoadPing = (data: any) => useOnLoadPing(data) as any;
const AnalyticsBgTask = (data: any, exitPage?: boolean) =>
  useAnalyticsBackgroundTask({ data, exitPage }) as any;

export const useAnalytics = () => {
  useEffect(
    () =>
      OnLoadPing({
        userAgentString: navigator?.userAgent,
        page: window?.location?.pathname,
        event: 'dex365',
      }),
    []
  );
  useEffect(
    () =>
      AnalyticsBgTask({
        ping: {
          userAgentString: navigator?.userAgent,
          page: window?.location?.pathname,
          event: 'dex365',
        },
      }),
    []
  );
  useEffect(() => {
    const page = window?.location?.pathname;
    return () => {
      AnalyticsBgTask(
        {
          ping: {
            userAgentString: navigator?.userAgent,
            page,
            event: 'dex365',
          },
        },
        true
      );
    };
  }, []);
};
