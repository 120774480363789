import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';

export const atoms = [
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text answer',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text vote',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Text,
    name: 'Text~tertiary',
    description: 'Text question',
    settings: TextSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon Back',
    settings: IconSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    name: 'Icon~secondary',
    description: 'Icon Check',
    settings: IconSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Icon,
    name: 'Icon~tertiary',
    description: 'Icon Processing',
    settings: IconSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button vote',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~secondary',
    description: 'Button answer',
    settings: ButtonSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Button,
    name: 'Button~tertiary',
    description: 'Button back',
    settings: ButtonSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Button,
    name: 'Button~quaternary',
    description: 'Button mini-profile',
    settings: ButtonSettings,
    atomVariant: '&quaternary',
  },
  {
    atom: Button,
    name: 'Button~quinary',
    description: 'Button mini-profile',
    settings: ButtonSettings,
    atomVariant: '&quinary',
  },
];
