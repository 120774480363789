import { Heading, Link, Text } from '@dexper.events/product-atomic-ui';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Wrapper } from './styles';

function Error404Page() {
  const title = 'Dexper - Page not found';

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <div className="Dex365NotFoundPage">
        <div className="section-heading">
          <Heading tag="h1" className="primary">
            Page not found
          </Heading>
        </div>
        <div className="section-text">
          <Text className="primary">
            We&apos;re sorry but we can&apos;t find the page you&apos;re looking
            for.
          </Text>
        </div>
        <div className="section-link">
          <Link className="primary" location="/" anchorType="internal">
            Back to Home
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

export default Error404Page;
