import styled, { css } from 'styled-components';

export const FilterBar365Container = styled.div``;
export const StyledSection = styled.div``;

export const DivLine2 = css`
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var(--alt-color);
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s;
  border-bottom: 2px solid transparent;
  min-height: 52px; /*//! height of the Footer365 component */
  padding-left: 10px;
  padding-right: 10px;

  a {
    white-space: nowrap;
  }

  :hover {
    ${DivLine2}
  }
`;
