import styled from 'styled-components';

export const CookieConsentWrapper = styled.div`
  .CookieConsent {
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.2);
    background-color: #f2f2f3;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    font-size: 0.9rem;
    color: #000;
    padding: 0 -15px 0 calc(var(--responsivePadding) - 15px);
    align-items: center !important;
    > div {
      margin: 0 !important;
      padding: 23px 30px 23px 30px;
    }
  }

  #rcc-confirm-button {
    border: 0;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 2px;
    transform: translateZ(0);
    transition: transform 0.2s ease;
    background-color: #1bc492;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    :hover {
      opacity: 0.8;
    }
  }
  a {
    color: #1bc492;
    font-size: 16px;
    font-weight: 300;
    font-family: Dexper, sans-serif !important;
  }
`;
