import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { ButtonStyle, ImgStyle } from './Button.css';

/*
Implementation examples:
========================

import {Button} from '@dexper.events/product-atomic-ui';

const svgSrc = 'https://dexper-product-development.web.app/static/media/user.2c0a844d.svg';
const profilePhoto = 'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Fimages%2Fprofile-photo.jpg?alt=media&token=bcdeb824-f3a4-4532-821b-34c4b06a56af';

<Button onClick={() => {console.log('click')}}>Click me!</Button>

// Button with text and icon

<Button image={svgSrc}>Click me!</Button>

// When a button only has an icon, provide an alt parameter describing the icon
// to comply with WCAG

<Button image={svgSrc} alt="Icon of person"/>

// Image as a button. Provide alt parameter.

<Button background={profilePhoto} alt="Evelyn Hill"/>

*/

export interface ButtonTypes {
  disabled?: boolean;
  className: string;
  image?: string | null;
  background?: string;
  alt?: string;
  children?: ReactNode;
  onClick?: (e: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const ButtonStory = {
  className: 'primary',
  disabled: false,
  image:
    'https://dexper-product-development.web.app/static/media/user.2c0a844d.svg',
  background:
    'https://firebasestorage.googleapis.com/v0/b/dexper-product-development.appspot.com/o/assets%2Fimages%2Fprofile-photo.jpg?alt=media&token=bcdeb824-f3a4-4532-821b-34c4b06a56af',
  children: 'Click me',
  alt: 'Icon of person',
  onClick: () => {
    console.log('story assigned event');
  },
};

export const Button: React.FC<ButtonTypes> = ({
  className,
  disabled,
  image,
  background,
  alt,
  children,
  onClick,
  type,
}) => {
  if (!children && !alt) {
    console.error(`Provide children or alt property to Button ${className}`);
  }

  return (
    <ButtonStyle
      disabled={disabled || false}
      className={classNames('Button', className)}
      type={type || 'submit'}
      aria-label={alt || ''}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      background={background || ''}
    >
      {image && (
        <ImgStyle viewBox='0 0 100 100'>
          <defs>
            <mask id={`${image}-mask`}>
              <image xlinkHref={image} width='100' height='100' />
            </mask>
          </defs>
          <rect
            width='100'
            height='100'
            style={{ fill: 'var(--alt-color, currentColor)' }}
            mask={`url(#${image}-mask)`}
          />
        </ImgStyle>
      )}
      {children}
    </ButtonStyle>
  );
};

export const ButtonSettings: ISettings = {
  states: [State.default, State.active, State.hover, State.disabled],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.text,
    CmsConfig.color,
    CmsConfig.altColor,
    CmsConfig.fill,
    // CmsConfig.effects,
    CmsConfig.padding,
    CmsConfig.flexbox,
    CmsConfig.cursor,
    CmsConfig.hmargin,
    // CmsConfig.display,
  ],
  variants: [
    {
      className: '&primary',
      description: 'Button Primary',
    },
    {
      className: '&secondary',
      description: 'Button Secondary',
    },
    {
      className: '&tertiary',
      description: 'Button Ternary',
    },
    {
      className: '&quaternary',
      description: 'Button Quinary',
    },
    {
      className: '&quinary',
      description: 'Button Quinary',
    },
    {
      className: '&senary',
      description: 'Button Senary',
    },
    {
      className: '&septenary',
      description: 'Button Septenary',
    },
    {
      className: '&octonary',
      description: 'Button Octonary',
    },
  ],
};
