export enum State {
  default,
  hover,
  active,
  disabled,
  focus,
}

export interface IStates {
  [index: number]: {
    label: string;
    style: string;
  };
}
