import { ACTIVE_EVENT_NAME } from './../../../constants/general.constants';
import useGetDocumentOnSnapshot from '../firebase/dexper/useGetDocumentOnSnapshot';
import { dexperFirestore } from '../../firebase';

const useGetDemFontStyle = () => {
  const [demStyleData] = useGetDocumentOnSnapshot<{
    style: string;
  }>(dexperFirestore.configuratorFontCss(ACTIVE_EVENT_NAME), true);
  return [demStyleData?.style] as const;
};

export default useGetDemFontStyle;
