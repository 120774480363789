import React, { useLayoutEffect, useState } from 'react';
import { Dex365Header } from '@dexper.events/product-atomic-ui';
import { IDex365HeaderContainer } from './types';
import { settings } from './settings';
import styled from 'styled-components';

const Wrapper = styled.div`
  .section-image {
    width: 200px;
    white-space: nowrap;
  }
`;

export const Dex365HeaderContainer = ({
  className,
  data,
  appSettings,
}: IDex365HeaderContainer) => {
  const [headerSettings, setHeaderSettings] = useState<any>();
  useLayoutEffect(() => {
    if (!data) {
      return;
    }
    setHeaderSettings(settings(data, (appSettings = appSettings || {})));
  }, [data]);
  return (
    <>
      {headerSettings && (
        <Wrapper>
          <Dex365Header settings={headerSettings} className={className} />
        </Wrapper>
      )}
    </>
  );
};
