import { Event365 } from '@dexper.events/dexper-datamodel';
import { GenericWithDocumentID } from '../../../../../types';

export function filterByEventTime(
  filterData: GenericWithDocumentID<Event365>[],
  selectedFilter: string
) {
  const currentTimeMs = Date.now();
  switch (selectedFilter) {
    case 'upcoming':
      filterData = filterData.filter(
        (event) => currentTimeMs < event.startTimeMs
      );
      break;
    case 'live':
      filterData = filterData.filter(
        (event) =>
          currentTimeMs >= event.startTimeMs && currentTimeMs <= event.endTimeMs
      );
      break;
    case 'concluded':
      filterData = filterData.filter(
        (event) => currentTimeMs > event.endTimeMs
      );
      break;
    default:
      return filterData;
  }
  return filterData;
}
