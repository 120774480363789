import React, { useContext } from 'react';
import { FilterList } from '@dexper.events/old-atomic-ui';
import { settings } from './settings';
import {
  FilterOption,
  FilterSelectionOverview,
  IFilterListContainer,
} from './types';
import GlobalContext from '../../../../../context/GlobalContext';

export const FilterListContainer = ({
  className,
  setEventTypeFilter,
  setCategoryParentFilter,
  setSelectedFilterValues,
  selectedFilterValues,
  setShowSidePanel,
}: IFilterListContainer) => {
  const globalData = useContext(GlobalContext);

  function resetEventTypeFilter() {
    setEventTypeFilter([]);
  }

  function resetCategoryParentFilter() {
    setCategoryParentFilter([]);
  }

  const filterListSettings = settings(globalData);

  function onFilterListSelect(
    selectionOverview: FilterSelectionOverview,
    selectedFilterValues: string[]
  ) {
    const { eventType, categoryParent } = selectionOverview;
    if (eventType) {
      const eventTypeSelected = eventType.filter(
        (option: FilterOption) => option.checked
      );
      if (eventTypeSelected.length) {
        setEventTypeFilter(eventTypeSelected);
      } else {
        resetEventTypeFilter();
      }
    } else {
      resetEventTypeFilter();
    }
    if (categoryParent) {
      const categoryParentSelected = categoryParent.filter(
        (option: FilterOption) => option.checked
      );

      if (categoryParentSelected.length) {
        setCategoryParentFilter(categoryParentSelected);
      } else {
        resetCategoryParentFilter();
      }
    } else {
      resetCategoryParentFilter();
    }

    setSelectedFilterValues(selectedFilterValues);
    setShowSidePanel(false);
  }
  return (
    <FilterList
      className={className}
      translation={filterListSettings?.translation}
      togglesGroups={filterListSettings?.toggleGroups}
      onSelect={onFilterListSelect}
      values={selectedFilterValues}
    />
  );
};
