import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FaqContainer } from './FaqToggle.css';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { RichText, RichTextSettings } from '../../Atoms/RichText/RichText';

import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { State } from '../../../definitions/States';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import classNames from 'classnames';
import { PrintVariations } from './variations';

export const faqToggleSettings = {
  text: {
    titleType: 'h6',
    title: 'How do I join a workshop that I registered for?',
    body: `![image](https://uicdn.toast.com/toastui/img/tui-editor-bi.png)#Awesome Editor!
      It has been _released as opensource in 2018_ and has ~~continually~~ evolved to **receive 10k GitHub ⭐️ Stars**.
      [Editor](https://github.com/nhn/tui.editor)`,
  },
  dropdownIcon: '/assets/icons/faq-dropdown.svg',
};

export const FaqToggleStory = {
  className: 'primary',
  settings: faqToggleSettings,
};

const FaqTogglePropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      titleType: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
    }).isRequired,
    dropdownIcon: PropTypes.string,
  }).isRequired,
};

export type FaqToggleTypes = InferProps<typeof FaqTogglePropTypes>;

export const FaqToggle: React.FC<FaqToggleTypes> = ({
  className,
  settings,
}) => {
  return (
    <FaqContainer className={classNames('InputField', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </FaqContainer>
  );
};

const allCategories = [
  CmsConfig.vmargin,
  CmsConfig.padding,
  CmsConfig.border,
  CmsConfig.fill,
  CmsConfig.box,
  CmsConfig.flexbox,
];
const atoms = [
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
  {
    atom: RichText,
    name: 'RichText~primary',
    description: 'RichText primary',
    settings: RichTextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button arrow',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
];

export const FaqToggleSettings: ISettings = {
  states: [State.default],
  include: allCategories,
  atoms: atoms,
  sections: [
    {
      className: 'section-heading-and-button',
      description: 'Section heading and button',
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'FaqToggle primary',
    },
  ],
};
