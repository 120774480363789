/* eslint-disable react/react-in-jsx-scope */
import { Button } from '../../Atoms/Button/Button';
import { Icon } from '../../Atoms/Icon/Icon';
import { Heading } from '../../Atoms/Heading/Heading';
import { Text } from '../../Atoms/Text/Text';
import {
  StyledSection,
  StyledHRSection,
  TextStyledSection,
  IconWrapper,
} from './SponsorCard.css';

export const PrintVariations = (args: any) => {
  const { settings } = args;
  return (
    <>
      <StyledSection className='section-image'>
        <Button
          className='primary'
          background={settings.sponsorImage}
          alt={settings.sponsorName}
        ></Button>
      </StyledSection>
      <StyledHRSection className='section-hr'>
        <hr />
      </StyledHRSection>
      <StyledSection className='section-details'>
        <TextStyledSection>
          <Heading className='primary' tag='h4'>
            {settings.text.title}{' '}
          </Heading>
          <Text className='primary' htmlFor=''>
            {settings.text.subtitle}
          </Text>
        </TextStyledSection>
        {settings.isInternalLink ? (
          <IconWrapper>
            <Icon
              className='primary'
              location={settings.iconInternalLink.icon}
              content={{ label: 'internal link' }}
            />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <Icon
              className='primary'
              location={settings.iconExternalLink.icon}
              content={{ label: 'external link' }}
            />
          </IconWrapper>
        )}
      </StyledSection>
    </>
  );
};
