import styled from 'styled-components';

interface StyledComponentTypes {
  open: boolean;
}

export const Container = styled.nav<StyledComponentTypes>`
  border-bottom: 1px solid #f1f1f1;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    padding: 15px 0;
  }
  a {
    color: ${({ open }) => (open ? 'var(--light)' : 'var(--dark)')};
  }
`;

export const RightNavigation = styled.ul<StyledComponentTypes>`
  margin: 0;
  height: 24px;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
  }

  a {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    flex-flow: column nowrap;
    background-color: #333;
    z-index: 1;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    width: 100%;
    max-width: 230px;
    height: 100vh;
    padding: 20px;
    margin: 0;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;
