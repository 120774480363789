import styled from 'styled-components';

export const StyledInput = styled.input`
  background: none;
  outline: none;
  border: none;

  &:focus {
    outline: none;
    color: black;
    border-color: cyan;
  }

  &:focus-visible {
    outline: none;
    border-color: cyan;
  }
`;

/* interface Background {
  default?: string;
  disabled?: string;
}
interface Placeholder {
  error?: string;
  default?: string;
  disabled?: string;
  focus?: string;
}
interface Text {
  hover?: string;
  error?: string;
  active?: string;
  focus?: string;
  disabled?: string;
  default?: string;
}
interface Border {
  hover?: string;
  active?: string;
  default?: string;
  error?: string;
}
interface Var {
  backgroundColor?: Background;
  textColor?: Text;
  borderColor?: Border;
  placeholderTextColor?: Placeholder;
}
interface T {
  variant?: Var;
  error?: boolean;
} */

// export const Container = styled.div``;

/* export const InputWrapper = styled.input<T>`
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: 9px 18px;
  background-color: ${({ variant }) => variant?.backgroundColor?.default};
  color: ${({ error, variant }) =>
    variant?.textColor?.[error ? 'error' : 'default']};

  line-height: 25px;
  outline: none;
  border-radius: 0;
  border: 1px solid
    ${({ error, variant }) =>
      variant?.borderColor?.[error ? 'error' : 'default']};

  &::placeholder {
    color: ${({ variant, error, disabled }) => {
      if (error) {
        return variant?.placeholderTextColor?.error;
      } else if (disabled) {
        return variant?.placeholderTextColor?.disabled;
      } else {
        return variant?.placeholderTextColor?.default;
      }
    }};
    opacity: 1;
  }

  &:disabled {
    background-color: ${({ variant }) => variant?.backgroundColor?.disabled};
    color: ${({ variant }) => variant?.textColor?.disabled};
  }

  &:hover {
    color: ${({ variant }) => variant?.textColor?.hover};
    border-color: ${({ variant, disabled }) =>
      disabled ? 'transparent' : variant?.borderColor?.hover};
  }

  &:not(:disabled) {
    &:focus {
      outline: none;
      color: ${({ variant, error }) =>
        error ? variant?.textColor?.error : variant?.textColor?.active};
      border-color: ${({ variant }) => variant?.borderColor?.active};
    }

    &:focus-visible {
      outline: none;
      border-color: ${({ variant }) => variant?.borderColor?.active};
    }

    &::placeholder {
      color: ${({ variant }) => variant?.placeholderTextColor?.focus};
    }
  }

  & + .error {
    margin-top: -10px;
  }
`; */
