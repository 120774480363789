export const BASE_PATH = '/';
export const EVENT_PATH = '/events';
export const EVENT_DETAIL_BASE_PATH = '/events/detail/:slug';
export const ON_DEMAND_OVERVIEW_PATH = '/on-demand';
export const ON_DEMAND_DETAIL_PATH = '/on-demand/:vodOrSerieSlug';
export const RESOURCES_PATH = '/resources';
export const FORM_PATH = '/form';

export const NOT_FOUND_BASE_PATH = '/not-found';

export const TEST_CAROUSEL_PATH = '/test/carousel';
export const TEST_COMPONENTS_PATH = '/test/components';
