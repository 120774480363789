import { css } from 'styled-components';
interface ShapeProps {
  color: string;
}

export default css<ShapeProps>`
  width: 20px;
  height: 10px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: block;
`;
