import styled from 'styled-components';

interface IImagePlaceholder {
  width?: string;
  height?: string;
}

export const ImagePlaceholder = styled.div<IImagePlaceholder>`
  width: ${({ width }) => width || '100px'};
  height: ${({ height }) => height || '100px'};
  background-color: #ccc;
`;
