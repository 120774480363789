/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { DetailBodySettings, IDetailBodyContainer } from './types';
import { settings } from './settings';
import {
  Button,
  Text,
  RichText,
  Heading,
} from '@dexper.events/product-atomic-ui';
import { useHistory } from 'react-router-dom';
import { StyledContainer, Wrapper, InfoWrapper } from './styles';
import HubspotForm from '../../../../../components/Hubspot/HubspotForm';
import { ICON_ARROW_LEFT } from '../../../../../constants/storage.constants';
import { RichTextWrapper } from './styles';
import GlobalContext from '../../../../../context/GlobalContext';
import { currentLanguage } from '../../../../../config/language.config';

export const DetailBodyContainer = ({
  pageContent,
  detailData,
}: IDetailBodyContainer) => {
  const globalData = useContext(GlobalContext);
  const history = useHistory();
  const [detailSettings, setDetailSettings] = useState<DetailBodySettings>();

  useEffect(() => {
    if (!detailData) {
      return;
    }
    setDetailSettings(settings(detailData));
  }, [detailData]);
  if (detailSettings) {
    const {
      text: { when, eventType, where, description },
    } = detailSettings;
    const eventTypes = globalData?.config?.eventTypes || {};
    const eventTypeLabel =
      eventTypes[eventType]?.language[currentLanguage]?.label;

    const renderForm = () => {
      if (detailData.form && detailData.form.type === 'hubspot')
        return (
          <HubspotForm
            region={detailData.form.hubspot.region}
            portalId={detailData.form.hubspot.portalId}
            formId={detailData.form.hubspot.formId}
            target="#hubspot-form"
          />
        );
    };

    return (
      <Wrapper>
        <Button
          onClick={() => {
            history.push('/events');
          }}
          image={ICON_ARROW_LEFT}
          className="back-button"
        >
          {pageContent?.backButtonLabel}
        </Button>

        <StyledContainer>
          <section className="section-left">
            <InfoWrapper>
              <Text className="primary">{pageContent?.whenLabel}</Text>
              <Heading className="primary" tag="h5">
                {when}
              </Heading>
            </InfoWrapper>
            <InfoWrapper>
              <Text className="primary">{pageContent?.typeLabel}</Text>
              <Heading className="secondary" tag="h5">
                {eventTypeLabel}
              </Heading>
            </InfoWrapper>
            <InfoWrapper>
              <Text className="primary"> {pageContent?.whereLabel}</Text>
              <Heading className="secondary" tag="h5">
                {where}
              </Heading>
            </InfoWrapper>
          </section>
          <section className="section-right">
            <RichTextWrapper>
              <RichText className="primary">{description}</RichText>
            </RichTextWrapper>
            {renderForm()}
          </section>
        </StyledContainer>
      </Wrapper>
    );
  } else {
    return null;
  }
};
