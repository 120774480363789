import styled from 'styled-components';
import PropTypes from 'prop-types';

interface CardContainerProps {
  background: string;
}

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  padding: var(--padding);
  background: linear-gradient(
      rgba(20, 28, 40, 0.9) 0%,
      rgba(20, 28, 40, 0.3) 52.08%,
      rgba(20, 28, 40, 0.9) 100%
    ),
    left/cover ${({ background }) => `url(${background})`};

  .section-hover {
    max-height: 0;
    overflow: hidden;
    transition: max-height 250ms ease-in-out;
  }

  :hover .section-hover {
    max-height: 100px;
  }
`;
