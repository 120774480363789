import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './hashicorpFooter.css';
import { Row } from '../../Layout/Row/Row';
const HashiCorp_Logo = './images/footer/HashiCorp_Logo.svg';
const Octocat_Logo = './images/footer/Octocat_Logo.svg';
const Twitter_Logo = './images/footer/Twitter_Logo.svg';
const Youtube_Logo = './images/footer/Youtube_Logo.svg';
const Gplus_Logo = './images/footer/Gplus_Logo.svg';
const LinkedIn_Logo = './images/footer/LinkedIn_Logo.svg';
const Terra_Logo = './images/footer/Terraform_Logo.svg';
const Vault_Logo = './images/footer/Vault_Logo.svg';
const Consul_Logo = './images/footer/Consul_Logo.svg';
const Nomad_Logo = './images/footer/Nomad_Logo.svg';

// @ts-ignore
export const HashicorpFooter = ({ footerLinks }) => {
  // @ts-ignore
  return (
    <>
      <SC.LinksWrapper>
        <Row>
          <SC.LinksListUl>
            <SC.StyledItem>
              <SC.Link
                href={footerLinks.preFooterSection.contact}
                target='_blank'
                rel='noopener noreferrer'
              >
                Event Support
              </SC.Link>
            </SC.StyledItem>
            <SC.StyledItem>
              <SC.Link
                href={footerLinks.preFooterSection.viewAllEvents}
                target='_blank'
                rel='noopener noreferrer'
              >
                Code of Conduct
              </SC.Link>
            </SC.StyledItem>
            <SC.StyledItem>
              <SC.Link
                href={footerLinks.preFooterSection.termsAndConditions}
                rel='noopener noreferrer'
                target='_blank'
              >
                Terms & Conditions
              </SC.Link>
            </SC.StyledItem>
            <SC.StyledItem>
              <SC.Link
                href={footerLinks.preFooterSection.hashiconfTag}
                target='_blank'
                rel='noopener noreferrer'
              >
                #HashiConf
              </SC.Link>
              <SC.Link
                href={footerLinks.preFooterSection.hashiconfTwitter}
                target='_blank'
                rel='noopener noreferrer'
              >
                @HashiConf
              </SC.Link>
            </SC.StyledItem>
          </SC.LinksListUl>
        </Row>
      </SC.LinksWrapper>
      <SC.Footer>
        <Row>
          <SC.FooterTop>
            <SC.HashicorpImage src={HashiCorp_Logo} alt='' />
            <SC.SocialLinks>
              {footerLinks.socialLinks.github && (
                <SC.SocialLinksListItem>
                  <a
                    href={footerLinks.socialLinks.github}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <SC.SocialLinksImg src={Octocat_Logo} alt='Octocat' />
                  </a>
                </SC.SocialLinksListItem>
              )}
              {footerLinks.socialLinks.twitter && (
                <SC.SocialLinksListItem>
                  <a
                    href={footerLinks.socialLinks.twitter}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <SC.SocialLinksImg src={Twitter_Logo} />
                  </a>
                </SC.SocialLinksListItem>
              )}
              {footerLinks.socialLinks.youtube && (
                <SC.SocialLinksListItem>
                  <a
                    href={footerLinks.socialLinks.youtube}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <SC.SocialLinksImg src={Youtube_Logo} />
                  </a>
                </SC.SocialLinksListItem>
              )}
              {footerLinks.socialLinks.googleplus && (
                <SC.SocialLinksListItem>
                  <a
                    href={footerLinks.socialLinks.googleplus}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <SC.SocialLinksImg src={Gplus_Logo} />
                  </a>
                </SC.SocialLinksListItem>
              )}
              {footerLinks.socialLinks.linkedIn && (
                <SC.SocialLinksListItem>
                  <a
                    href={footerLinks.socialLinks.linkedIn}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <SC.SocialLinksImg src={LinkedIn_Logo} />
                  </a>
                </SC.SocialLinksListItem>
              )}
            </SC.SocialLinks>
          </SC.FooterTop>
          <SC.FooterWrapper>
            <SC.FooterWrapperRow>
              <SC.Coli>
                <SC.Sample>
                  <SC.RowFlex>
                    <SC.ColUl>
                      <SC.LeftBlockFooterListItem>
                        <SC.LeftBlockFooterLink
                          href={footerLinks.provision}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <SC.FooterSpan>
                            <div>PROVISION</div>
                          </SC.FooterSpan>
                          <SC.FooterPara>
                            Cloud infrastructure automation
                          </SC.FooterPara>
                          <div>
                            <SC.Image src={Terra_Logo} />
                          </div>
                        </SC.LeftBlockFooterLink>
                      </SC.LeftBlockFooterListItem>
                    </SC.ColUl>
                    <SC.ColUl>
                      <SC.LeftBlockFooterListItem>
                        <SC.LeftBlockFooterLink
                          href={footerLinks.secure}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <SC.FooterSpan>SECURE</SC.FooterSpan>
                          <SC.FooterPara>
                            Cloud security automation
                          </SC.FooterPara>
                          <div>
                            <SC.Image src={Vault_Logo} />
                          </div>
                        </SC.LeftBlockFooterLink>
                      </SC.LeftBlockFooterListItem>
                    </SC.ColUl>
                  </SC.RowFlex>
                  <SC.RowFlex>
                    <SC.ColUl>
                      <SC.LeftBlockFooterListItem>
                        <SC.LeftBlockFooterLink
                          href={footerLinks.connect}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <SC.FooterSpan>CONNECT</SC.FooterSpan>
                          <SC.FooterPara>
                            Cloud networking automation
                          </SC.FooterPara>
                          <div>
                            <SC.Image src={Consul_Logo} />
                          </div>
                        </SC.LeftBlockFooterLink>
                      </SC.LeftBlockFooterListItem>
                    </SC.ColUl>
                    <SC.ColUl>
                      <SC.LeftBlockFooterListItem>
                        <SC.LeftBlockFooterLink
                          href={footerLinks.run}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <SC.FooterSpan>RUN</SC.FooterSpan>
                          <SC.FooterPara>
                            Cloud application automation
                          </SC.FooterPara>
                          <div>
                            <SC.Image src={Nomad_Logo} />
                          </div>
                        </SC.LeftBlockFooterLink>
                      </SC.LeftBlockFooterListItem>
                    </SC.ColUl>
                  </SC.RowFlex>
                </SC.Sample>
              </SC.Coli>

              <SC.Col>
                <SC.Row>
                  <SC.Col>
                    <SC.FooterListSpan>Products</SC.FooterListSpan>
                    <SC.ListFooterUnorderedList>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.terraform}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Terraform
                        </SC.FooterAnchorLinks>
                      </li>

                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.vault}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Vault
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.consul}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Consul
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.nomad}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Nomad
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.vagrant}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Vagrant
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.packer}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Packer
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.boundary}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Boundary
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.waypoint}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Waypoint
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.products.sentinel}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Sentinel
                        </SC.FooterAnchorLinks>
                      </li>
                    </SC.ListFooterUnorderedList>
                  </SC.Col>
                  {/* @ts-ignore */}
                  <SC.Col sm={4} xs={6}>
                    <SC.FooterListSpan>RESOURCES</SC.FooterListSpan>
                    <SC.ListFooterUnorderedList>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.blog}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Blog
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.community}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Community
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.events}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Events
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.integrations}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Integrations
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.library}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Library
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.partners}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Partners
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.podcast}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Podcast
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.support}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Support
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.resources.traninig}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Training
                        </SC.FooterAnchorLinks>
                      </li>
                    </SC.ListFooterUnorderedList>
                  </SC.Col>
                  {/* @ts-ignore */}
                  <SC.Col sm={4} xs={6}>
                    <SC.FooterListSpan>COMPANY</SC.FooterListSpan>
                    <SC.ListFooterUnorderedList>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.company.about}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          About Us
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.company.jobs}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          We&apos;re Hiring
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.company.pressCenter}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Press Center
                        </SC.FooterAnchorLinks>
                      </li>
                      <li>
                        <SC.FooterAnchorLinks
                          href={footerLinks.company.contactUs}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Contact Us
                        </SC.FooterAnchorLinks>
                      </li>
                    </SC.ListFooterUnorderedList>
                  </SC.Col>
                </SC.Row>
              </SC.Col>
            </SC.FooterWrapperRow>
          </SC.FooterWrapper>
          <SC.FooterBottom>
            <SC.SystemCopyright>
              <SC.CopyrightLinksListItem>
                <SC.FooterAnchorLinks
                  href={footerLinks.systemStatus}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  System Status
                </SC.FooterAnchorLinks>
              </SC.CopyrightLinksListItem>
              <SC.CopyrightLinksListItem>
                <SC.FooterAnchorLinks
                  href={footerLinks.termsOfUse}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of Use
                </SC.FooterAnchorLinks>
              </SC.CopyrightLinksListItem>

              <SC.CopyrightLinksListItem>
                <SC.FooterAnchorLinks
                  href={footerLinks.security}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Security
                </SC.FooterAnchorLinks>
              </SC.CopyrightLinksListItem>

              <SC.CopyrightLinksListItem>
                <SC.FooterAnchorLinks
                  href={footerLinks.privacy}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy
                </SC.FooterAnchorLinks>
              </SC.CopyrightLinksListItem>
            </SC.SystemCopyright>
          </SC.FooterBottom>
        </Row>
      </SC.Footer>
    </>
  );
};

HashicorpFooter.propTypes = {
  footerLinks: PropTypes.object,
};

HashicorpFooter.defaultProps = {
  footerLinks: {},
  logoLink: null,
  skeleton: false,
};
