import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { ProfileCardContainer } from './ProfileCard.css';
import classNames from 'classnames';
import { Box } from '../../Layout/Box/Box';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { ShapeTextPropTypes } from '../../../definitions/PropTypes';
import { Heading } from '../../Atoms/Heading/Heading';
import { Text } from '../../Atoms/Text/Text';
import { ImagePlaceholder } from '../../Dummy/ImagePlaceholder';

const ProfileCardPropTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(ShapeTextPropTypes).isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export type ProfileCardTypes = InferProps<typeof ProfileCardPropTypes>;

export const ProfileCard: React.FC<ProfileCardTypes> = ({
  className,
  content: { name, jobTitle },
}) => (
  <ProfileCardContainer
    open={true}
    className={classNames('ProfileCard', className)}
  >
    <Box className='row inner-box'>
      <Box className='image'>
        <ImagePlaceholder width='140px' height='140px' />
      </Box>
      <Box className='column personal'>
        <Heading tag='h4' className='h4'>
          {name}
        </Heading>
        <Text className='job-title'>{jobTitle}</Text>
      </Box>
    </Box>
  </ProfileCardContainer>
);

export const ProfileCardSettings: ISettings = {
  variants: [
    {
      className: '',
      description: 'ProfileCard outer box',
      include: [CmsConfig.border, CmsConfig.color, CmsConfig.flexbox],
    },
    {
      className: 'inner-box',
      description: 'Profile inner box',
      include: [CmsConfig.color, CmsConfig.flexbox],
    },
    {
      className: 'personal',
      description: 'Personal box',
      include: [CmsConfig.padding],
    },
    {
      className: 'image',
      description: 'Profile photo box',
      include: [CmsConfig.color, CmsConfig.border, CmsConfig.padding],
    },
    {
      className: 'name',
      default: [{ [CmsConfig.text]: 'h4' }, { [CmsConfig.color]: '#cc0' }],
      description: 'Name',
      include: [CmsConfig.text, CmsConfig.color],
    },
    {
      className: 'title',
      description: 'Title',
      include: [CmsConfig.text, CmsConfig.color],
    },
  ],
};

ProfileCard.propTypes = ProfileCardPropTypes;
