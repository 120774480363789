import styled from 'styled-components';

export const Wrapper = styled.div`
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 10% 10% 25% 10%;

  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );

  .Dex365NotFoundPage {
    align-self: center;
  }
`;
