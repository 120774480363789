/* eslint-disable react/react-in-jsx-scope */
import { Link } from '../../Atoms/Link/Link';
// import { Icon } from '../../Atoms/Icon/Icon';
import {
  StyledSectionLogo,
  StyledSectionLinks,
  // StyledSectionLogoInvisible,
} from './Navigation365.css';

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;
  switch (variation) {
    case 'primary':
      return (
        <>
          <StyledSectionLogo className='section-logo'>
            {/* <Icon
              className='primary'
              content={settings.logo.content}
              location={settings.logo.location}
              viewbox={settings?.logo.viewbox}
              imageWidth={settings?.logo.imageWidth}
              imageHeight={settings?.logo.imageHeight}
            ></Icon> */}
            <Link
              className={settings.logoLink.className}
              anchorType={settings.logoLink.anchorType}
              targetBlank={settings.logoLink.targetBlank}
              callback={settings.logoLink.callback}
              location={settings.logoLink.location}
            >
              <img
                src={settings.logo.location}
                alt='logo'
                width='262'
                height='37'
              />
            </Link>
          </StyledSectionLogo>
          <StyledSectionLinks className='section-links'>
            {settings.links.map((link: any) => {
              return (
                <>
                  <Link
                    className={link.className}
                    anchorType={link.anchorType}
                    targetBlank={link.targetBlank}
                    callback={link.callback}
                    label={link.label}
                    location={link.location}
                  >
                    {link.label}
                  </Link>
                </>
              );
            })}
          </StyledSectionLinks>
          <div>&nbsp;</div>
          {/* <StyledSectionLogoInvisible>
            <Icon
              className='primary'
              content={settings.logo.content}
              location={settings.logo.location}
            ></Icon>
          </StyledSectionLogoInvisible> */}
        </>
      );
      break;
    default:
      return <p>no card variation selected</p>;
  }
};
