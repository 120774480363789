import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { GridContainer } from './Grid.css';
import classNames from 'classnames';

const GridPropTypes = {
  children: PropTypes.any.isRequired,
  columns: PropTypes.number,
  className: PropTypes.string,
  minWidthTablet: PropTypes.string,
  minWidthDesktop: PropTypes.string,
};

export type GridType = InferProps<typeof GridPropTypes>;

export const Grid: React.FC<GridType> = ({
  className,
  children,
  columns,
  minWidthTablet,
  minWidthDesktop,
}) => (
  <GridContainer
    className={classNames('Grid', className)}
    columns={columns || 4}
    minWidthTablet={minWidthTablet || ''}
    minWidthDesktop={minWidthDesktop || ''}
  >
    {children}
  </GridContainer>
);

Grid.propTypes = GridPropTypes;
