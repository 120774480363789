import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import classNames from 'classnames';

export interface LanguageType {
  noAnswerWithEmail?: string;
  noAnswerWithoutEmail?: string;
  onlineTitle?: string;
  offlineTitle?: string;
  agentLabel?: string;
  onlineMessagePlaceholder?: string;
  offlineGreeting?: string;
  offlineEmailPlaceholder?: string;
  offlineMessagePlaceholder?: string;
  offlineNamePlaceholder?: string;
  offlineSendButton?: string;
  offlineThankYouMessage?: string;
  requireInfoGreeting?: string;
  requireInfoSubmitBtn?: string;
  requestScreenshotText?: string;
  requestScreenshotAllowLabel?: string;
  requestScreenshotDeclineLabel?: string;
  defaultOkBtnLabel?: string;
  defaultSendBtnLabel?: string;
  defaultCancelBtnLabel?: string;
  defaultYesBtnLabel?: string;
  defaultNoBtnLabel?: string;
  conversationEndLabel?: string;
  conversationRatingLabel?: string;
  conversationRatingThankYou?: string;
  conversationRatingPlaceholder?: string;
  conversationEndTranscriptPlaceholder?: string;
  conversationEndConfirmationQuestion?: string;
  conversationTranscriptSentThankYou?: string;
  conversationEndMenuLinkLabel?: string;
  autoResponseMessage?: string;
}
const english = {
  noAnswerWithEmail:
    'Sorry no one has responded yet. We have your email on file if you need to leave or you can continue to wait.',
  noAnswerWithoutEmail:
    'Sorry no one has responded yet. Do you mind typing your email in chat below so we can reach you in case you need to leave?',
  onlineTitle: 'How can we help you?',
  offlineTitle: 'Contact Us',
  agentLabel: 'eleVation Support',
  onlineMessagePlaceholder: 'Type message here...',
  offlineGreeting:
    'Sorry we are away, but we would love to hear from you and chat soon!',
  offlineEmailPlaceholder: 'Email',
  offlineMessagePlaceholder: 'Your message here',
  offlineNamePlaceholder: 'Name (optional but helpful)',
  offlineSendButton: 'Send',
  offlineThankYouMessage: 'Thanks for your message. We will be in touch soon!',
  requireInfoGreeting:
    'Need support? Enter your name and email to start live chatting!',
  requireInfoSubmitBtn: 'Start',
  requestScreenshotText:
    'Operator would like to take a screenshot of your browser. Confirm below.',
  requestScreenshotAllowLabel: 'Take screenshot',
  requestScreenshotDeclineLabel: 'Decline',
  defaultOkBtnLabel: 'Ok',
  defaultSendBtnLabel: 'Send',
  defaultCancelBtnLabel: 'Cancel',
  defaultYesBtnLabel: 'Yes',
  defaultNoBtnLabel: 'No',
  conversationEndLabel: 'Your chat session has ended. Thanks for chatting!',
  conversationRatingLabel: 'How would you rate this chat?',
  conversationRatingThankYou: 'Thanks for rating your chat session!',
  conversationRatingPlaceholder: 'How can we improve?',
  conversationEndTranscriptPlaceholder: 'Email to send transcript of chat',
  conversationEndConfirmationQuestion:
    'Are you sure you want to end this chat?',
  conversationTranscriptSentThankYou:
    'Thanks! You will receive your transcript shortly.',
  conversationEndMenuLinkLabel: 'End Chat',
  autoResponseMessage:
    'Question? Just type it below and we are online and ready to answer.',
};

export const ChatlioStory = {
  translation: english,
};
export interface ChatlioAtomType {
  translation?: LanguageType;
  className?: string;
}

function toggleChatlio() {
  // @ts-ignore
  window._chatlio.showOrHide();
}

function configChatlio(translation: any) {
  // @ts-ignore
  window._chatlio.configure(translation);
}

export const Chatlio: React.FC<ChatlioAtomType> = ({
  translation,
  className,
}) => {
  useEffect(() => {
    configChatlio(translation);
  }, [translation]);

  return (
    <div className={classNames('Chatlio', className)}>
      <Button
        className='primary'
        onClick={toggleChatlio}
        image='/assets/icons/chatlio-icon.svg'
        alt='chatlio-icon'
      />
    </div>
  );
};

const atoms = [
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button chatlio',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
];

export const ChatlioSettings: ISettings = {
  states: [State.default, State.hover, State.active],
  include: [CmsConfig.padding, CmsConfig.frame],
  atoms,
  variants: [
    {
      className: '&primary',
      description: 'Chatlio primary',
    },
  ],
};

Chatlio.propTypes = {
  translation: PropTypes.object,
};

Chatlio.defaultProps = {
  translation: {},
};
