import { Event365 } from '@dexper.events/dexper-datamodel';
import { GenericWithDocumentID } from '../../../../../types';
import { FilterOption } from '../../containers/FilterList/types';

export function filterByEventType(
  filterData: GenericWithDocumentID<Event365>[],
  selectedFilters: FilterOption[]
) {
  let filteredData: GenericWithDocumentID<Event365>[] = filterData;
  if (selectedFilters.length > 0) {
    filteredData = filterData.filter((event: GenericWithDocumentID<Event365>) =>
      selectedFilters.some((selectedFilterValue: FilterOption) =>
        event.eventType.includes(selectedFilterValue.value)
      )
    );
  }
  return filteredData;
}
