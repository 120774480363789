import { FirebaseCustomToken } from "./AllModels";

export type DexperFunctionBase = {
    errorKeys: string;
    name: string;
};
export type FunctionDef<T extends DexperFunctionBase> = {
    errors: {
        [key in T["errorKeys"]]: {
            errorCode: FunctionsErrorCode;
            message: string;
        };
    };
    name: T["name"];
};

export const FirebaseCustomTokenDef: FunctionDef<FirebaseCustomToken> = {
    name: "firebaseCustomToken",
    errors: {
        hubspotAlreadyUsed: {
            errorCode: "invalid-argument",
            message: "This user was already created using different login credentials.",
        },
        hubspotInvalid: {
            errorCode: "invalid-argument",
            message: "Creating an account requires hubspot details, log in via the link in your registration email.",
        },
        fairtecAlreadyUsed: {
            errorCode: "invalid-argument",
            message: "This user was already created using different login credentials.",
        },
        fairtecInvalid: {
            errorCode: "invalid-argument",
            message: "Creating an account requires hubspot details, log in via the link in your registration email.",
        },
        invalid: {
            errorCode: "unauthenticated",
            message: "Invalid credentials.",
        },
        accountDeleted: {
            errorCode: "permission-denied",
            message: "Your account is deleted.",
        },
    },
};

export type FunctionsErrorCode =
    | "ok"
    | "cancelled"
    | "unknown"
    | "invalid-argument"
    | "deadline-exceeded"
    | "not-found"
    | "already-exists"
    | "permission-denied"
    | "resource-exhausted"
    | "failed-precondition"
    | "aborted"
    | "out-of-range"
    | "unimplemented"
    | "internal"
    | "unavailable"
    | "data-loss"
    | "unauthenticated";
