import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { BoxContainer } from './Box.css';
import classNames from 'classnames';

const BoxPropTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export type BoxType = InferProps<typeof BoxPropTypes>;

export const Box: React.FC<BoxType> = ({ className, children }) => (
  <BoxContainer className={classNames('Row', className)}>
    {children}
  </BoxContainer>
);

Box.propTypes = BoxPropTypes;
