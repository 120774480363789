import { LOGO_FOOTER } from './../../constants/storage.constants';
import { IFooter365Settings } from './types';

export const settings = () => {
  const settings: IFooter365Settings = {
    links: [
      {
        className: 'primary',
        anchorType: 'external',
        targetBlank: true,
        callback: undefined,
        label: 'Home',
        location: 'https://www.dexper.io',
        children: null,
      },
      {
        className: 'primary',
        anchorType: 'external',
        targetBlank: true,
        callback: undefined,
        label: 'About',
        location: 'https://www.dexper.io',
        children: null,
      },
      {
        className: 'primary',
        anchorType: 'external',
        targetBlank: true,
        callback: undefined,
        label: 'Service',
        location: 'https://www.dexper.io',
        children: null,
      },
      {
        className: 'primary',
        anchorType: 'external',
        targetBlank: true,
        callback: undefined,
        label: 'Contact us',
        location: 'https://www.dexper.io',
        children: null,
      },
    ],
    logo: {
      content: {
        label: 'Remote',
      },
      location: LOGO_FOOTER,
      className: 'primary',
    },
    logoLink: {
      location: 'https://www.remote.com',
      anchorType: 'external',
      targetBlank: true,
      className: 'footer-logo-link',
      callback: () => {
        // callback
      },
    },
    callbacks: {},
  };
  return settings;
};
