import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Footer365Container } from './Footer365.css';
import classNames from 'classnames';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';

import { Link, LinkSettings } from '../../Atoms/Link/Link';
import { Icon, IconSettings } from '../../Atoms/Icon/Icon';

/* 
Implementation example:
=======================

import {Footer365Story} from '@dexper.events/product-atomic-ui';

const {settings : Footer365Settings} = Footer365Story;

 <div className="AnotherPageTest"> 
    <div className="section-bottom"> 
        <Footer365 settings={Footer365Settings} className="primary"></$> 
     </div>
 </div> 

*/

export const footer365Settings = {
  text: {},
  links: [
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Home',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'About',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Service',
      location: '#',
      children: null,
    },
    {
      className: 'primary',
      anchorType: 'internal',
      targetBlank: true,
      callback: null,
      label: 'Contact us',
      location: '#',
      children: null,
    },
  ],
  logo: {
    content: {
      label: 'Remote',
    },
    location: '/assets/icons/remote-logo.svg',
    className: 'primary',
  },
  callbacks: {},
  // more props here:
};

export const Footer365Story = {
  settings: footer365Settings,
  className: 'primary',
};

const Footer365PropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({}).isRequired,
    logo: PropTypes.shape({
      content: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
      location: PropTypes.string.isRequired,
      className: PropTypes.string,
    }).isRequired,
    logoLink: PropTypes.shape({
      location: PropTypes.string.isRequired,
      anchorType: PropTypes.string,
      targetBlank: PropTypes.bool,
      className: PropTypes.string,
      callback: PropTypes.func,
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string.isRequired,
        anchorType: PropTypes.string,
        targetBlank: PropTypes.bool,
        callback: PropTypes.func,
        label: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        children: PropTypes.string,
      }),
    ).isRequired,
  }),
};

export type Footer365Types = InferProps<typeof Footer365PropTypes>;

export const Footer365: React.FC<Footer365Types> = ({
  className,
  settings,
}) => {
  return (
    <Footer365Container className={classNames('Footer365', className)}>
      <PrintVariations
        className={className}
        settings={settings}
      ></PrintVariations>
    </Footer365Container>
  );
};

const atoms = [
  {
    atom: Link,
    name: 'Link~primary',
    description: 'Link navbar',
    settings: LinkSettings,
    atomVariant: '&primary',
  },
  {
    atom: Icon,
    name: 'Icon~primary',
    description: 'Icon logo',
    settings: IconSettings,
    atomVariant: '&primary',
  },
];
//more atoms here:

export const Footer365Settings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-logo',
      description: 'Section logo',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
    {
      className: 'section-links',
      description: 'Section links',
      include: [
        CmsConfig.fill,
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'Footer365 primary',
    },
  ],
};

Footer365.propTypes = Footer365PropTypes;
