import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { CardSpeakerContainer } from './CardSpeaker.css';
import classNames from 'classnames';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Button, ButtonSettings } from '../../Atoms/Button/Button';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
import { Category, CategorySettings } from '../../Atoms/Category/Category';

export const cardSpeakerSettings = {
  text: {
    titleType: 'h1',
    title: 'Gustavo Schleifer',
    description: 'Founder & CTO | Dexper',
  },
  callbacks: {
    onInfo: (e: any) => {
      e.stopPropagation();
      console.log('onInfo fired');
    },
    onClick: (...args: any[]) => {
      console.log('onClick fired', args);
    },
  },
  categories: [
    {
      className: 'primary',
      shape: 'circle',
      children: 'Dexper',
      color: '#00B568',
      label: 'Child 1',
    },
    {
      className: 'primary',
      shape: 'circle',
      children: 'Dexper',
      color: '#00B568',
      label: 'Child 2',
    },
  ],
  speakerImg: '/assets/images/highlightedSpeaker.png',
  buttonIcon: '/assets/icons/arrow-right.svg',
};

export const CardSpeakerStory = {
  settings: cardSpeakerSettings,
  className: 'primary',
};

const CardSpeakerPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onInfo: PropTypes.func.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    categories: PropTypes.array.isRequired,
    speakerImg: PropTypes.string.isRequired,
    buttonIcon: PropTypes.string.isRequired,
  }),
};

export type CardSpeakerTypes = InferProps<typeof CardSpeakerPropTypes>;

export const CardSpeaker: React.FC<CardSpeakerTypes> = ({
  className,
  settings,
}) => (
  <CardSpeakerContainer
    className={classNames('CardSpeaker', className, settings)}
    onClick={settings?.callbacks.onClick}
  >
    <PrintVariations
      className={className}
      settings={settings}
    ></PrintVariations>
  </CardSpeakerContainer>
);

const atoms = [
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
  {
    atom: Category,
    name: 'Category~primary',
    description: 'Category primary',
    settings: CategorySettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text primary',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~primary',
    description: 'Button primary',
    settings: ButtonSettings,
    atomVariant: '&primary',
  },
  {
    atom: Button,
    name: 'Button~secondary',
    description: 'Button secondary',
    settings: ButtonSettings,
    atomVariant: '&secondary',
  },
];

export const CardSpeakerSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
    CmsConfig.cursor,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-image',
      description: 'Section image',
      include: [
        CmsConfig.vmargin,
        CmsConfig.hmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.cursor,
      ],
    },
    {
      className: 'section-text',
      description: 'Section text',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-button',
      description: 'Section button',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.frame,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'CardSpeaker primary',
    },
  ],
};

CardSpeaker.propTypes = CardSpeakerPropTypes;
