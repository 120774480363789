import styled from 'styled-components';
import { Slide } from 'pure-react-carousel';

export const StyledContainer = styled.div`
  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );
`;

export const StyledSlide = styled(Slide)``;
