import styled from 'styled-components';
import { Icon } from '../../Atoms/Icon/Icon';

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DownloadImage = styled.img`
  width: 100%;
  margin-top: 16px;
`;

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
`;

export const StyledIcon = styled(Icon)`
  margin-left: 8px;
`;
