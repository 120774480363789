import React from 'react';
import PropTypes from 'prop-types';
import { CardEventContainer } from './CardEvent.css';
import classNames from 'classnames';
import { Heading, HeadingSettings } from '../../Atoms/Heading/Heading';
import { Text, TextSettings } from '../../Atoms/Text/Text';
import { Badge, BadgeSettings } from '../../Atoms/Badge/Badge';
import { Category, CategorySettings } from '../../Atoms/Category/Category';
import { CmsConfig } from '../../../definitions/CmsConfig';
import { ISettings } from '../../../definitions/ISettings';
import { State } from '../../../definitions/States';
import { PrintVariations } from './variations';
import { categoriesPropTypes } from '../../../definitions/PropTypes';

/* 
Implementation example:
=======================

import {CardEventStory, CardEventStory} from '@dexper.events/product-atomic-ui';

 <div className="AnotherPageTest">
    <div className="section-bottom" style={{backgroundImage : url('./assets/images/haarlem.jpeg')}}>
        <CardEvent settings={CardEventStory.settings} className="primary"></CardEvent>
    </div>
</div>
*/

export const cardEventSettings = {
  text: {
    eventType: 'Online Event',
    badgeLabel: 'Upcoming', // empty string to not show badge
    dateTime: 'April 11, 2021 | 3:00pm - 7:00pm CEST',
    titleType: 'h1',
    title: 'Dexper Digital 2021',
    description:
      'Innovation, R&D, and investments to achieve seamless global connectivity are all fueled by a balanced framework for standardization.',
  },
  callbacks: {
    onClick: (...args: any[]) => {
      console.log('onClick fired', args);
    },
  },
  categories: [
    {
      className: 'primary',
      shape: 'circle',
      children: 'Fun',
      color: '#FF8330',
    },
    {
      className: 'primary',
      shape: 'circle',
      children: 'Dexper',
      color: '#1881FF',
    },
  ],
  badgeVariant: 'primary', // primary = upcoming | secondary = concluded | tertiary = live
  backgroundImage: '/assets/images/eventCard-1.jpg',
  firstGradientColor: 'rgba(20, 28, 40, 0.2)',
  secondGradientColor: 'rgba(20, 28, 40, 0.75)',
};

export const CardEventStory = {
  settings: cardEventSettings,
  className: 'primary',
};

const CardEventPropTypes = {
  className: PropTypes.string,
  settings: PropTypes.shape({
    text: PropTypes.shape({
      eventType: PropTypes.string.isRequired,
      badgeLabel: PropTypes.string.isRequired,
      dateTime: PropTypes.string.isRequired,
      titleType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    callbacks: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(categoriesPropTypes),
    badgeVariant: PropTypes.string,
    backgroundImage: PropTypes.string,
    firstGradientColor: PropTypes.string,
    secondGradientColor: PropTypes.string,
  }),
};

export interface ICardEvent {
  className?: string;
  settings: {
    text: {
      eventType: string;
      badgeLabel: string;
      dateTime: string;
      titleType: string;
      title: string;
      description: string;
    };
    callbacks: {
      onClick: () => void;
    };
    categories?: { className: string; shape: string; children: string }[];
    badgeVariant?: string;
    backgroundImage?: string;
    firstGradientColor?: string;
    secondGradientColor?: string;
  };
}

export const CardEvent = ({ className, settings }: ICardEvent): JSX.Element => (
  <CardEventContainer
    backgroundImage={settings.backgroundImage}
    firstGradientColor={settings.firstGradientColor}
    secondGradientColor={settings.secondGradientColor}
    className={classNames('CardEvent', className, settings)}
    onClick={() => {
      settings?.callbacks.onClick();
    }}
  >
    <PrintVariations
      className={className}
      settings={settings}
    ></PrintVariations>
  </CardEventContainer>
);

const atoms = [
  {
    atom: Category,
    name: 'Category~primary',
    description: 'Category primary',
    settings: CategorySettings,
    atomVariant: '&primary',
  },
  {
    atom: Badge,
    name: 'Badge~primary',
    description: 'Badge primary',
    settings: BadgeSettings,
    atomVariant: '&primary',
  },
  {
    atom: Badge,
    name: 'Badge~secondary',
    description: 'Badge secondary',
    settings: BadgeSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Badge,
    name: 'Badge~tertiary',
    description: 'Badge tertiary',
    settings: BadgeSettings,
    atomVariant: '&tertiary',
  },
  {
    atom: Text,
    name: 'Text~primary',
    description: 'Text description and date',
    settings: TextSettings,
    atomVariant: '&primary',
  },
  {
    atom: Text,
    name: 'Text~secondary',
    description: 'Text on top',
    settings: TextSettings,
    atomVariant: '&secondary',
  },
  {
    atom: Heading,
    name: 'Heading~primary',
    description: 'Heading primary',
    settings: HeadingSettings,
    atomVariant: '&primary',
  },
];

export const CardEventSettings: ISettings = {
  states: [State.default],
  include: [
    CmsConfig.frame,
    CmsConfig.border,
    CmsConfig.fill,
    CmsConfig.padding,
    CmsConfig.vmargin,
    CmsConfig.hmargin,
    CmsConfig.flexbox,
    CmsConfig.cursor,
  ],
  atoms: atoms,
  sections: [
    {
      className: 'section-top',
      description: 'Section top',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-middle',
      description: 'Section middle',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-categories',
      description: 'Section categories',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-heading',
      description: 'Section heading',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
    {
      className: 'section-hover',
      description: 'Section hover description',
      include: [
        CmsConfig.flexbox,
        CmsConfig.vmargin,
        CmsConfig.padding,
        CmsConfig.fill,
      ],
    },
  ],
  variants: [
    {
      className: '&primary',
      description: 'CardEvent primary',
    },
    {
      className: '&secondary',
      description: 'CardEvent secondary',
    },
  ],
};

CardEvent.propTypes = CardEventPropTypes;
