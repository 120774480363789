import styled from 'styled-components';

export const Wrapper = styled.section`
  background: linear-gradient(
    180deg,
    #141a28 28.94%,
    rgba(20, 26, 40, 0.75) 153.64%
  );
  .FilterList-listItem,
  .FilterListRow-title {
    color: #fff;
  }
  .FilterListRow-title {
    margin-bottom: 12px;
  }
  .FilterListRow-title > span {
    font-size: 15px;
  }
  h3 {
    font-weight: 500;
    p {
      color: white;
    }
  }
`;

export const StyledEventContainer = styled.div`
  padding: 30px var(--responsive);
  .event-heading {
    text-transform: capitalize;
  }
  p {
    color: white;
  }
`;

export const StyledFilterBar = styled.div`
  display: flex;
  padding: 0 calc(var(--responsivePadding) + 10px);
  align-items: center;
  justify-content: space-between;
  background-color: #3d4859;
  nav {
    height: 55px;
  }
  .Button {
    padding-right: 0 !important;
    gap: 0;
    :before {
      content: 'Filter';
      font-weight: 400;
      font-size: 14px;
    }
    svg {
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      background: 0 0 no-repeat url(/assets/icons/filter.svg);
      margin-left: 10px;
    }
  }
`;
