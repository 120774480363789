import React from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { Badge } from '../../Atoms/Badge/Badge';
import { Text } from '../../Atoms/Text/Text';
import { Category } from '../../Atoms/Category/Category';
import { StyledSection } from './CardEvent.css';

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { settings } = args;

  switch (variation) {
    case 'primary':
      return (
        <>
          <div className='section-top'>
            <Text className='secondary'>{settings.text.eventType} </Text>
          </div>

          <StyledSection className='section-middle'>
            {settings.text.badgeLabel && settings.text.badgeLabel !== '' ? (
              <Badge className={settings.badgeVariant}>
                {settings.text.badgeLabel}{' '}
              </Badge>
            ) : null}
            <Text className='primary'>{settings.text.dateTime} </Text>
          </StyledSection>

          <StyledSection className='section-categories'>
            {settings.categories?.map((category: any, index: number) => {
              return (
                <Category
                  className={category.className}
                  shape={category.shape}
                  color={category.color}
                  key={index}
                >
                  {category.children}
                </Category>
              );
            })}
          </StyledSection>

          <StyledSection className='section-heading'>
            <Heading tag={settings.titleType} className='primary'>
              {settings.text.title}
            </Heading>
          </StyledSection>
          <StyledSection className='section-hover'>
            <Text className='primary'>{settings.text.description} </Text>
          </StyledSection>
        </>
      );
      break;
    default:
      return <p>No Dex365Header variation selected</p>;
  }
};
