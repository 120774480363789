import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface StyledLinkProps {
  color?: string;
}

const link = css<StyledLinkProps>`
  color: ${({ color = '#fff' }) => color};
`;

export const DefaultLink = styled.a`
  ${link}
`;

export const ReactRouterLink = styled(Link)`
  text-decoration: none;
  ${link}
`;
