import { Grid } from '@dexper.events/product-atomic-ui';
import styled from 'styled-components';

const Styled = {
  main: styled.main``,
};

export default Styled;

export const StyledContainer = styled.div``;

export const ResponsiveRow = styled.section`
  padding: 40px var(--responsive);
`;

export const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;
