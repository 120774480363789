import styled from 'styled-components';
import { Slide } from 'pure-react-carousel';

export const Wrapper = styled.div`
  .carousel__slider {
    height: unset;
  }
  .events-heading {
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
  }
`;

export const StyledContainer = styled.div``;

export const ContentWrapper = styled.div`
  background: linear-gradient(180deg, #141a28 30%, rgba(20, 26, 40, 0.75) 155%);
`;

export const StyledSlide = styled(Slide)`
  .CardEvent.primary {
    height: 392px;
  }
`;
