/* eslint-disable react/react-in-jsx-scope */

export const PrintVariations = (args: any) => {
  const { className: variation } = args;
  const { message } = args;
  switch (variation) {
    case 'primary':
      return <>{message}</>;
      break;
    default:
      return <p>no card variation selected</p>;
  }
};
